import { useState, useEffect } from "react";
import { Box, Button, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";

import VisibilityIcon from "@mui/icons-material/Visibility";
//import EditIcon from "@mui/icons-material/Edit";
//import DeleteIcon from "@mui/icons-material/Delete";
import DialogueCourier from "./Dialogue";
import { DataStore } from 'aws-amplify/datastore';
import { Courier, Wallet, DeliveryOrder } from "../../models";



const Couriers = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [selectedCourier, setSelectedCourier] = useState(null);
    const [mockDataCourier, setMockDataCourier] = useState([]);
    const [mockDataWallet, setMockDataWallet] = useState([]);
    const [mockDataDeliveryOrder, setMockDataDeliveryOrder] = useState([]);

    useEffect(()=> {
        DataStore.query(Courier).then(setMockDataCourier)
    },[])

    useEffect(()=> {
        DataStore.query(Wallet).then(setMockDataWallet)
    },[])

    useEffect(()=> {
        DataStore.query(DeliveryOrder).then(setMockDataDeliveryOrder)
    },[])

    const handleOpenViewModal = (courier) => {
        setSelectedCourier(courier);
        setOpenViewModal(true);
    };

    const handleCloseViewModal = () => {
        setOpenViewModal(false);
    };

    const columns = [
        { field: "id", headerName: "ID" },
        {
            field: "name",
            headerName: "Nom & Prenom",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "sub",
            headerName: "Sub",
            type: "string",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "phone",
            headerName: "Contact",
            
        },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            width: 180,
            renderCell: (params) => (
                <Box display="flex" justifyContent="space-around">
                    <Button startIcon={<VisibilityIcon />} variant="outlined" color="primary" onClick={() => handleOpenViewModal(params.row)}>
                        View
                    </Button>
                    {/* <Button startIcon={<EditIcon />} variant="outlined" color="secondary">
                        Edit
                    </Button>
                    <Button startIcon={<DeleteIcon />} variant="outlined" color="error">
                        Delete
                    </Button>*/}
                </Box>
            ),
        },
    ];

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Livreur" subtitle="Bienvenue sur la gestion des Livreurs" />
            </Box>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                <DataGrid  rows={mockDataCourier} columns={columns} />
            </Box>
            <DialogueCourier open={openViewModal} handleClose={handleCloseViewModal} courier={selectedCourier} deliveryOrder={mockDataDeliveryOrder} wallet={mockDataWallet} />
            
        </Box>
    );
};

export default Couriers;
