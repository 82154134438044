
export const mokDataDishes =[
  {
    id: "6cecc798-b333-4dce-8a3e-250984e0816b",
    name: "Petits pois",
    short_description: "Délicieux plat de petits pois ",
    image: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.03799540197167034.jpg",
    price: "1500",
    restaurantID: "3b2440f8-f6ff-4a33-af35-1fd79b5cb2c6",
    stock: "true",
    createdAt: "2023-12-26T10:34:22.948Z",
    updatedAt: "2024-01-06T15:22:28.370Z"
  },
  {
    id: "0f828991-3bab-439f-b325-ce912bb4560e",
    name: "Riz sauce arrachide",
    short_description: "Délicieux riz sauce arrachide ",
    image: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.5358873746524456.jpg",
    price: "1000",
    restaurantID: "3b2440f8-f6ff-4a33-af35-1fd79b5cb2c6",
    stock: "true",
    createdAt: "2023-12-26T09:48:39.583Z",
    updatedAt: "2023-12-26T10:39:36.640Z"
  },
  {
    id: "508b5e2c-e604-456d-86be-cdc573f58e83",
    name: "Riz sauce yassa viande ",
    short_description: "Délicieux riz sauce yassa accompagnement viande ",
    image: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.7936013914552057.jpg",
    price: "1000",
    restaurantID: "3b2440f8-f6ff-4a33-af35-1fd79b5cb2c6",
    stock: "true",
    createdAt: "2023-12-26T10:20:10.461Z",
    updatedAt: "2023-12-26T10:39:40.888Z"
  },
  {
    id: "5349f76b-a184-4067-a3e1-b5e84f3ede7b",
    name: "Garba + Poisson Carpe ",
    short_description: "Délicieux Garba accompagnement poisson carpe ",
    image: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.7366763971498638.jpg",
    price: "1500",
    restaurantID: "5e88574b-ea65-4258-b44f-d0a6a82823fb",
    stock: "true",
    createdAt: "2023-12-26T15:06:11.118Z",
    updatedAt: "2024-01-20T13:07:01.619Z"
  },
  {
    id: "a85a6d80-a4c4-442d-a13d-96e6688c40fd",
    name: "Soupe de poulet ",
    short_description: "Délicieuse soupe de poulet ",
    image: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.42852060060094604.jpg",
    price: "1500",
    restaurantID: "3b2440f8-f6ff-4a33-af35-1fd79b5cb2c6",
    stock: "true",
    createdAt: "2023-12-26T10:27:36.531Z",
    updatedAt: "2023-12-26T10:39:47.332Z"
  },
  {
    id: "5eb8e225-b569-43f1-a540-914557d23f14",
    name: "Riz gras poisson ",
    short_description: "Savoureux Riz gras accompagnement poisson ",
    image: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.03314487981571496.jpg",
    price: "1000",
    restaurantID: "3b2440f8-f6ff-4a33-af35-1fd79b5cb2c6",
    stock: "true",
    createdAt: "2023-12-26T09:39:12.685Z",
    updatedAt: "2023-12-26T10:39:32.151Z"
  },
  {
    id: "c1bca099-8719-4c6e-98db-2e809ad0aaa5",
    name: "Riz sauce feuille ",
    short_description: "Délicieux riz sauce feuille ",
    image: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.9056112680089776.jpg",
    price: "1000",
    restaurantID: "3b2440f8-f6ff-4a33-af35-1fd79b5cb2c6",
    stock: "true",
    createdAt: "2023-12-26T09:52:34.795Z",
    updatedAt: "2023-12-26T10:39:38.426Z"
  },
  {
    id: "5535cfce-66d4-4556-bebd-b07131e57f1e",
    name: "Plat de frites ",
    short_description: "Délicieux plat de frites ",
    image: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.44386775480541446.jpg",
    price: "1000",
    restaurantID: "3b2440f8-f6ff-4a33-af35-1fd79b5cb2c6",
    stock: "true",
    createdAt: "2023-12-26T10:39:15.430Z",
    updatedAt: "2023-12-26T10:39:51.188Z"
  },
  {
    id: "4f13164f-68cf-4685-a15f-67ea236dc45e",
    name: "Riz gras poulet ",
    short_description: "Savoureux Riz gras avec accompagnement poulet ",
    image: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.11551652221183155.jpg",
    price: "2000",
    restaurantID: "3b2440f8-f6ff-4a33-af35-1fd79b5cb2c6",
    stock: "true",
    createdAt: "2023-12-26T09:43:31.256Z",
    updatedAt: "2023-12-26T10:39:35.585Z"
  },
  {
    id: "1b74ee5b-ee35-41d2-b923-a3a3933a573c",
    name: "Couscous viande",
    short_description: "Délicieux plat de couscous accompagnement viande ",
    image: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.7369683919643624.jpg",
    price: "1000",
    restaurantID: "3b2440f8-f6ff-4a33-af35-1fd79b5cb2c6",
    stock: "true",
    createdAt: "2023-12-26T10:24:58.672Z",
    updatedAt: "2023-12-26T10:39:46.195Z"
  },
  {
    id: "1215f54f-2841-4635-bb72-e083003a8d4a",
    name: "Soupe de jarret",
    short_description: "Délicieuse soupe de jarret ",
    image: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.35227542281257945.jpg",
    price: "1500",
    restaurantID: "3b2440f8-f6ff-4a33-af35-1fd79b5cb2c6",
    stock: "true",
    createdAt: "2023-12-26T10:31:14.043Z",
    updatedAt: "2023-12-26T10:39:49.280Z"
  },
  {
    id: "3ea98622-6a0d-4116-a7e7-d559fe7bbf04",
    name: "Attieke + Poisson Thon ",
    short_description: "Délicieux attieke accompagnement Poisson Thon ",
    image: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.006833129684978011.jpg",
    price: "1000",
    restaurantID: "5e88574b-ea65-4258-b44f-d0a6a82823fb",
    stock: "true",
    createdAt: "2023-12-26T15:04:03.332Z",
    updatedAt: "2024-01-20T13:07:14.435Z"
  },
  {
    id: "2681c5a1-6c6b-4d7c-b1d4-491c0e069884",
    name: "Riz gras viande",
    short_description: "Savoureux Riz gras avec accompagnement viande ",
    image: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.704046529991672.jpg",
    price: "1000",
    restaurantID: "3b2440f8-f6ff-4a33-af35-1fd79b5cb2c6",
    stock: "true",
    createdAt: "2023-12-26T09:40:36.812Z",
    updatedAt: "2023-12-26T10:39:32.952Z"
  },
  {
    id: "6992bf33-c9c0-4b33-beb6-419149dcc915",
    name: "Riz sauce gombo",
    short_description: "Savoureux Riz sauce gombo",
    image: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.17335710138913418.jpg",
    price: "1000",
    restaurantID: "3b2440f8-f6ff-4a33-af35-1fd79b5cb2c6",
    stock: "true",
    createdAt: "2023-12-26T10:17:21.859Z",
    updatedAt: "2023-12-26T10:39:39.472Z"
  },
  {
    id: "00506cc1-856e-4c2b-bdba-677fa72a403e",
    name: "Attieke + Poisson + Dindon",
    short_description: "Délicieux mélange d'attieke, poisson et d'in morceau de dindon",
    image: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.9717393621548255.jpg",
    price: "2000",
    restaurantID: "5e88574b-ea65-4258-b44f-d0a6a82823fb",
    stock: "true",
    createdAt: "2023-12-26T15:09:23.551Z",
    updatedAt: "2024-01-20T13:06:58.507Z"
  },
  {
    id: "c1740360-680e-499f-b3b6-7c4b34b1835f",
    name: "Spaghetti viande ",
    short_description: "Délicieux plat de spaghetti accompagnement viande ",
    image: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.9040867422154409.jpg",
    price: "1000",
    restaurantID: "3b2440f8-f6ff-4a33-af35-1fd79b5cb2c6",
    stock: "true",
    createdAt: "2023-12-26T10:22:57.621Z",
    updatedAt: "2023-12-26T10:39:44.475Z"
  },
  {
    id: "a5421e4c-7237-42d4-b03c-7b42d49adc52",
    name: "Riz gras poisson carpe ",
    short_description: "Savoureux Riz gras avec accompagnement poisson carpe ",
    image: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.6558600836527497.jpg",
    price: "2000",
    restaurantID: "3b2440f8-f6ff-4a33-af35-1fd79b5cb2c6",
    stock: "true",
    createdAt: "2023-12-26T09:42:00.447Z",
    updatedAt: "2023-12-26T10:39:34.339Z"
  },
  {
    id: "b0d9540f-7d6a-4583-850e-3bdd5a76ff23",
    name: "Alloco ",
    short_description: "Accompagnez vos plats d'allocations chaud ",
    image: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.0029272841760192543.jpg",
    price: "1000",
    restaurantID: "5e88574b-ea65-4258-b44f-d0a6a82823fb",
    stock: "true",
    createdAt: "2023-12-26T15:10:48.280Z",
    updatedAt: "2024-01-20T13:06:48.919Z"
  },
  {
    id: "38427eef-342a-40fa-9f55-e20b33b19588",
    name: "Soupe de carpe",
    short_description: "Délicieuse soupe de carpe ",
    image: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.5245281974398052.jpg",
    price: "1500",
    restaurantID: "3b2440f8-f6ff-4a33-af35-1fd79b5cb2c6",
    stock: "true",
    createdAt: "2023-12-26T10:29:49.167Z",
    updatedAt: "2023-12-26T10:39:48.289Z"
  },
]

export const mockDataOrderRestaurant = [
  {
    id: "a8c6927d-34fd-43b8-9a95-7cd61bbb3fc3",
    userID: "00bdfe3a-d633-4c29-927b-cce5599fb5fb",
    restaurantID: "5e88574b-ea65-4258-b44f-d0a6a82823fb",
    totalAmount: "2000",
    status: "DECLINED",
    userlat: "11.1443094",
    userlng: "-4.3339982",
    courierID: "",
    deliveryFee: "1000",
    createdAt: "2024-01-08T08:55:00.878Z",
    updatedAt: "2024-01-14T18:12:23.438Z"
  },
  {
    id: "d807e2e7-d457-48e0-9c64-58d3fec62584",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    restaurantID: "5e88574b-ea65-4258-b44f-d0a6a82823fb",
    totalAmount: "3000",
    status: "CANCELLED",
    userlat: "12.372527",
    userlng: "-1.4968143",
    courierID: "",
    deliveryFee: "1000",
    createdAt: "2024-01-22T14:24:37.231Z",
    updatedAt: "2024-01-22T14:26:54.364Z"
  },
  {
    id: "ed042b1a-69de-4c60-84a2-a514cfd691b5",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    restaurantID: "5e88574b-ea65-4258-b44f-d0a6a82823fb",
    totalAmount: "1500",
    status: "CANCELLED",
    userlat: "12.372527",
    userlng: "-1.4968143",
    courierID: "",
    deliveryFee: "1000",
    createdAt: "2024-01-22T14:28:45.019Z",
    updatedAt: "2024-01-22T14:29:59.213Z"
  },
  {
    id: "eabd9ba4-a4bd-4603-a667-e64617590876",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    restaurantID: "5e88574b-ea65-4258-b44f-d0a6a82823fb",
    totalAmount: "3000",
    status: "CANCELLED",
    userlat: "12.372527",
    userlng: "-1.4968143",
    courierID: "",
    deliveryFee: "1000",
    createdAt: "2024-01-22T14:32:18.489Z",
    updatedAt: "2024-01-22T14:34:06.307Z"
  },
  {
    id: "6ce089c5-2d38-4c91-9bc9-b4051d2fe846",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    restaurantID: "3b2440f8-f6ff-4a33-af35-1fd79b5cb2c6",
    totalAmount: "1500",
    status: "CANCELLED",
    userlat: "12.372527",
    userlng: "-1.4968143",
    courierID: "",
    deliveryFee: "1000",
    createdAt: "2024-01-22T14:35:27.952Z",
    updatedAt: "2024-01-22T14:35:54.690Z"
  },
  {
    id: "a106dee4-2897-4397-82c0-50fc54f3937e",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    restaurantID: "3b2440f8-f6ff-4a33-af35-1fd79b5cb2c6",
    totalAmount: "1500",
    status: "CANCELLED",
    userlat: "12.372527",
    userlng: "-1.4968143",
    courierID: "",
    deliveryFee: "1000",
    createdAt: "2024-01-22T14:37:30.515Z",
    updatedAt: "2024-01-22T14:37:36.015Z"
  },
  {
    id: "a3fd3186-3c2b-41bd-ae81-8fa7ec686faa",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    restaurantID: "3b2440f8-f6ff-4a33-af35-1fd79b5cb2c6",
    totalAmount: "1500",
    status: "CANCELLED",
    userlat: "12.372527",
    userlng: "-1.4968143",
    courierID: "",
    deliveryFee: "1000",
    createdAt: "2024-01-22T14:38:50.354Z",
    updatedAt: "2024-01-22T14:38:55.547Z"
  },
  {
    id: "947ef1ff-e4ce-4c8b-95b3-6a1cd4217b09",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    restaurantID: "5e88574b-ea65-4258-b44f-d0a6a82823fb",
    totalAmount: "1500",
    status: "CANCELLED",
    userlat: "12.372527",
    userlng: "-1.4968143",
    courierID: "",
    deliveryFee: "1000",
    createdAt: "2024-01-22T14:39:50.900Z",
    updatedAt: "2024-01-22T14:39:55.905Z"
  },
  {
    id: "4d5e48aa-25d7-4131-9789-7f4426375d3e",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    restaurantID: "5e88574b-ea65-4258-b44f-d0a6a82823fb",
    totalAmount: "1500",
    status: "CANCELLED",
    userlat: "12.376618",
    userlng: "-1.4768415",
    courierID: "",
    deliveryFee: "1000",
    createdAt: "2024-01-22T17:02:00.870Z",
    updatedAt: "2024-01-22T17:02:08.114Z"
  },
  {
    id: "7e42d0f9-e4d9-48ef-9e40-f645e1a95f69",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    restaurantID: "5e88574b-ea65-4258-b44f-d0a6a82823fb",
    totalAmount: "3500",
    status: "CANCELLED",
    userlat: "12.378367",
    userlng: "-1.4792762",
    courierID: "",
    deliveryFee: "1000",
    createdAt: "2024-01-22T22:26:47.872Z",
    updatedAt: "2024-01-22T22:27:01.633Z"
  },
  {
    id: "e8bf3d73-f4dd-473d-8134-9f82d752535e",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    restaurantID: "5e88574b-ea65-4258-b44f-d0a6a82823fb",
    totalAmount: "1500",
    status: "CANCELLED",
    userlat: "12.3869663",
    userlng: "-1.4777648",
    courierID: "",
    deliveryFee: "1000",
    createdAt: "2024-01-27T22:25:47.085Z",
    updatedAt: "2024-01-27T22:25:53.204Z"
  },
  {
    id: "6b9f95ac-b3a0-4423-8336-a057c982c6ac",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    restaurantID: "3b2440f8-f6ff-4a33-af35-1fd79b5cb2c6",
    totalAmount: "1500",
    status: "CANCELLED",
    userlat: "12.3533496",
    userlng: "-1.5693045",
    courierID: "",
    deliveryFee: "1000",
    createdAt: "2024-02-05T13:34:30.249Z",
    updatedAt: "2024-02-05T13:35:11.514Z"
  },
  {
    id: "802025a9-7581-4f9a-ac81-f81821379a10",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    restaurantID: "3b2440f8-f6ff-4a33-af35-1fd79b5cb2c6",
    totalAmount: "1500",
    status: "COMPLETED",
    userlat: "12.3533496",
    userlng: "-1.5693045",
    courierID: "08314558-a87f-4011-9362-00feda3063a5",
    deliveryFee: "1000",
    createdAt: "2024-02-05T13:35:23.072Z",
    updatedAt: "2024-02-05T13:39:02.028Z"
  },
];

export const mockDataOrderItem = [
  {
    id: "a01ca0e4-63bf-4fc1-b64a-d406c036a7e2",
    orderID: "a8c6927d-34fd-43b8-9a95-7cd61bbb3fc3",
    quantity: "1",
    price: "1000",
    dishID: "3ea98622-6a0d-4116-a7e7-d559fe7bbf04",
    createdAt: "2024-01-08T08:55:03.077Z",
    updatedAt: "2024-01-08T08:55:03.077Z"
  },
  {
    id: "26406748-91a4-4725-9795-0437bc5dc025",
    orderID: "a8c6927d-34fd-43b8-9a95-7cd61bbb3fc3",
    quantity: "1",
    price: "1000",
    dishID: "b0d9540f-7d6a-4583-850e-3bdd5a76ff23",
    createdAt: "2024-01-08T08:55:02.070Z",
    updatedAt: "2024-01-08T08:55:02.070Z"
  },
  {
    id: "71efad0d-d6e7-4a07-9c5a-237599f6821b",
    orderID: "d807e2e7-d457-48e0-9c64-58d3fec62584",
    quantity: "2",
    price: "1500",
    dishID: "5349f76b-a184-4067-a3e1-b5e84f3ede7b",
    createdAt: "2024-01-22T14:24:38.321Z",
    updatedAt: "2024-01-22T14:24:38.321Z"
  },
  {
    id: "cd1a0c98-f3cb-4004-8dcd-74cb0df74695",
    orderID: "ed042b1a-69de-4c60-84a2-a514cfd691b5",
    quantity: "1",
    price: "1500",
    dishID: "5349f76b-a184-4067-a3e1-b5e84f3ede7b",
    createdAt: "2024-01-22T14:28:46.176Z",
    updatedAt: "2024-01-22T14:28:46.176Z"
  },
  {
    id: "287e1a95-51b2-409e-b07f-cb49ccf41b35",
    orderID: "eabd9ba4-a4bd-4603-a667-e64617590876",
    quantity: "2",
    price: "1500",
    dishID: "5349f76b-a184-4067-a3e1-b5e84f3ede7b",
    createdAt: "2024-01-22T14:32:19.421Z",
    updatedAt: "2024-01-22T14:32:19.421Z"
  },
  {
    id: "07a1bb35-d28d-4fb0-b1d5-797b6f597740",
    orderID: "6ce089c5-2d38-4c91-9bc9-b4051d2fe846",
    quantity: "1",
    price: "1500",
    dishID: "6cecc798-b333-4dce-8a3e-250984e0816b",
    createdAt: "2024-01-22T14:35:29.090Z",
    updatedAt: "2024-01-22T14:35:29.090Z"
  },
  {
    id: "4bbf3929-4e1e-47e1-ab4b-fb4c7e630362",
    orderID: "a106dee4-2897-4397-82c0-50fc54f3937e",
    quantity: "1",
    price: "1500",
    dishID: "6cecc798-b333-4dce-8a3e-250984e0816b",
    createdAt: "2024-01-22T14:37:31.428Z",
    updatedAt: "2024-01-22T14:37:31.428Z"
  },
  {
    id: "7e3a1424-0d86-4e61-a030-8e742084366f",
    orderID: "a3fd3186-3c2b-41bd-ae81-8fa7ec686faa",
    quantity: "1",
    price: "1500",
    dishID: "6cecc798-b333-4dce-8a3e-250984e0816b",
    createdAt: "2024-01-22T14:38:51.277Z",
    updatedAt: "2024-01-22T14:38:51.277Z"
  },
  {
    id: "7bfbed72-724a-4826-b45d-7e944168c365",
    orderID: "947ef1ff-e4ce-4c8b-95b3-6a1cd4217b09",
    quantity: "1",
    price: "1500",
    dishID: "5349f76b-a184-4067-a3e1-b5e84f3ede7b",
    createdAt: "2024-01-22T14:39:51.905Z",
    updatedAt: "2024-01-22T14:39:51.905Z"
  },
  {
    id: "03c4c4d9-012c-4a4e-a191-82bdc24689d3",
    orderID: "7e42d0f9-e4d9-48ef-9e40-f645e1a95f69",
    quantity: "1",
    price: "2000",
    dishID: "00506cc1-856e-4c2b-bdba-677fa72a403e",
    createdAt: "2024-01-22T22:26:50.097Z",
    updatedAt: "2024-01-22T22:26:50.097Z"
  },
  {
    id: "4229e2ec-aeb7-41fa-98a0-b0b45d16b481",
    orderID: "4d5e48aa-25d7-4131-9789-7f4426375d3e",
    quantity: "1",
    price: "1500",
    dishID: "5349f76b-a184-4067-a3e1-b5e84f3ede7b",
    createdAt: "2024-01-22T17:02:02.125Z",
    updatedAt: "2024-01-22T17:02:02.125Z"
  },
  {
    id: "45e6e449-aba0-4912-8a1b-43b835995427",
    orderID: "7e42d0f9-e4d9-48ef-9e40-f645e1a95f69",
    quantity: "1",
    price: "1500",
    dishID: "5349f76b-a184-4067-a3e1-b5e84f3ede7b",
    createdAt: "2024-01-22T22:26:48.982Z",
    updatedAt: "2024-01-22T22:26:48.982Z"
  },
  {
    id: "4629a71b-262f-4f66-9629-16d2c242a1d5",
    orderID: "e8bf3d73-f4dd-473d-8134-9f82d752535e",
    quantity: "1",
    price: "1500",
    dishID: "5349f76b-a184-4067-a3e1-b5e84f3ede7b",
    createdAt: "2024-01-27T22:25:48.291Z",
    updatedAt: "2024-01-27T22:25:48.291Z"
  },
  {
    id: "e573f250-c18f-4b2f-adbc-fa6dcd14ab9e",
    orderID: "6b9f95ac-b3a0-4423-8336-a057c982c6ac",
    quantity: "1",
    price: "1500",
    dishID: "6cecc798-b333-4dce-8a3e-250984e0816b",
    createdAt: "2024-02-05T13:34:31.071Z",
    updatedAt: "2024-02-05T13:34:31.071Z"
  },
  {
    id: "01a24e12-d537-4cfd-9364-3a06e4a9eaea",
    orderID: "802025a9-7581-4f9a-ac81-f81821379a10",
    quantity: "1",
    price: "1500",
    dishID: "6cecc798-b333-4dce-8a3e-250984e0816b",
    createdAt: "2024-02-05T13:35:23.942Z",
    updatedAt: "2024-02-05T13:35:23.942Z"
  },
];

export const mockDataRestaurant = [
  {
    id: "5e88574b-ea65-4258-b44f-d0a6a82823fb",
    name: "Chez Rama Garbadrome",
    short_description: "Garba chaud chez Rama",
    image: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.21602498400021208.jpg",
    lat: "12.3603284",
    long: "-1.4829152",
    address: "Boulevard des Tensoba, Dagnoen",
    rating: "4.5",
    featuredID: "4fa658b1-0588-4234-af79-44e7fc46cdae",
    sub: "de48a9af-01fb-45ee-a810-f00e12730392",
    phone: "57902441",
    deviceToken: "dDmcxPOWSwe96SDOyR7fWJ:APA91bHsA0Pg0rx8nGbgpBcmZDXutry_amLiu6qmyffjutBIZgssrt08DrUH2b8a61UVughqu-8mHqEO9gezT9dnluxiwXrTpG75nH4vbg4GQMLU62H6YilhacG45WjEfL2ynYN-ihgH",
    openTime: "10:00",
    closeTime: "23:00",
    createdAt: "2023-12-26T14:36:07.342Z",
    updatedAt: "2024-01-26T15:11:48.769Z",
    restaurantCategoryId: ""
  },
  {
    id: "3b2440f8-f6ff-4a33-af35-1fd79b5cb2c6",
    name: "SAF SAFAL ",
    short_description: "Restaurant sénégalais",
    image: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.7190176123990784.jpg",
    lat: "12.3736135",
    long: "-1.4885431",
    address: "Rue 29.13, Wemtenga ",
    rating: "3",
    featuredID: "4fa658b1-0588-4234-af79-44e7fc46cdae",
    sub: "37108138-e3fa-4356-9450-2d23c3720b81",
    phone: "64919582",
    deviceToken: "d-537DnIQ2SeSUNHOGC3nz:APA91bH9uGmU1dCeu4lVbsnmQjgGXhNNNSAaQq_JC-1ZBmGSGMV0uBcqSwSWvA546Dv3FWH6bbiAFosiyy9qLiJvw1VgkpZV59mc5WMtqsDSJzhBPqtFvT50M-uIW1SvRhqNe0gCS1Bh",
    openTime: "09:00",
    closeTime: "23:00",
    createdAt: "2023-12-22T15:24:32.599Z",
    updatedAt: "2024-01-26T17:13:29.516Z",
    restaurantCategoryId: ""
  },
];

export const mockDataTeam = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    access: "admin",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    access: "manager",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    access: "user",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    access: "admin",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    access: "user",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    access: "manager",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    access: "user",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    access: "user",
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    access: "admin",
  },
];

export const mockDataContacts = [
    {
      id: "fe94d538-000a-413b-8cb0-da42e5c4c0f7",
      name: "Sanou Joseph Camille",
      adress: "01 BP 2529 Bobo-Dioulasso Lafiabougou 01",
      lat: "",
      lng: "",
      sub: "00bdfe3a-d633-4c29-927b-cce5599fb5fb",
      deviceToken: "fqFAgqPVQmik_2TZB2MK6N:APA91bFeC3WSWMCNAhOICrt0sP4A21dSSiHuEphxNZ0RfDUwQ0PqaV43E4cv4t_nlmu5GEwKbr5HTuQFXz4rR4ktnxzcrCCpaRNY_5NWD5BVtSsoTQ5PQJnBDSVmk45LdJW5Jce_3MS5",
      phone: "77379292",
      profileImage: "",
      notation: "0",
      createdAt: "2024-01-08T08:42:03.911Z",
      updatedAt: "2024-01-08T08:42:03.911Z"
    },
    {
      id: "cd9805c2-1fbf-451c-9d20-922066805764",
      name: "Maimouna Traore",
      adress: "Ouagadougou ",
      lat: "",
      lng: "",
      sub: "11da57b9-2993-4aa2-9883-d93482dc6119",
      deviceToken: "eTQaH_MiQoCk-qJdLFcfC8:APA91bH8OOLENHU83p-GZwy8NEphpA6HKCTagb1w6Lr9kwEXGYDK_6h1qpniph41n9RsBN2gYarciK7S77tp_bZ6yM5xkMbQbV_ka4OA0aC9VPSQp8GZyYEtabr-CGxShCmni1npl_GT",
      phone: "67390119",
      profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.9613519224826016.jpg",
      notation: "74",
      createdAt: "2023-12-21T15:25:57.809Z",
      updatedAt: "2024-02-05T18:52:22.806Z"
    },
    {
      id: "3f3d41b7-8648-497f-8ae4-5f06a8130c2c",
      name: "Djonan informatique ",
      adress: "Ouagadougou ",
      lat: "",
      lng: "",
      sub: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
      deviceToken: "fVbVaF0DRgaR4nvkAGAI4Q:APA91bHWQUnNdA-rI6RDGIY6ymA1nijUEFoLgxsZ4sZIGh04d6eXzNYnT04A7BlPPNTVjhMuscXgRRhKtAsmM0H7g-1LY6EohjiZkfB2rsRUEtbp1OLbnr5IoLzTh2ROU3Jxv8SyqGlL",
      phone: 73114622,
      profileImage: "",
      notation: 59,
      createdAt: "2024-01-09T11:32:25.781Z",
      updatedAt: "2024-02-08T11:31:49.789Z"
    },
    {
      id: "88f695fb-11c5-4b04-a6da-4aa858e13e93",
      name: "Djonan ",
      adress: "Ouagadougou ",
      lat: "",
      lng: "",
      sub: "e92767ad-5a57-4975-a5dd-753c0d93b677",
      deviceToken: "e9GfnLFfT42tON2SoHgyIE:APA91bFLJnM9g9Z-qLxC5_nyatHSD8D56CJ3EkQV6-UIyXTcNa5_OA6ISm6gzgo7RQEuq2LyB91tiAIRQYJ6-tydBMBoZedE32NYCpqTNYa2iIbgYakNEauM6EvUN2XwMuyeujA-mQx5",
      phone: 55577874,
      profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.6567347646186565.jpg",
      notation: 22,
      createdAt: "2023-11-22T12:53:40.983Z",
      updatedAt: "2024-01-09T09:13:06.288Z"
    },
    {
      id: "73632c00-abfe-4791-acd7-cb37657840f2",
      name: "SIDIBE MARIAMA",
      adress: "Ouagadougou ",
      lat: "",
      lng: "",
      sub: "e6d2b911-01a6-49ad-a9da-1498c027c724",
      deviceToken: "dee5JLUMSaKUnU4u05ZzV6:APA91bG0ugWK_4xWZ_5UnYsU_mZ4I1LHsyEHE1XWFsxuvVfnk6J3Uge6agay7133MRKvJH3sGLlX55CGh2obOPyLtbLsQ3EL8gA2X-GS4xEQG2t9U_sXDa8mOmF1b6dOOvsqmi6XjBr-",
      phone: 76590315,
      profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.45051305192719754.jpg",
      notation: 0,
      createdAt: "2023-12-22T14:03:05.908Z",
      updatedAt: "2023-12-22T14:04:28.475Z"
    },
    {
      id: "5169da07-a4cb-4a57-b1e0-38a6f5927ea3",
      name: "Ousman",
      adress: "Ouagadougou ",
      lat: "",
      lng: "",
      sub: "5ef3caa1-744e-42b9-862f-ab3628ebfec7",
      deviceToken: "cTnyvBn0T4iqDlEZlRKuEH:APA91bGesjda1I-0VFV0gF8kNtuzE7WTGnkB9x8BqIQaquI--qiY1duRaKtaaSEqGsJ2uvNUZ7zeTnyvDYt6ZR2xHGe-TjjObjkJM2G_UBFfMvlwGQ8g8gZ_9EeBCTi8rqdBFHa-Ae5S",
      phone: 76838435,
      profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.08634364039151733.jpg",
      notation: 2,
      createdAt: "2023-12-26T19:51:34.210Z",
      updatedAt: "2024-01-05T19:09:20.770Z"
    },
    {
      id: "fd04e15a-5002-4f0f-9528-3b9f9a4f3655",
      name: "Enrique ",
      adress: "enriqueowanri@gmail.com",
      lat: "",
      lng: "",
      sub: "ee65ed8e-7aa4-4e8d-9ffd-0a9c0d92f740",
      deviceToken: "dgNrxyN5RoSEMJPFWbakq5:APA91bGYfnhCkMlY3JH_f9og76m0bmEi1KAYUcfufOMOrf7TQPY4Wk3YKlvTY369CSfRwxYF2lbFL_2H9hYeVBsppDYxIIRXCPDZwQXZgpjPRSYz_3a3aLBjuBsG1Dshz_yh_rLh5P85",
      phone: 70687247,
      profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.18625531251734306.jpg",
      notation: 0,
      createdAt: "2023-12-24T21:09:50.122Z",
      updatedAt: "2023-12-25T11:35:38.624Z"
    },
    {
      id: "66c598b1-6039-45ed-a3ec-e5e065941fb5",
      name: "Moustapha sionne",
      adress: "Ouagadougou ",
      lat: "",
      lng: "",
      sub: "44078f66-a7b1-41e6-87f9-fd421537091a",
      deviceToken: "fORpKHgvTByLQ3sPTG8euK:APA91bHpEq5ygzkPmbUmCoUNN3xgLqAzHBqjE32A6KsOYa4vCTZCYAozpfWZDEjqx_cL7qw-2C91OR6O6MAY1cRXpFBIYPTeUyx7rLwYa3pSi_cod6IlHq1x_PM5nhMPemNm_5synSB3",
      phone: 74945800,
      profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.5234377286284077.jpg",
      notation: 0,
      createdAt: "2023-12-28T10:54:34.864Z",
      updatedAt: "2023-12-28T10:55:09.862Z"
    },
    {
      id: "ca552ea7-9ea1-4a04-8e48-148da96cbfa4",
      name: "Borro Benoît ",
      adress: "Ouagadougou ",
      lat: "",
      lng: "",
      sub: "42536245-dae5-4956-b0b6-88bacbf72c16",
      deviceToken: "cstgJYYeTfqI4O4nPIZhhs:APA91bGJNG0q8bKBOEv5LSZFIJQ05G0dtB7F7qfCB7A9YtALJ0La2XU7vJtOF-QAybx26HvNaxk4vjl1tGF9786VlPgm4RubMtBCwzPlAGRePEQZrdjtIhLdE6vj4qOueBeZPpp_N7SD",
      phone: 64761065,
      profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.16254900016786142.jpg",
      notation: 0,
      createdAt: "2023-12-21T20:58:53.258Z",
      updatedAt: "2023-12-21T21:08:47.344Z"
    }
];

export const mockDataCourier = [
  
  {
    id: "9500eac0-4f38-462f-993a-f2e23bad11f8",
    name: "Djonans ",
    sub: "e92767ad-5a57-4975-a5dd-753c0d93b677",
    lat: "12.3238461",
    lng: "-1.4812558",
    transportationMode: "DRIVING",
    deviceToken: "ft3bIPKfTNSDiXCbvkZAf8:APA91bF2WjCkWuuIZliuc3rey_p9C2PhLQxYggDYZGzh_Q6z5ulVHg4ilZWVFEPZyd4Zq-FwmuZkIc04T2SzP7Hj5RoirmKqYVgi6kZ8nnEc4N3Eh-sSKhFqQnXgP3MMKu29M0D6pv_q",
    bikeColor: "Vegas zr",
    licensePlate: "1010",
    profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.8154203191513844.jpg",
    phone: "+22655577874",
    rating: "1.875",
    heading: "",
    createdAt: "2024-01-26T13:57:02.396Z",
    updatedAt: "2024-02-06T01:30:33.889Z"
  },
  {
    id: "08314558-a87f-4011-9362-00feda3063a5",
    name: "Livreur ZAGRE ",
    sub: "1e04d8ee-29d0-4fa8-8e10-062ee8273c77",
    lat: "12.3683072",
    lng: "-1.495467",
    transportationMode: "DRIVING",
    deviceToken: "fKiOTUUIQiq9yYMxk15ciF:APA91bEI5wMs0KpCsbniU3ZIIV_pn79Zygfv4d0aQ2MSunQannAJpnZ0cfMrhgsm49a3BAfeitxzmU-A-kaUktVm8SXR8MLWkUCm8Zj2NIC6HfexSgxtI1RHjlia6OrouQyHkGRbGIPt",
    bikeColor: "Sirius noire",
    licensePlate: "1010",
    profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.9278434013265562.jpg",
    phone: "78976414",
    rating: "2.5",
    heading: "",
    createdAt: "2024-02-05T12:27:20.739Z",
    updatedAt: "2024-02-06T07:34:36.649Z"
  },
];

export const mockDataDeliveryMaxDistance =[
  {
    id: "4ac620df-605f-4508-9fbb-e3ef56e8dc05",
    distance: "10",
    createdAt: "2024-01-13T16:49:33.632Z",
    updatedAt: "2024-02-05T12:33:11.924Z"
  },
]

export const mockDataMaxDistance =[
  {
    id: "846f12e8-52b5-4061-aab6-f25b900c7141",
    distance: "10",
    createdAt: "2024-01-13T12:28:16.582Z",
    updatedAt: "2024-01-30T12:38:15.338Z"
  },
]

export const mockDataLocationOrder =[
  {
    id: "134fc024-9d9e-4d67-ab7e-30b6b08c6dd7",
    type: "une journée",
    originLatitude: "12.378367",
    originLongitude: "-1.4792762",
    status: "COMPLETED",
    amount: "30000",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    driverID: "7e894bc2-f9fc-457a-93b2-1edacc5c3969",
    createdAt: "2024-01-22T22:23:23.088Z",
    updatedAt: "2024-01-22T22:23:30.513Z"
  },
  {
    id: "56b78598-0e3b-4e5a-8e54-0a87707948af",
    type: "une journée",
    originLatitude: "12.371096",
    originLongitude: "-1.4986813",
    status: "CANCELLED",
    amount: "30000",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    driverID: "",
    createdAt: "2024-01-24T12:14:59.549Z",
    updatedAt: "2024-01-24T12:17:41.037Z"
  },
  {
    id: "e9b2553d-f99f-4061-9436-64d89f532299",
    type: "une journée",
    originLatitude: "12.3723289",
    originLongitude: "-1.4969172",
    status: "CANCELLED",
    amount: "30000",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    driverID: "",
    createdAt: "2024-01-24T11:54:03.103Z",
    updatedAt: "2024-01-24T12:13:32.824Z"
  },
  {
    id: "ba3e11fd-89bf-4ee9-a119-e7ae5464ad6e",
    type: "une journée",
    originLatitude: "12.371096",
    originLongitude: "-1.4986813",
    status: "CANCELLED",
    amount: "30000",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    driverID: "",
    createdAt: "2024-01-24T12:18:46.606Z",
    updatedAt: "2024-01-24T12:21:46.367Z"
  },
  {
    id: "7122aa0c-6bd6-4edd-af9c-832185a73f3f",
    type: "une journée",
    originLatitude: "12.371096",
    originLongitude: "-1.4986813",
    status: "CANCELLED",
    amount: "30000",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    driverID: "",
    createdAt: "2024-01-24T12:29:22.094Z",
    updatedAt: "2024-01-24T12:31:03.412Z"
  },
  {
    id: "b9dde6c1-842e-46a0-b90d-c40d5d048872",
    type: "une journée",
    originLatitude: "12.371096",
    originLongitude: "-1.4986813",
    status: "CANCELLED",
    amount: "30000",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    driverID: "",
    createdAt: "2024-01-24T12:31:45.193Z",
    updatedAt: "2024-01-24T12:32:08.644Z"
  },
  {
    id: "3c4f1e5d-9b5e-42d4-b82d-65015ff32e40",
    type: "Demi-journée",
    originLatitude: "12.371096",
    originLongitude: "-1.4986813",
    status: "CANCELLED",
    amount: "15000",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    driverID: "",
    createdAt: "2024-01-24T12:38:24.295Z",
    updatedAt: "2024-01-24T12:38:48.616Z"
  },
  {
    id: "4eccfbc8-9f41-4764-b7cd-49e39f1ba63e",
    type: "une journée",
    originLatitude: "12.371096",
    originLongitude: "-1.4986813",
    status: "CANCELLED",
    amount: "30000",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    driverID: "",
    createdAt: "2024-01-24T13:38:55.405Z",
    updatedAt: "2024-01-24T13:39:55.384Z"
  },
  {
    id: "295aa4e4-c2a5-47d8-8025-62a142f657e6",
    type: "une journée",
    originLatitude: "12.371096",
    originLongitude: "-1.4986813",
    status: "CANCELLED",
    amount: "30000",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    driverID: "",
    createdAt: "2024-01-24T13:41:08.183Z",
    updatedAt: "2024-01-24T13:42:07.985Z"
  },
  {
    id: "1ba3b28b-02cf-4615-8d3c-a32f03b5188d",
    type: "une journée",
    originLatitude: "12.371096",
    originLongitude: "-1.4986813",
    status: "CANCELLED",
    amount: "30000",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    driverID: "",
    createdAt: "2024-01-24T13:44:18.819Z",
    updatedAt: "2024-01-24T13:45:18.753Z"
  },
]

export const mockDataDeliveryOption =[
  {
    id: "52f7d72f-ed83-4bbd-bb42-f8cc85729a46",
    title: "Livraison de Colis",
    image: "https://www.pngmart.com/files/11/Blank-Package-PNG-Image.png",
    multiplier: "100",
    createdAt: "2024-02-05T19:00:51.299Z",
    updatedAt: "2024-02-05T19:00:51.299Z"
  },
  {
    id: "1292fb78-1404-42e1-bc71-165b0322e919",
    title: "Transport à Moto",
    image: "https://djonan-storage101242-staging.s3.amazonaws.com/public/44190.jpg",
    multiplier: "150",
    createdAt: "2024-02-05T19:02:14.635Z",
    updatedAt: "2024-02-05T19:02:14.635Z"
  },
  {
    id: "2d0b5f2e-b4fa-46b8-a98c-6092cb1c7b6d",
    title: "Transport bagage",
    image: "https://djonan-storage101242-staging.s3.amazonaws.com/public/tricycle.jpeg",
    multiplier: "950",
    createdAt: "2024-02-05T19:03:19.695Z",
    updatedAt: "2024-02-05T19:03:19.695Z"
  },
]

export const mockDataLocationOption = [
  {
    id: "21a1bb3c-26f0-4371-ab84-b2f6f62c45be",
    title: "Demi-journée",
    subtitle: "une durée de six (06) heures",
    image: "https://djonan-storage101242-staging.s3.amazonaws.com/public/Comfort.jpeg",
    price: "15000",
    createdAt: "2024-01-12T22:19:28.232Z",
    updatedAt: "2024-01-17T22:06:15.364Z"
  },
  {
    id: "f65eca89-7e79-4fea-adaf-e8a07bcb89b1",
    title: "Une journée",
    subtitle: "une durée de douze (12) heures",
    image: "https://djonan-storage101242-staging.s3.amazonaws.com/public/UberXL.jpeg",
    price: "30000",
    createdAt: "2024-02-05T11:55:17.194Z",
    updatedAt: "2024-02-05T11:55:17.194Z"
  },
]

export const mockDataLocationRideOption = [
  {
    id: "1510ad61-9479-4688-8ac9-f91d79ae741b",
    title: "Economique",
    image: "https://djonan-storage101242-staging.s3.amazonaws.com/public/econo.png",
    multiplier: "300",
    createdAt: "2024-01-13T11:09:20.971Z",
    updatedAt: "2024-02-05T11:51:09.462Z"
  },
  {
    id: "e6a600b1-e48f-49d2-a664-8e85674d8fa2",
    title: "Confort",
    image: "https://djonan-storage101242-staging.s3.amazonaws.com/public/okokok.png",
    multiplier: "400",
    createdAt: "2024-02-05T11:30:56.387Z",
    updatedAt: "2024-02-05T11:45:36.823Z"
  },
]

export const mockDataCoupons =[
  {
    id: "30c07321-88b7-4766-bae1-c6d03cab105f",
    code: "DJONAN2023",
    reduction: "0.5",
    status: "Used",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    createdAt: "2024-01-13T10:10:08.057Z",
    updatedAt: "2024-01-21T15:21:06.961Z"
  },
  {
    id: "5d4ccc00-e2c7-436b-acfa-4359491e58e2",
    code: "WEEKEND",
    reduction: "0.2",
    status: "Used",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    createdAt: "2024-01-13T10:10:44.699Z",
    updatedAt: "2024-01-21T15:24:23.942Z"
  },
  {
    id: "5e8a0514-5128-4996-bf5d-4f83a8274792",
    code: "ETALONBUT",
    reduction: "0.5",
    status: "USED",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    createdAt: "2024-01-13T10:11:17.136Z",
    updatedAt: "2024-01-26T14:05:00.921Z"
  },
  {
    id: "7dd0937f-04f2-48a8-94a9-963f27be431e",
    code: "BUT",
    reduction: "0.5",
    status: "USED",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    createdAt: "2024-01-22T11:01:22.744Z",
    updatedAt: "2024-01-22T11:26:14.325Z"
  },
  {
    id: "16d9fe96-2ea7-4380-afb4-151fe843c20f",
    code: "ZOULOU",
    reduction: "0.2",
    status: "USED",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    createdAt: "2024-01-22T22:31:05.756Z",
    updatedAt: "2024-01-26T13:24:51.019Z"
  },
  {
    id: "ba3180e9-454d-4dbc-94ae-acea9117c77f",
    code: "BUTE",
    reduction: "0.5",
    status: "NEW",
    userID: "",
    createdAt: "2024-01-26T18:40:57.565Z",
    updatedAt: "2024-02-08T15:21:59.174Z"
  },
]

export const mockDataDeliveryOrder = [
  
  {
    id: "248462d1-0a28-425f-b7e2-d9cb038c3be3",
    type: "Transport bagage",
    originlatitude: "12.3766194",
    originlongitude: "-1.4768424",
    destinationlatitude: "12.3766199",
    destinationlongitude: "-1.4768424",
    createdAt: "2024-01-17T22:10:30.759Z",
    status: "CANCELED",
    deliveryAmount: "1000",
    courierID: "",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    whyCanceled: "bouton retour appuyé",
    updatedAt: "2024-01-17T22:10:48.982Z"
  },
  {
    id: "608667f5-c19a-4833-af83-9e17c23713dc",
    type: "Transport a Moto",
    originlatitude: "12.372206",
    originlongitude: "-1.4958111",
    destinationlatitude: "12.3059077",
    destinationlongitude: "-1.519919",
    createdAt: "2024-01-09T10:50:14.055Z",
    status: "CANCELED",
    deliveryAmount: "1500",
    courierID: "",
    userID: "e92767ad-5a57-4975-a5dd-753c0d93b677",
    whyCanceled: "Course non acceptée",
    updatedAt: "2024-01-09T10:51:13.939Z"
  },
  {
    id: "ea57e966-1b58-44f0-a9c5-c2a130e09962",
    type: "Transport bagage",
    originlatitude: "12.3220677",
    originlongitude: "-1.5329678",
    destinationlatitude: "12.3220677",
    destinationlongitude: "-1.5329678",
    createdAt: "2024-01-17T19:38:10.862Z",
    status: "CANCELED",
    deliveryAmount: "1000",
    courierID: "",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    whyCanceled: "bouton retour appuyé",
    updatedAt: "2024-01-17T19:38:20.500Z"
  },
  {
    id: "b899baad-3105-4fbc-8155-a6a09f1c6371",
    type: "Transport a Moto",
    originlatitude: "12.3783927",
    originlongitude: "-1.4148363",
    destinationlatitude: "12.3138383",
    destinationlongitude: "-1.4676394",
    createdAt: "2024-01-14T13:28:55.993Z",
    status: "ACCEPTED",
    deliveryAmount: "2200",
    courierID: "08314558-a87f-4011-9362-00feda3063a5",
    userID: "978ff479-4e00-45e3-9185-72c22fb63693",
    whyCanceled: "",
    updatedAt: "2024-02-05T13:21:27.384Z"
  },
  {
    id: "1a44e606-58d5-4b91-89f0-a23bbc76e70f",
    type: "Transport bagage",
    originlatitude: "12.3766194",
    originlongitude: "-1.4768424",
    destinationlatitude: "12.3679516",
    destinationlongitude: "-1.5184418",
    createdAt: "2024-01-17T22:11:36.803Z",
    status: "CANCELED",
    deliveryAmount: "6300",
    courierID: "",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    whyCanceled: "bouton retour appuyé",
    updatedAt: "2024-01-17T22:11:54.583Z"
  },
  {
    id: "b66bed49-0937-492c-b94c-acfc51e52917",
    type: "Livraison de Colis",
    originlatitude: "12.377585",
    originlongitude: "-1.4822386",
    destinationlatitude: "12.3999465",
    destinationlongitude: "-1.5577945",
    createdAt: "2024-01-19T13:02:28.475Z",
    status: "CANCELED",
    deliveryAmount: "1100",
    courierID: "",
    userID: "0b83ff32-c341-4e16-9c00-f8990f205c24",
    whyCanceled: "Course non acceptée",
    updatedAt: "2024-01-19T13:03:17.530Z"
  },
  {
    id: "918c9630-0c65-43df-8d2f-8f1d24c144da",
    type: "Livraison de Colis",
    originlatitude: "12.377585",
    originlongitude: "-1.4822386",
    destinationlatitude: "12.3999465",
    destinationlongitude: "-1.5577945",
    createdAt: "2024-01-19T13:05:17.596Z",
    status: "CANCELED",
    deliveryAmount: "1100",
    courierID: "",
    userID: "0b83ff32-c341-4e16-9c00-f8990f205c24",
    whyCanceled: "Course non acceptée",
    updatedAt: "2024-01-19T13:06:17.812Z"
  },
  {
    id: "31ac0566-a8db-47ff-a5b6-77c1367ed0be",
    type: "Livraison de Colis",
    originlatitude: "12.377585",
    originlongitude: "-1.4822386",
    destinationlatitude: "12.3999465",
    destinationlongitude: "-1.5577945",
    createdAt: "2024-01-19T13:17:52.405Z",
    status: "CANCELED",
    deliveryAmount: "1100",
    courierID: "",
    userID: "0b83ff32-c341-4e16-9c00-f8990f205c24",
    whyCanceled: "Course non acceptée",
    updatedAt: "2024-01-19T13:18:52.522Z"
  },
  {
    id: "5e2b7d88-145d-4408-b5db-48015cc7c4f7",
    type: "Transport à Moto",
    originlatitude: "12.378367",
    originlongitude: "-1.4792762",
    destinationlatitude: "12.3714277",
    destinationlongitude: "-1.5196603",
    createdAt: "2024-01-22T22:24:52.278Z",
    status: "CANCELED",
    deliveryAmount: "1000",
    courierID: "",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    whyCanceled: "bouton retour appuyé",
    updatedAt: "2024-01-22T22:25:13.459Z"
  },
  {
    id: "19ce7ffb-da1f-4c56-9cea-99ac05b6a624",
    type: "Transport bagage",
    originlatitude: "12.376618",
    originlongitude: "-1.4768415",
    destinationlatitude: "12.3766196",
    destinationlongitude: "-1.4768424",
    createdAt: "2024-01-22T17:02:46.434Z",
    status: "CANCELED",
    deliveryAmount: "1000",
    courierID: "",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    whyCanceled: "bouton retour appuyé",
    updatedAt: "2024-01-22T17:02:50.016Z"
  },
  {
    id: "05add69a-64ac-4399-8a09-1f7ab1b8447d",
    type: "Livraison de Colis",
    originlatitude: "12.3714277",
    originlongitude: "-1.5196603",
    destinationlatitude: "12.3220677",
    destinationlongitude: "-1.5329678",
    createdAt: "2024-01-22T22:39:12.862Z",
    status: "CANCELED",
    deliveryAmount: "1000",
    courierID: "",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    whyCanceled: "bouton retour appuyé",
    updatedAt: "2024-01-22T22:39:18.332Z"
  },
  {
    id: "3271dfe8-9d7b-4664-9e41-8cbd1b1a5e5b",
    type: "Transport bagage",
    originlatitude: "12.376618",
    originlongitude: "-1.4768415",
    destinationlatitude: "12.3766196",
    destinationlongitude: "-1.4768424",
    createdAt: "2024-01-22T17:02:29.872Z",
    status: "CANCELED",
    deliveryAmount: "1000",
    courierID: "",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    whyCanceled: "bouton retour appuyé",
    updatedAt: "2024-01-22T17:02:42.463Z"
  },
  {
    id: "e184454e-63f4-43e3-a911-07bd7a763636",
    type: "Transport bagage",
    originlatitude: "12.378367",
    originlongitude: "-1.4792762",
    destinationlatitude: "12.3714277",
    destinationlongitude: "-1.5196603",
    createdAt: "2024-01-22T22:24:20.931Z",
    status: "CANCELED",
    deliveryAmount: "4200",
    courierID: "",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    whyCanceled: "bouton retour appuyé",
    updatedAt: "2024-01-22T22:24:40.108Z"
  },
  {
    id: "d4a5c44d-b652-4a2f-a1d2-53997ff773cf",
    type: "Livraison de Colis",
    originlatitude: "12.371096",
    originlongitude: "-1.4986813",
    destinationlatitude: "12.3059077",
    destinationlongitude: "-1.519919",
    createdAt: "2024-01-26T13:20:23.604Z",
    status: "CANCELED",
    deliveryAmount: "1000",
    courierID: "",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    whyCanceled: "bouton retour appuyé",
    updatedAt: "2024-01-26T13:21:02.087Z"
  },
  {
    id: "9bf95c22-f866-4c65-9bb8-83fc8e6eadab",
    type: "Transport à Moto",
    originlatitude: "12.3725316",
    originlongitude: "-1.4968282",
    destinationlatitude: "12.3213267",
    destinationlongitude: "-1.4785127",
    createdAt: "2024-01-26T13:57:33.639Z",
    status: "PICK_UP",
    deliveryAmount: "1200",
    courierID: "9500eac0-4f38-462f-993a-f2e23bad11f8",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    whyCanceled: "",
    updatedAt: "2024-01-26T13:58:14.252Z"
  },
  {
    id: "57b965ae-be2d-4a90-8456-8e6913039120",
    type: "Transport à Moto",
    originlatitude: "12.3725514",
    originlongitude: "-1.4968749",
    destinationlatitude: "12.3213267",
    destinationlongitude: "-1.4785127",
    createdAt: "2024-01-26T14:01:43.431Z",
    status: "COMPLETED",
    deliveryAmount: "1200",
    courierID: "9500eac0-4f38-462f-993a-f2e23bad11f8",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    whyCanceled: "",
    updatedAt: "2024-01-26T14:02:50.654Z"
  },
  {
    id: "c242106d-13fe-445b-afff-8d83a1920bf4",
    type: "Livraison de Colis",
    originlatitude: "12.371096",
    originlongitude: "-1.4986813",
    destinationlatitude: "12.3059077",
    destinationlongitude: "-1.519919",
    createdAt: "2024-01-26T14:04:45.285Z",
    status: "PICK_UP",
    deliveryAmount: "1000",
    courierID: "9500eac0-4f38-462f-993a-f2e23bad11f8",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    whyCanceled: "",
    updatedAt: "2024-01-26T14:05:09.134Z"
  },
  {
    id: "36570003-eb5c-42f3-845e-706cb8e3e61b",
    type: "Transport à Moto",
    originlatitude: "12.3624631",
    originlongitude: "-1.4748932",
    destinationlatitude: "12.3714277",
    destinationlongitude: "-1.5196603",
    createdAt: "2024-01-30T00:41:52.123Z",
    status: "CANCELED",
    deliveryAmount: "1000",
    courierID: "",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    whyCanceled: "Course non acceptée",
    updatedAt: "2024-01-30T00:42:59.474Z"
  },
  {
    id: "336cfbe3-0a4b-4141-8622-8574966e0e95",
    type: "Livraison de Colis",
    originlatitude: "12.3783927",
    originlongitude: "-1.4148363",
    destinationlatitude: "12.3714277",
    destinationlongitude: "-1.5196603",
    createdAt: "2024-01-30T13:30:15.619Z",
    status: "CANCELED",
    deliveryAmount: "1200",
    courierID: "",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    whyCanceled: "bouton retour appuyé",
    updatedAt: "2024-01-30T13:30:30.619Z"
  },
  {
    id: "9783a4ae-13d1-492b-bf53-6d2ef2d26c97",
    type: "Livraison de Colis",
    originlatitude: "12.3679516",
    originlongitude: "-1.5184418",
    destinationlatitude: "12.3533496",
    destinationlongitude: "-1.5693045",
    createdAt: "2024-01-30T13:30:56.320Z",
    status: "COMPLETED",
    deliveryAmount: "1000",
    courierID: "9500eac0-4f38-462f-993a-f2e23bad11f8",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    whyCanceled: "",
    updatedAt: "2024-01-30T13:31:59.206Z"
  },
  {
    id: "27da5697-d7e5-493d-a71a-1e18a0989558",
    type: "Transport à Moto",
    originlatitude: "12.3679516",
    originlongitude: "-1.5184418",
    destinationlatitude: "12.3220677",
    destinationlongitude: "-1.5329678",
    createdAt: "2024-01-30T13:34:31.689Z",
    status: "COMPLETED",
    deliveryAmount: "1000",
    courierID: "9500eac0-4f38-462f-993a-f2e23bad11f8",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    whyCanceled: "",
    updatedAt: "2024-01-30T13:34:59.948Z"
  },
  {
    id: "165d8dbc-be1d-4398-87ec-57e25f845f5f",
    type: "Livraison de Colis",
    originlatitude: "12.371096",
    originlongitude: "-1.4986813",
    destinationlatitude: "12.3059077",
    destinationlongitude: "-1.519919",
    createdAt: "2024-01-26T13:36:33.097Z",
    status: "CANCELED",
    deliveryAmount: "1100",
    courierID: "5dd15799-e91b-4faf-b743-5855ec6872f9",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    whyCanceled: "bouton retour appuyé",
    updatedAt: "2024-01-26T13:39:26.533Z"
  },
  {
    id: "2ab54136-8048-44d2-94f7-07d2052b812d",
    type: "Transport à Moto",
    originlatitude: "12.3725245",
    originlongitude: "-1.4968365",
    destinationlatitude: "12.3059077",
    destinationlongitude: "-1.519919",
    createdAt: "2024-01-26T13:29:08.424Z",
    status: "ACCEPTED",
    deliveryAmount: "1500",
    courierID: "5dd15799-e91b-4faf-b743-5855ec6872f9",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    whyCanceled: "",
    updatedAt: "2024-01-26T13:29:36.470Z"
  },
  {
    id: "8646b16c-70d9-41bb-8129-8907e2d52fb6",
    type: "Transport à Moto",
    originlatitude: "12.371096",
    originlongitude: "-1.4986813",
    destinationlatitude: "12.3059077",
    destinationlongitude: "-1.519919",
    createdAt: "2024-01-26T13:24:19.854Z",
    status: "COMPLETED",
    deliveryAmount: "1300",
    courierID: "5dd15799-e91b-4faf-b743-5855ec6872f9",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    whyCanceled: "",
    updatedAt: "2024-01-26T13:27:30.463Z"
  },
  {
    id: "53c35a2d-d046-459e-8c3d-0fd8e47991b5",
    type: "Transport à Moto",
    originlatitude: "12.372522",
    originlongitude: "-1.4968089",
    destinationlatitude: "12.3059077",
    destinationlongitude: "-1.519919",
    createdAt: "2024-01-26T13:53:49.745Z",
    status: "PICK_UP",
    deliveryAmount: "1600",
    courierID: "5dd15799-e91b-4faf-b743-5855ec6872f9",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    whyCanceled: "",
    updatedAt: "2024-01-26T13:55:32.196Z"
  },
  {
    id: "2aa2385d-beb3-4c60-b272-54bc38b6546c",
    type: "Livraison de Colis",
    originlatitude: "12.371096",
    originlongitude: "-1.4986813",
    destinationlatitude: "12.3059077",
    destinationlongitude: "-1.519919",
    createdAt: "2024-01-26T13:39:43.788Z",
    status: "ACCEPTED",
    deliveryAmount: "1100",
    courierID: "5dd15799-e91b-4faf-b743-5855ec6872f9",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    whyCanceled: "",
    updatedAt: "2024-01-26T13:39:53.321Z"
  },
  {
    id: "bab3b293-2b03-4a19-980e-10781c1a884f",
    type: "Livraison de Colis",
    originlatitude: "12.371096",
    originlongitude: "-1.4986813",
    destinationlatitude: "12.3213267",
    destinationlongitude: "-1.4785127",
    createdAt: "2024-02-01T14:43:03.138Z",
    status: "CANCELED",
    deliveryAmount: "1000",
    courierID: "",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    whyCanceled: "Course non acceptée",
    updatedAt: "2024-02-01T14:45:39.036Z"
  },
  {
    id: "c5cac7d5-6740-4f5b-99f0-d1f834ca179f",
    type: "Transport à Moto",
    originlatitude: "12.3533496",
    originlongitude: "-1.5693045",
    destinationlatitude: "12.3714277",
    destinationlongitude: "-1.5196603",
    createdAt: "2024-02-05T12:32:46.747Z",
    status: "CANCELED",
    deliveryAmount: "2000",
    courierID: "",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    whyCanceled: "bouton retour appuyé",
    updatedAt: "2024-02-05T12:33:02.661Z"
  },
  {
    id: "facbdebc-ebbb-4045-b12d-b94eb881d7bf",
    type: "Livraison de Colis",
    originlatitude: "12.3679516",
    originlongitude: "-1.5184418",
    destinationlatitude: "12.3533496",
    destinationlongitude: "-1.5693045",
    createdAt: "2024-02-05T13:18:12.011Z",
    status: "CANCELED",
    deliveryAmount: "1000",
    courierID: "",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    whyCanceled: "Course non acceptée",
    updatedAt: "2024-02-05T13:19:12.023Z"
  },
  {
    id: "09a02f22-e2da-4a0d-975b-9f5660836ced",
    type: "Livraison de Colis",
    originlatitude: "12.3533496",
    originlongitude: "-1.5693045",
    destinationlatitude: "12.3963423",
    destinationlongitude: "-1.5620347",
    createdAt: "2024-02-05T13:20:57.380Z",
    status: "CANCELED",
    deliveryAmount: "1000",
    courierID: "",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    whyCanceled: "Course non acceptée",
    updatedAt: "2024-02-05T13:21:57.736Z"
  },
  {
    id: "81891487-bf5a-48a9-9709-f91c7cdf972f",
    type: "Livraison de Colis",
    originlatitude: "12.3533496",
    originlongitude: "-1.5693045",
    destinationlatitude: "12.3714277",
    destinationlongitude: "-1.5196603",
    createdAt: "2024-02-05T13:27:22.603Z",
    status: "COMPLETED",
    deliveryAmount: "1400",
    courierID: "08314558-a87f-4011-9362-00feda3063a5",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    whyCanceled: "",
    updatedAt: "2024-02-05T13:29:19.555Z"
  },
  {
    id: "ba928036-1a2a-45a0-97c4-0ca77ff21e8e",
    type: "Transport à Moto",
    originlatitude: "12.3533496",
    originlongitude: "-1.5693045",
    destinationlatitude: "12.3783927",
    destinationlongitude: "-1.4148363",
    createdAt: "2024-02-05T13:30:12.231Z",
    status: "CANCELED",
    deliveryAmount: "3600",
    courierID: "",
    userID: "11da57b9-2993-4aa2-9883-d93482dc6119",
    whyCanceled: "Course non acceptée",
    updatedAt: "2024-02-05T13:31:12.421Z"
  },
  {
    id: "448f741d-0377-44d7-ac5a-427287baf338",
    type: "Livraison de Colis",
    originlatitude: "12.3059077",
    originlongitude: "-1.519919",
    destinationlatitude: "12.335102",
    destinationlongitude: "-1.5051306",
    createdAt: "2024-02-05T23:22:55.153Z",
    status: "CANCELED",
    deliveryAmount: "1000",
    courierID: "",
    userID: "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
    whyCanceled: "bouton retour appuyé",
    updatedAt: "2024-02-05T23:23:33.929Z"
  }
];

export const mockDataWallet = [
  
  {
    id: "b615062e-279b-46f1-ab8d-8e76e36c4118",
    balance: 11954,
    createdAt: "2023-11-17T12:37:48.275Z",
    updatedAt: "2024-02-08T11:25:22.623Z",
    walletDriverId: "7e894bc2-f9fc-457a-93b2-1edacc5c3969",
    walletCourierId: "",
    walletUserId: "",
    walletRestaurantId: ""
  },
  {
    id: "d82115d6-b433-4d9f-b852-825fca2a1dfc",
    balance: 2000,
    createdAt: "2024-01-16T09:54:01.116Z",
    updatedAt: "2024-01-16T10:16:43.189Z",
    walletDriverId: "d2e561ae-ec43-4f5d-b9e1-3ff939daf733",
    walletCourierId: "",
    walletUserId: "",
    walletRestaurantId: ""
  },
  {
    id: "9728b2be-e618-49fe-b7a7-05d309a37381",
    balance: 5800,
    createdAt: "2024-01-06T22:29:08.007Z",
    updatedAt: "2024-01-30T13:34:59.151Z",
    walletDriverId: "",
    walletCourierId: "9500eac0-4f38-462f-993a-f2e23bad11f8",
    walletUserId: "",
    walletRestaurantId: ""
  },
  {
    id: "39662f84-a321-4d27-886c-a5c9c8bf87bc",
    balance: 2000,
    createdAt: "2023-12-27T16:41:40.818Z",
    updatedAt: "2023-12-27T17:16:53.303Z",
    walletDriverId: "d9ad2b5e-cf7f-4a01-a78b-8cf0b9195d87",
    walletCourierId: "",
    walletUserId: "",
    walletRestaurantId: ""
  },
  {
    id: "fcc13505-1451-4717-b52e-8013acf40fb7",
    balance: 1000,
    createdAt: "2023-12-26T12:14:58.365Z",
    updatedAt: "2023-12-29T19:13:46.482Z",
    walletDriverId: "a0725d49-9399-40fd-8758-4f39e5f17b53",
    walletCourierId: "",
    walletUserId: "",
    walletRestaurantId: ""
  },
  {
    id: "107f4ed7-a53f-4651-aa11-4ed155552d00",
    balance: 2000,
    createdAt: "2023-12-26T11:49:00.637Z",
    updatedAt: "2023-12-29T19:18:05.546Z",
    walletDriverId: "cf949dbc-3637-458a-99ce-f2f211eea34f",
    walletCourierId: "",
    walletUserId: "",
    walletRestaurantId: ""
  },
  {
    id: "91c4c9b6-6613-44f0-b5e0-75e9ef4d45ae",
    balance: 0,
    createdAt: "2024-01-06T22:13:53.534Z",
    updatedAt: "2024-01-06T22:13:53.534Z",
    walletDriverId: "",
    walletCourierId: "",
    walletUserId: "",
    walletRestaurantId: "5e88574b-ea65-4258-b44f-d0a6a82823fb"
  },
  {
    id: "869158d2-1399-4fea-bbf3-1cdd789418d5",
    balance: 1500,
    createdAt: "2024-01-06T15:14:51.998Z",
    updatedAt: "2024-02-05T13:39:04.433Z",
    walletDriverId: "",
    walletCourierId: "",
    walletUserId: "",
    walletRestaurantId: "3b2440f8-f6ff-4a33-af35-1fd79b5cb2c6"
  },
  {
    id: "3086d1ec-17bc-4e12-a20a-54528e5be8d4",
    balance: 5000,
    createdAt: "2023-12-27T11:12:21.066Z",
    updatedAt: "2023-12-27T11:29:08.443Z",
    walletDriverId: "cd5b189e-d2d4-4ce8-9bfb-65c5298a1aae",
    walletCourierId: "",
    walletUserId: "",
    walletRestaurantId: ""
  },
  {
    id: "0156004c-cbaa-4dce-b2cf-79f3a5f49b18",
    balance: 3735,
    createdAt: "2024-01-05T08:50:32.010Z",
    updatedAt: "2024-02-08T11:27:40.529Z",
    walletDriverId: "1c8af1cc-eb06-4c03-8d86-9d0ca9a83902",
    walletCourierId: "",
    walletUserId: "",
    walletRestaurantId: ""
  },
  {
    id: "e1ca4f8a-a194-453c-99f0-e56554ffb0ea",
    balance: 2000,
    createdAt: "2023-12-28T17:08:35.118Z",
    updatedAt: "2024-01-12T20:12:42.460Z",
    walletDriverId: "46d58f9a-899e-4565-bb69-45598f8b2f65",
    walletCourierId: "",
    walletUserId: "",
    walletRestaurantId: ""
  },
  {
    id: "e3e36b3b-8573-4605-8370-a4f3bbaa2b55",
    balance: 1640,
    createdAt: "2023-12-29T10:26:01.800Z",
    updatedAt: "2024-01-16T12:40:12.238Z",
    walletDriverId: "8c7baf79-a288-48a3-bba2-c526418e0b6a",
    walletCourierId: "",
    walletUserId: "",
    walletRestaurantId: ""
  },
  {
    id: "9e05ab7d-c999-48d8-862e-6fd37ac492d0",
    balance: 2000,
    createdAt: "2023-12-27T12:09:53.331Z",
    updatedAt: "2023-12-27T12:31:14.034Z",
    walletDriverId: "70dab02f-f00c-4b6f-8ea5-fd965532f8f7",
    walletCourierId: "",
    walletUserId: "",
    walletRestaurantId: ""
  },
  {
    id: "2c5b2f25-8673-4712-ac20-e45e19581751",
    balance: 4060,
    createdAt: "2024-01-10T10:27:03.484Z",
    updatedAt: "2024-01-22T10:02:12.208Z",
    walletDriverId: "a690fcd9-ae9f-4071-917e-f2388ff868dd",
    walletCourierId: "",
    walletUserId: "",
    walletRestaurantId: ""
  },
  {
    id: "81d0f477-e5cf-435b-9e6f-414dcc534b39",
    balance: 5000,
    createdAt: "2023-12-27T10:36:03.339Z",
    updatedAt: "2023-12-27T11:03:25.700Z",
    walletDriverId: "f8915769-4743-4f57-8d55-de31cb65c83b",
    walletCourierId: "",
    walletUserId: "",
    walletRestaurantId: ""
  },
  {
    id: "af2a8d0f-dae1-4227-a5f7-e0d58b09d18b",
    balance: 2000,
    createdAt: "2024-02-01T12:02:32.073Z",
    updatedAt: "2024-02-01T12:37:54.949Z",
    walletDriverId: "2e0d899d-c78b-4922-82d3-4cf77c7d50f2",
    walletCourierId: "",
    walletUserId: "",
    walletRestaurantId: ""
  },
  {
    id: "ef2b1b80-44d6-4668-9095-51318a3b58b1",
    balance: 93900,
    createdAt: "2024-02-05T12:30:34.690Z",
    updatedAt: "2024-02-05T13:39:02.906Z",
    walletDriverId: "",
    walletCourierId: "08314558-a87f-4011-9362-00feda3063a5",
    walletUserId: "",
    walletRestaurantId: ""
  },
  {
    id: "78142cd8-e8b6-40ab-9d39-06aad3667bf4",
    balance: 2000,
    createdAt: "2024-02-05T12:50:37.403Z",
    updatedAt: "2024-02-05T13:05:32.151Z",
    walletDriverId: "d975ac95-2795-4643-9445-f2162132b886",
    walletCourierId: "",
    walletUserId: "",
    walletRestaurantId: ""
  },
  {
    id: "cb33e073-8c04-4196-8207-422ca6f3f24e",
    balance: 2000,
    createdAt: "2024-02-08T11:08:13.856Z",
    updatedAt: "2024-02-08T11:33:22.455Z",
    walletDriverId: "4bc1a903-6f36-4589-888b-d084519bb537",
    walletCourierId: "",
    walletUserId: "",
    walletRestaurantId: ""
  }
];

export const mockDataCarOrder = [
    {
      "id": "c32fea87-cdb9-4b5b-9697-e44b75e39bab",
      "type": "Eco",
      "originlatitude": 12.3559028,
      "originlongitude": -1.5355614,
      "destinationlatitude": 12.2625943,
      "destinationlongitude": -1.5384359,
      "createdAt": "2024-01-17T12:08:27.362Z",
      "status": "CANCELLED",
      "driveAmont": 3700,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "Course non acceptée",
      "FeesDebited": null,
      "driverID": "",
      "userID": "844e17ba-cfbf-4fcc-8ecc-78baebf719b4",
      "updatedAt": "2024-01-17T12:09:27.468Z"
    },
    {
      "id": "9cabd703-52d8-475d-9bd7-c8f0362c6b88",
      "type": "Eco",
      "originlatitude": 12.3213267,
      "originlongitude": -1.4785127,
      "destinationlatitude": 12.3059077,
      "destinationlongitude": -1.519919,
      "createdAt": "2024-01-08T21:17:01.069Z",
      "status": "CANCELLED",
      "driveAmont": 2200,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "Course non acceptée",
      "FeesDebited": null,
      "driverID": "",
      "userID": "e92767ad-5a57-4975-a5dd-753c0d93b677",
      "updatedAt": "2024-01-08T21:18:01.663Z"
    },
    {
      "id": "160af17f-a373-45ab-b0dd-b53da4e1ed2a",
      "type": "Eco",
      "originlatitude": 12.3622645,
      "originlongitude": -1.5481091,
      "destinationlatitude": 12.3819868,
      "destinationlongitude": -1.5245297,
      "createdAt": "2024-01-08T00:13:33.797Z",
      "status": "CANCELLED",
      "driveAmont": 1400,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "Course non acceptée",
      "FeesDebited": null,
      "driverID": "",
      "userID": "282c7d3b-ddd1-45af-afaa-a32901415f71",
      "updatedAt": "2024-01-08T00:14:33.541Z"
    },
    {
      "id": "6347b657-eb59-4b56-91fc-4b43837f1da7",
      "type": "Confort",
      "originlatitude": 12.3425969,
      "originlongitude": -1.4795952,
      "destinationlatitude": 12.3682839,
      "destinationlongitude": -1.5184067,
      "createdAt": "2024-01-16T22:17:58.316Z",
      "status": "CANCELLED",
      "driveAmont": 1000,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "Course non acceptée",
      "FeesDebited": null,
      "driverID": "",
      "userID": "e2e3489f-de0f-4f1a-8b24-deed362d337c",
      "updatedAt": "2024-01-16T22:19:12.699Z"
    },
    {
      "id": "03bee411-899e-4071-8449-44b50eea07fc",
      "type": "Confort +",
      "originlatitude": 12.3585079,
      "originlongitude": -1.5449655,
      "destinationlatitude": 12.3819868,
      "destinationlongitude": -1.5245297,
      "createdAt": "2024-01-08T10:32:52.388Z",
      "status": "CANCELLED",
      "driveAmont": 2600,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "Course non acceptée",
      "FeesDebited": null,
      "driverID": "",
      "userID": "282c7d3b-ddd1-45af-afaa-a32901415f71",
      "updatedAt": "2024-01-08T10:33:52.601Z"
    },
    {
      "id": "5418af9e-284e-4e67-bc77-2de29b70ad70",
      "type": "Confort",
      "originlatitude": 12.3725393,
      "originlongitude": -1.4968336,
      "destinationlatitude": 12.3059077,
      "destinationlongitude": -1.519919,
      "createdAt": "2024-01-09T10:44:56.294Z",
      "status": "CANCELLED",
      "driveAmont": 4000,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "Course non acceptée",
      "FeesDebited": null,
      "driverID": "",
      "userID": "e92767ad-5a57-4975-a5dd-753c0d93b677",
      "updatedAt": "2024-01-09T10:45:55.898Z"
    },
    {
      "id": "42b137bd-972c-4e02-bf2c-fc5360ffe227",
      "type": "Eco",
      "originlatitude": 12.4345035,
      "originlongitude": -1.3722684,
      "destinationlatitude": 12.3250239,
      "destinationlongitude": -1.5160212,
      "createdAt": "2024-01-09T15:26:46.942Z",
      "status": "CANCELLED",
      "driveAmont": 7800,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "Course non acceptée",
      "FeesDebited": null,
      "driverID": "",
      "userID": "604641c2-4aa3-4a7a-889c-56adcd29e957",
      "updatedAt": "2024-01-09T15:27:47.060Z"
    },
    {
      "id": "876ea1da-4ea4-41aa-adb4-3930337478c8",
      "type": "Eco",
      "originlatitude": 12.3533496,
      "originlongitude": -1.5693045,
      "destinationlatitude": 12.2850926,
      "destinationlongitude": -1.4918235,
      "createdAt": "2024-01-10T10:41:14.332Z",
      "status": "ACCEPTED",
      "driveAmont": 4500,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "",
      "FeesDebited": null,
      "driverID": "cf949dbc-3637-458a-99ce-f2f211eea34f",
      "userID": "f974cc1c-ce09-4895-a946-c04c22e4521c",
      "updatedAt": "2024-01-12T11:46:58.062Z"
    },
    {
      "id": "73179d84-e336-41a7-bee9-9c9d37f2ecd9",
      "type": "Eco",
      "originlatitude": 12.3714277,
      "originlongitude": -1.5196603,
      "destinationlatitude": 12.2850926,
      "destinationlongitude": -1.4918235,
      "createdAt": "2024-01-11T19:07:45.230Z",
      "status": "ACCEPTED",
      "driveAmont": 4400,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "",
      "FeesDebited": null,
      "driverID": "cd5b189e-d2d4-4ce8-9bfb-65c5298a1aae",
      "userID": "14ae33d7-f7fc-42cc-aac2-a50904c467d2",
      "updatedAt": "2024-01-11T23:06:35.074Z"
    },
    {
      "id": "de46acfd-195a-45b9-98b1-3715af0d1c07",
      "type": "Eco",
      "originlatitude": 12.3077047,
      "originlongitude": -1.4939706,
      "destinationlatitude": 12.3250239,
      "destinationlongitude": -1.5160212,
      "createdAt": "2024-01-17T22:10:33.627Z",
      "status": "CANCELLED",
      "driveAmont": 1500,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "Course non acceptée",
      "FeesDebited": null,
      "driverID": "",
      "userID": "ee65ed8e-7aa4-4e8d-9ffd-0a9c0d92f740",
      "updatedAt": "2024-01-17T22:11:33.792Z"
    },
    {
      "id": "d4ae7e73-c983-4aac-aa6e-a6234d3ad4be",
      "type": "Economique",
      "originlatitude": 12.4154641,
      "originlongitude": -1.4712661,
      "destinationlatitude": 12.3220677,
      "destinationlongitude": -1.5329678,
      "createdAt": "2024-02-05T11:58:13.451Z",
      "status": "CANCELLED",
      "driveAmont": 4800,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "Course non acceptée",
      "FeesDebited": null,
      "driverID": "",
      "userID": "11da57b9-2993-4aa2-9883-d93482dc6119",
      "updatedAt": "2024-02-05T11:59:13.469Z"
    },
    {
      "id": "d60828a4-3c3f-45a7-a67d-34e789901012",
      "type": "Economique",
      "originlatitude": 12.4154641,
      "originlongitude": -1.4712661,
      "destinationlatitude": 12.3533496,
      "destinationlongitude": -1.5693045,
      "createdAt": "2024-02-05T12:03:55.853Z",
      "status": "COMPLETED",
      "driveAmont": 4400,
      "drivingTimeCost": 0,
      "waitingTimeCost": 0,
      "totalCost": 4400,
      "whyCanceled": "",
      "FeesDebited": 440,
      "driverID": "1c8af1cc-eb06-4c03-8d86-9d0ca9a83902",
      "userID": "11da57b9-2993-4aa2-9883-d93482dc6119",
      "updatedAt": "2024-02-05T12:05:11.446Z"
    },
    {
      "id": "8ae7701b-1aa6-4566-8b34-1b9d4f3f790b",
      "type": "Confort",
      "originlatitude": 12.3533496,
      "originlongitude": -1.5693045,
      "destinationlatitude": 12.2850926,
      "destinationlongitude": -1.4918235,
      "createdAt": "2024-02-05T12:06:01.625Z",
      "status": "COMPLETED",
      "driveAmont": 6000,
      "drivingTimeCost": 0,
      "waitingTimeCost": 0,
      "totalCost": 6000,
      "whyCanceled": "",
      "FeesDebited": 600,
      "driverID": "1c8af1cc-eb06-4c03-8d86-9d0ca9a83902",
      "userID": "11da57b9-2993-4aa2-9883-d93482dc6119",
      "updatedAt": "2024-02-05T12:06:23.661Z"
    },
    {
      "id": "7604cb43-85c7-4d69-8271-bcaddfa3b4ac",
      "type": "Confort",
      "originlatitude": 12.3533496,
      "originlongitude": -1.5693045,
      "destinationlatitude": 12.2850926,
      "destinationlongitude": -1.4918235,
      "createdAt": "2024-02-05T12:07:13.176Z",
      "status": "COMPLETED",
      "driveAmont": 6000,
      "drivingTimeCost": 25,
      "waitingTimeCost": 0,
      "totalCost": 6025,
      "whyCanceled": "",
      "FeesDebited": 603,
      "driverID": "1c8af1cc-eb06-4c03-8d86-9d0ca9a83902",
      "userID": "11da57b9-2993-4aa2-9883-d93482dc6119",
      "updatedAt": "2024-02-05T12:08:53.987Z"
    },
    {
      "id": "0a9fe4c1-44d6-4ca3-a2c1-d3204b5526de",
      "type": "Economique",
      "originlatitude": 12.3725094,
      "originlongitude": -1.4968395,
      "destinationlatitude": 12.3213267,
      "destinationlongitude": -1.4785127,
      "createdAt": "2024-02-05T12:48:56.774Z",
      "status": "CANCELLED",
      "driveAmont": 2300,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "Course non acceptée",
      "FeesDebited": null,
      "driverID": "",
      "userID": "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
      "updatedAt": "2024-02-05T12:49:56.931Z"
    },
    {
      "id": "61d4fcea-52b5-469a-9bf1-e9f57a09519f",
      "type": "Economique",
      "originlatitude": 12.3725094,
      "originlongitude": -1.4968395,
      "destinationlatitude": 12.3213267,
      "destinationlongitude": -1.4785127,
      "createdAt": "2024-02-05T12:51:55.914Z",
      "status": "CANCELLED",
      "driveAmont": 2300,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "Changé d'avis",
      "FeesDebited": null,
      "driverID": "d975ac95-2795-4643-9445-f2162132b886",
      "userID": "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
      "updatedAt": "2024-02-05T12:53:06.594Z"
    },
    {
      "id": "f797cfa1-ac1a-4736-a0c7-77cc6ededdf4",
      "type": "Economique",
      "originlatitude": 12.3725094,
      "originlongitude": -1.4968395,
      "destinationlatitude": 12.3138383,
      "destinationlongitude": -1.4676394,
      "createdAt": "2024-02-05T12:53:24.102Z",
      "status": "CANCELLED",
      "driveAmont": 2700,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "utilisateur retour en arriere",
      "FeesDebited": null,
      "driverID": "",
      "userID": "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
      "updatedAt": "2024-02-05T12:53:53.725Z"
    },
    {
      "id": "ea11c792-3b70-433b-ad24-fd9b3c787629",
      "type": "Economique",
      "originlatitude": 12.3756698,
      "originlongitude": -1.4939591,
      "destinationlatitude": 12.3213267,
      "destinationlongitude": -1.4785127,
      "createdAt": "2024-02-05T12:54:24.282Z",
      "status": "COMPLETED",
      "driveAmont": 2500,
      "drivingTimeCost": 0,
      "waitingTimeCost": 50,
      "totalCost": 2550,
      "whyCanceled": "",
      "FeesDebited": 255,
      "driverID": "d975ac95-2795-4643-9445-f2162132b886",
      "userID": "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
      "updatedAt": "2024-02-05T12:58:11.577Z"
    },
    {
      "id": "83f61ce8-8782-4887-89e6-57034a42e34b",
      "type": "Economique",
      "originlatitude": 12.3756698,
      "originlongitude": -1.4939591,
      "destinationlatitude": 12.3213267,
      "destinationlongitude": -1.4785127,
      "createdAt": "2024-02-05T13:00:19.417Z",
      "status": "CANCELLED",
      "driveAmont": 2500,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "Course non acceptée",
      "FeesDebited": null,
      "driverID": "",
      "userID": "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
      "updatedAt": "2024-02-05T13:01:19.505Z"
    },
    {
      "id": "de58ee49-8440-436a-b494-2abdc7a62d50",
      "type": "Economique",
      "originlatitude": 12.3725282,
      "originlongitude": -1.4968291,
      "destinationlatitude": 12.3213267,
      "destinationlongitude": -1.4785127,
      "createdAt": "2024-02-05T13:04:16.757Z",
      "status": "CANCELLED",
      "driveAmont": 2300,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "utilisateur retour en arriere",
      "FeesDebited": null,
      "driverID": "",
      "userID": "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
      "updatedAt": "2024-02-05T13:04:59.388Z"
    },
    {
      "id": "1d33c500-3017-43ff-b746-e6853c03f7c3",
      "type": "Economique",
      "originlatitude": 12.3725282,
      "originlongitude": -1.4968291,
      "destinationlatitude": 12.3213267,
      "destinationlongitude": -1.4785127,
      "createdAt": "2024-02-05T13:06:00.356Z",
      "status": "COMPLETED",
      "driveAmont": 2300,
      "drivingTimeCost": 0,
      "waitingTimeCost": 0,
      "totalCost": 2300,
      "whyCanceled": "",
      "FeesDebited": 230,
      "driverID": "1c8af1cc-eb06-4c03-8d86-9d0ca9a83902",
      "userID": "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
      "updatedAt": "2024-02-05T13:06:56.403Z"
    },
    {
      "id": "55b1ec68-f61f-4cee-aa75-bb0f58c53fec",
      "type": "Economique",
      "originlatitude": 12.3725282,
      "originlongitude": -1.4968291,
      "destinationlatitude": 12.3059077,
      "destinationlongitude": -1.519919,
      "createdAt": "2024-02-05T13:07:24.625Z",
      "status": "CANCELLED",
      "driveAmont": 3000,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "Course non acceptée",
      "FeesDebited": null,
      "driverID": "",
      "userID": "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
      "updatedAt": "2024-02-05T13:08:24.829Z"
    },
    {
      "id": "482fc811-affa-425f-9e48-d0f2dac1ea6a",
      "type": "Economique",
      "originlatitude": 12.3725031,
      "originlongitude": -1.4968283,
      "destinationlatitude": 12.3059077,
      "destinationlongitude": -1.519919,
      "createdAt": "2024-02-05T14:34:52.868Z",
      "status": "COMPLETED",
      "driveAmont": 3000,
      "drivingTimeCost": 75,
      "waitingTimeCost": 0,
      "totalCost": 3075,
      "whyCanceled": "",
      "FeesDebited": 308,
      "driverID": "1c8af1cc-eb06-4c03-8d86-9d0ca9a83902",
      "userID": "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
      "updatedAt": "2024-02-05T14:38:33.270Z"
    },
    {
      "id": "e08ee834-d9fb-4ba4-b11e-ef353eacab39",
      "type": "Economique",
      "originlatitude": 12.3725031,
      "originlongitude": -1.4968283,
      "destinationlatitude": 12.3059077,
      "destinationlongitude": -1.519919,
      "createdAt": "2024-02-05T14:39:07.523Z",
      "status": "COMPLETED",
      "driveAmont": 3000,
      "drivingTimeCost": 75,
      "waitingTimeCost": 0,
      "totalCost": 3075,
      "whyCanceled": "",
      "FeesDebited": 308,
      "driverID": "1c8af1cc-eb06-4c03-8d86-9d0ca9a83902",
      "userID": "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
      "updatedAt": "2024-02-05T14:42:40.252Z"
    },
    {
      "id": "ce89073a-c7dd-4783-a5d5-c5e1bd161031",
      "type": "Economique",
      "originlatitude": 12.3725095,
      "originlongitude": -1.4968291,
      "destinationlatitude": 12.3059077,
      "destinationlongitude": -1.519919,
      "createdAt": "2024-02-05T14:43:05.479Z",
      "status": "COMPLETED",
      "driveAmont": 3000,
      "drivingTimeCost": 100,
      "waitingTimeCost": 0,
      "totalCost": 3100,
      "whyCanceled": "",
      "FeesDebited": 310,
      "driverID": "1c8af1cc-eb06-4c03-8d86-9d0ca9a83902",
      "userID": "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
      "updatedAt": "2024-02-05T14:47:42.920Z"
    },
    {
      "id": "e59daa30-0af2-4757-921d-69131e4263ac",
      "type": "Economique",
      "originlatitude": 12.372533,
      "originlongitude": -1.4968645,
      "destinationlatitude": 12.3059077,
      "destinationlongitude": -1.519919,
      "createdAt": "2024-02-05T14:47:35.581Z",
      "status": "COMPLETED",
      "driveAmont": 3000,
      "drivingTimeCost": 25,
      "waitingTimeCost": 0,
      "totalCost": 3025,
      "whyCanceled": "",
      "FeesDebited": 303,
      "driverID": "1c8af1cc-eb06-4c03-8d86-9d0ca9a83902",
      "userID": "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
      "updatedAt": "2024-02-05T14:49:15.591Z"
    },
    {
      "id": "396d4b91-e744-4f8e-b9e8-eee733bebe60",
      "type": "Economique",
      "originlatitude": 12.3724695,
      "originlongitude": -1.4968026,
      "destinationlatitude": 12.3059077,
      "destinationlongitude": -1.519919,
      "createdAt": "2024-02-05T14:51:30.594Z",
      "status": "COMPLETED",
      "driveAmont": 3000,
      "drivingTimeCost": 25,
      "waitingTimeCost": 0,
      "totalCost": 3025,
      "whyCanceled": "",
      "FeesDebited": 303,
      "driverID": "1c8af1cc-eb06-4c03-8d86-9d0ca9a83902",
      "userID": "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
      "updatedAt": "2024-02-05T14:53:17.531Z"
    },
    {
      "id": "95ccd2ef-7e73-4bd2-9903-fd1c62839780",
      "type": "Economique",
      "originlatitude": 12.3724695,
      "originlongitude": -1.4968026,
      "destinationlatitude": 12.3059077,
      "destinationlongitude": -1.519919,
      "createdAt": "2024-02-05T14:49:52.048Z",
      "status": "COMPLETED",
      "driveAmont": 3000,
      "drivingTimeCost": 0,
      "waitingTimeCost": 0,
      "totalCost": 3000,
      "whyCanceled": "",
      "FeesDebited": 300,
      "driverID": "1c8af1cc-eb06-4c03-8d86-9d0ca9a83902",
      "userID": "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
      "updatedAt": "2024-02-05T14:51:02.489Z"
    },
    {
      "id": "e221a574-d0e1-4adf-9293-9a7cd85ded1c",
      "type": "Economique",
      "originlatitude": 12.3725157,
      "originlongitude": -1.4968221,
      "destinationlatitude": 12.3059077,
      "destinationlongitude": -1.519919,
      "createdAt": "2024-02-05T15:07:45.662Z",
      "status": "COMPLETED",
      "driveAmont": 3000,
      "drivingTimeCost": 0,
      "waitingTimeCost": 0,
      "totalCost": 3000,
      "whyCanceled": "",
      "FeesDebited": 300,
      "driverID": "1c8af1cc-eb06-4c03-8d86-9d0ca9a83902",
      "userID": "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
      "updatedAt": "2024-02-05T15:09:24.629Z"
    },
    {
      "id": "634cd6fc-aee2-434b-b05d-3c70d0bb767f",
      "type": "Economique",
      "originlatitude": 12.3725157,
      "originlongitude": -1.4968221,
      "destinationlatitude": 12.3059077,
      "destinationlongitude": -1.519919,
      "createdAt": "2024-02-05T15:10:01.596Z",
      "status": "COMPLETED",
      "driveAmont": 3000,
      "drivingTimeCost": 100,
      "waitingTimeCost": 0,
      "totalCost": 3100,
      "whyCanceled": "",
      "FeesDebited": 310,
      "driverID": "7e894bc2-f9fc-457a-93b2-1edacc5c3969",
      "userID": "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
      "updatedAt": "2024-02-05T15:14:53.361Z"
    },
    {
      "id": "1ac635e6-a951-4e66-a856-31ac9c4f0560",
      "type": "Economique",
      "originlatitude": 12.3725059,
      "originlongitude": -1.4968059,
      "destinationlatitude": 12.3059077,
      "destinationlongitude": -1.519919,
      "createdAt": "2024-02-05T15:15:07.395Z",
      "status": "COMPLETED",
      "driveAmont": 3100,
      "drivingTimeCost": 0,
      "waitingTimeCost": 0,
      "totalCost": 3100,
      "whyCanceled": "",
      "FeesDebited": 310,
      "driverID": "7e894bc2-f9fc-457a-93b2-1edacc5c3969",
      "userID": "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
      "updatedAt": "2024-02-05T15:16:21.676Z"
    },
    {
      "id": "0b95c534-5469-49cd-97de-2f71cf0db8ac",
      "type": "Economique",
      "originlatitude": 12.3725112,
      "originlongitude": -1.4968311,
      "destinationlatitude": 12.3059077,
      "destinationlongitude": -1.519919,
      "createdAt": "2024-02-05T15:44:42.233Z",
      "status": "COMPLETED",
      "driveAmont": 3000,
      "drivingTimeCost": 0,
      "waitingTimeCost": 0,
      "totalCost": 3000,
      "whyCanceled": "",
      "FeesDebited": 300,
      "driverID": "7e894bc2-f9fc-457a-93b2-1edacc5c3969",
      "userID": "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
      "updatedAt": "2024-02-05T15:45:16.852Z"
    },
    {
      "id": "bf52de8b-c450-448c-97d7-3114dca50099",
      "type": "Confort",
      "originlatitude": 12.3725091,
      "originlongitude": -1.4968324,
      "destinationlatitude": 12.3724683,
      "destinationlongitude": -1.4968283,
      "createdAt": "2024-02-05T18:34:05.951Z",
      "status": "COMPLETED",
      "driveAmont": 1000,
      "drivingTimeCost": 0,
      "waitingTimeCost": 0,
      "totalCost": 1000,
      "whyCanceled": "",
      "FeesDebited": 100,
      "driverID": "7e894bc2-f9fc-457a-93b2-1edacc5c3969",
      "userID": "11da57b9-2993-4aa2-9883-d93482dc6119",
      "updatedAt": "2024-02-05T18:34:50.278Z"
    },
    {
      "id": "4ab18a3a-4004-4f3c-b948-43c9a0d93fb7",
      "type": "Confort",
      "originlatitude": 12.2850926,
      "originlongitude": -1.4918235,
      "destinationlatitude": 12.3533496,
      "destinationlongitude": -1.5693045,
      "createdAt": "2024-02-05T18:54:41.520Z",
      "status": "CANCELLED",
      "driveAmont": 6100,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "Course non acceptée",
      "FeesDebited": null,
      "driverID": "",
      "userID": "11da57b9-2993-4aa2-9883-d93482dc6119",
      "updatedAt": "2024-02-05T18:55:41.742Z"
    },
    {
      "id": "ac3de466-4aac-4b99-b8ca-321eee93eb71",
      "type": "Confort",
      "originlatitude": 12.3725091,
      "originlongitude": -1.4968324,
      "destinationlatitude": 12.3724683,
      "destinationlongitude": -1.4968283,
      "createdAt": "2024-02-05T18:32:56.003Z",
      "status": "CANCELLED",
      "driveAmont": 1000,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "utilisateur retour en arriere",
      "FeesDebited": null,
      "driverID": "",
      "userID": "11da57b9-2993-4aa2-9883-d93482dc6119",
      "updatedAt": "2024-02-05T18:33:16.475Z"
    },
    {
      "id": "091648f8-c326-4871-a28a-49548e3b3825",
      "type": "Confort",
      "originlatitude": 12.3714277,
      "originlongitude": -1.5196603,
      "destinationlatitude": 12.3533496,
      "destinationlongitude": -1.5693045,
      "createdAt": "2024-02-05T18:45:28.555Z",
      "status": "CANCELLED",
      "driveAmont": 4700,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "Course non acceptée",
      "FeesDebited": null,
      "driverID": "",
      "userID": "11da57b9-2993-4aa2-9883-d93482dc6119",
      "updatedAt": "2024-02-05T18:46:28.660Z"
    },
    {
      "id": "c5335ef6-cba2-47dc-b9c2-49d2381b706d",
      "type": "Economique",
      "originlatitude": 12.3725091,
      "originlongitude": -1.4968324,
      "destinationlatitude": 12.3724683,
      "destinationlongitude": -1.4968283,
      "createdAt": "2024-02-05T18:32:21.277Z",
      "status": "CANCELLED",
      "driveAmont": 1000,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "utilisateur retour en arriere",
      "FeesDebited": null,
      "driverID": "",
      "userID": "11da57b9-2993-4aa2-9883-d93482dc6119",
      "updatedAt": "2024-02-05T18:32:37.300Z"
    },
    {
      "id": "cf7575d9-c1b7-4403-9238-44908756416e",
      "type": "Economique",
      "originlatitude": 12.3725091,
      "originlongitude": -1.4968324,
      "destinationlatitude": 12.2850926,
      "destinationlongitude": -1.4918235,
      "createdAt": "2024-02-05T18:31:07.364Z",
      "status": "CANCELLED",
      "driveAmont": 3600,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "Course non acceptée",
      "FeesDebited": null,
      "driverID": "",
      "userID": "11da57b9-2993-4aa2-9883-d93482dc6119",
      "updatedAt": "2024-02-05T18:32:07.416Z"
    },
    {
      "id": "1cdf44fa-d0b9-4a66-b1b0-9c5437504ca3",
      "type": "Confort",
      "originlatitude": 12.3679516,
      "originlongitude": -1.5184418,
      "destinationlatitude": 12.3533496,
      "destinationlongitude": -1.5693045,
      "createdAt": "2024-02-05T19:04:47.351Z",
      "status": "CANCELLED",
      "driveAmont": 2900,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "Course non acceptée",
      "FeesDebited": null,
      "driverID": "",
      "userID": "11da57b9-2993-4aa2-9883-d93482dc6119",
      "updatedAt": "2024-02-05T19:05:47.366Z"
    },
    {
      "id": "3c202df1-fb28-400b-9a2b-84bce8ca4c8c",
      "type": "Confort",
      "originlatitude": 12.3220677,
      "originlongitude": -1.5329678,
      "destinationlatitude": 12.3724351,
      "destinationlongitude": -1.4967535,
      "createdAt": "2024-02-07T11:15:42.935Z",
      "status": "CANCELLED",
      "driveAmont": 3300,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "utilisateur retour en arriere",
      "FeesDebited": null,
      "driverID": "",
      "userID": "11da57b9-2993-4aa2-9883-d93482dc6119",
      "updatedAt": "2024-02-07T11:15:57.058Z"
    },
    {
      "id": "f450de97-864e-48ac-8313-13cd02d7e7ce",
      "type": "Economique",
      "originlatitude": 12.3679516,
      "originlongitude": -1.5184418,
      "destinationlatitude": 12.3213267,
      "destinationlongitude": -1.4785127,
      "createdAt": "2024-02-07T09:56:14.114Z",
      "status": "CANCELLED",
      "driveAmont": 3300,
      "drivingTimeCost": null,
      "waitingTimeCost": null,
      "totalCost": null,
      "whyCanceled": "utilisateur retour en arriere",
      "FeesDebited": null,
      "driverID": "",
      "userID": "fb2ddeb4-2c56-4757-ae63-248a8c791fda",
      "updatedAt": "2024-02-07T09:56:53.036Z"
    }
];

export const mockDataDriver = [
  {
    id: "70dab02f-f00c-4b6f-8ea5-fd965532f8f7",
    name: "Ouedraogo",
    sub: "27af6cee-27a2-47dc-a341-11838297a68f",
    phone: 57477382,
    carType: "Confort",
    lat: 12.3256608,
    lng: -1.5049859,
    heading: "0",
    deviceToken: "dbW_t5esSPWFUFxymNYNeC:APA91bEItsul-J2Y1I1TurK8y0Ab7QvzEKOurQYAafQr_oS9MPCqu4JS0xHln4qhpeyw2X--EaxpbuZ9DaqdJwNzNOKk2m_cEc8qSEtSrMO0J62O6qotp1IWBzK6ut_NK5cEJDBAakhe",
    profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.5919693666259129.jpg",
    licensePlate: "6280D403",
    carColor: "Toyota Yaris bleu ",
    rating: "2.5",
    createdAt: "2023-12-27T12:07:07.986Z",
    updatedAt: "2024-02-05T20:08:11.544Z"
  },
  {
    id: "d2e561ae-ec43-4f5d-b9e1-3ff939daf733",
    name: "DICKO Saidou",
    sub: "4a8e04ec-6c73-4973-b3cb-577a874225d9",
    phone: 7156755,
    carType: "Confort",
    lat: 12.3220214,
    lng: -1.5036878,
    heading: "28.595252990722656",
    deviceToken: "fzs9zIqQQj-3T03tzDk0SI:APA91bFLBxbgemv2nLzAw6gD3asPyTcfafhMCF1Z49oeCRTZqk25j73EI70todB-oG0Gr3TJD5ZYmEstGs-0gYq-bk-x31gSPCTHZtdVBHkI6AL8wmdRn9fx0OGvoqUXLH-pGmubmpMp",
    profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.6686642250373899.jpg",
    licensePlate: "2468F803",
    carColor: "Toyota Yaris bleu",
    rating: "0",
    createdAt: "2024-01-16T09:51:55.365Z",
    updatedAt: "2024-02-05T14:48:03.550Z"
  },
  {
    id: "8c7baf79-a288-48a3-bba2-c526418e0b6a",
    name: "Benoît ",
    sub: "7a79c9b5-be5a-4cb7-a28e-ef160c56c707",
    phone: 64761065,
    carType: "Confort",
    lat: 12.3256815,
    lng: -1.5613787,
    heading: "251.28131103515625",
    deviceToken: "eu-aaJjPSP6Rj1p9tw_50v:APA91bGhwIhE-sfPp9ik0YJc9ZHNroglSoh_ZcdZ1fzyp-tv0v9w5-tBAx_h0DAXeezVEj0yxYuJPhMMv4VLKnqE3Z4ttgu6yEa6K_euvYHjWe3hosPMs2JVrmaF0bA_QEb3F0Z-38FH",
    profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.18450547338107837.jpg",
    licensePlate: "7099F603",
    carColor: "Honda Jazz bleu",
    rating: "4.6875",
    createdAt: "2023-12-29T10:21:30.210Z",
    updatedAt: "2024-01-21T22:13:20.551Z"
  },
  {
    id: "cd5b189e-d2d4-4ce8-9bfb-65c5298a1aae",
    name: "Daouda ",
    sub: "558599e2-0173-4f8e-a235-cc7ed37825e2",
    phone: 64758811,
    carType: "Confort",
    lat: 12.3586883,
    lng: -1.547595,
    heading: "0",
    deviceToken: "c86xu6BdSty2_gJH9Q-vXo:APA91bHEpOdZuOBccmttjZarb8dbFpEmhqX2RKt6ZHMc7t2jbfFIUVSq00_GXM0MUbUqqaAF-rSdBs6yAMcCBGNVJHqMDnVk40CUGJac1yrvupaU0M-EZmwVCMnYNk_L8ebVTgexgfQS",
    profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.5951536160374867.jpg",
    licensePlate: "5023F903",
    carColor: "Toyota berline noir",
    rating: "0.625",
    createdAt: "2023-12-27T11:11:14.890Z",
    updatedAt: "2024-02-08T13:07:14.144Z"
  },
  {
    id: "a690fcd9-ae9f-4071-917e-f2388ff868dd",
    name: "Ouedraogo Aboubacar ",
    sub: "a51620e9-01e9-4e8f-a52e-5f96f3891141",
    phone: 7750057,
    carType: "Confort",
    lat: 12.4028328,
    lng: -1.5201547,
    heading: "0",
    deviceToken: "djbETYCUTVqp_g5K-s37kH:APA91bFt0CTXt87NPAc3xWVunEqYgvr-1KszzHxEeBFqVz68IOQoTJX8GxkDHtmI2_zzLC_PGzGxcU_FCc5gReBqMHg2vkZEnuvr2q-LDqCmZN-zOqq7VvqfWADuaxuB86viKlojqZJ6",
    profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.25846772859996836.jpg",
    licensePlate: "5524F806",
    carColor: "Toyota Yaris grise",
    rating: "3.75",
    createdAt: "2024-01-10T10:24:26.513Z",
    updatedAt: "2024-01-29T20:55:34.168Z"
  },
  {
    id: "d9ad2b5e-cf7f-4a01-a78b-8cf0b9195d87",
    name: "NAGALO",
    sub: "837d151a-1df6-4f52-aa96-d860dcff72d6",
    phone: 67144145,
    carType: "Confort",
    lat: 12.30581,
    lng: -1.5277633,
    heading: "0",
    deviceToken: "e-0vStxRQeeOn2EiDvfH9u:APA91bGdjtQBGBaak2tepIfID8LbvfnSgv0o_r-OgswrSVZCKWWSgIa3fpoiwXpHlo5BL2NxQWJBGFmioG66VszLNVntBfoQZlTOVbC8fPZdLVgOqbiXrpUqL6HuYfVjraLCLRtsf6WO",
    profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.3329552147018403.jpg",
    licensePlate: "1675 E6 03",
    carColor: "TOYOTA SEDAN GRIS",
    rating: "0",
    createdAt: "2023-12-27T16:39:04.077Z",
    updatedAt: "2024-01-26T00:00:18.098Z"
  },
  {
    id: "f8915769-4743-4f57-8d55-de31cb65c83b",
    name: "Boukoungo ",
    sub: "954f57b9-75ee-4b53-b2df-9163c32c1703",
    phone: 71618494,
    carType: "Confort",
    lat: 12.3121864,
    lng: -1.5111924,
    heading: "248.00950622558594",
    deviceToken: "eO204LH0RKGq4_cFCnEmdm:APA91bFXvSmLYata8FYKtCmBEb_Za3GtcNN7CL0qW1BlCkZpV-O--5G0ld9SIHhF9eMMSzVtby6ckAyt_nu4N8nHcrKdlfEXKk9v7b9ulSXFD47Tc576wK6ExFGNn4cvo-lAbTV95qbk",
    profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.9748375042215848.jpg",
    licensePlate: "3230F903",
    carColor: "Toyota Corolla verso gris",
    rating: "3.125",
    createdAt: "2023-12-27T10:31:16.130Z",
    updatedAt: "2024-01-25T07:46:38.891Z"
  },
  {
    id: "7e894bc2-f9fc-457a-93b2-1edacc5c3969",
    name: "Driver 10",
    sub: "fb27a141-c90a-4f4d-b610-a88fc74dc9fd",
    phone: 66287588,
    carType: "Confort",
    lat: 12.3766793,
    lng: -1.4770162,
    heading: "288.8625793457031",
    deviceToken: "dS8FOzGESeiIbILJwb46Sf:APA91bFVIsPiQYeZVHjoY9VKNh1XHk3TU67R1TYixcRvjDK_QGtwOBsKlINFxYfqQqEGO2jPJV37RmSRyrSYv2LkUTYWUBnFWP0nayJCzqvKDZsn3uJIaS5d63TPHzCgxHJu2XjfZdAe",
    profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.8986279417755618.jpg",
    licensePlate: "1992 D4 03",
    carColor: "Toyota Corolla grise",
    rating: "4.835970401468712",
    createdAt: "2023-11-22T13:06:01.055Z",
    updatedAt: "2024-02-08T11:32:16.206Z"
  },
  {
    id: "a0725d49-9399-40fd-8758-4f39e5f17b53",
    name: "Sawadogo",
    sub: "d17633e2-a9b8-40fa-a1ad-7f388ecafd34",
    phone: 76013243,
    carType: "Confort",
    lat: 12.3628033,
    lng: -1.516125,
    heading: "0",
    deviceToken: "c_bM5MhuStuumBbwt3dVMD:APA91bGKaeWfpq-nc4kt-8erF5Ge6fp12gj5YvObawdcGS7Wt8cm-wOvm9KN7BAcDEv-34Pa5hzTJ5Mcnt3eDiFAtrDimj4wSn9uBMQpQrkGyWifYCrNAjnmT6QDLZ9Oi4nq9yfgLo5m",
    profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.03639194622606079.jpg",
    licensePlate: "",
    carColor: "TOYOTA ",
    rating: "0",
    createdAt: "2023-12-26T12:13:47.734Z",
    updatedAt: "2024-02-07T14:39:11.246Z"
  },
  {
    id: "46d58f9a-899e-4565-bb69-45598f8b2f65",
    name: "ROMARIC ",
    sub: "21e5f3dd-413c-4cc8-a233-154fc36f3149",
    phone: 69937205,
    carType: "Confort",
    lat: 12.3141346,
    lng: -1.5652071,
    heading: "15.108075141906738",
    deviceToken: "eQkkn-28Sh-JmEWjodOoGZ:APA91bGC-RJ_4UgtMd0k3kTuGD--mLWZwY5ct3YPLfQUxSs0ARPReX1aJsmSCjZ0MYNkaldLLPLNG-rwJIvlc9W0j5i6HZqsHmADLzq7F7Mm0BCK7sdKYtt-jgJk6DiLYtLDZgvo5OTG",
    profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.27998539607096623.jpg",
    licensePlate: "",
    carColor: "Toyota Yaris gris 8246f603",
    rating: "0",
    createdAt: "2024-01-12T18:00:32.571Z",
    updatedAt: "2024-01-22T03:32:47.056Z"
  },
  {
    id: "1c8af1cc-eb06-4c03-8d86-9d0ca9a83902",
    name: "Chauffeur ",
    sub: "e92767ad-5a57-4975-a5dd-753c0d93b677",
    phone: 55577874,
    carType: "Economique",
    lat: 12.37247,
    lng: -1.4968482,
    heading: "71.94539642333984",
    deviceToken: "eg5d-aTcSM-qB6i7396rWR:APA91bGw6i3qF4w6uE-qs8UGxaod9-ZOYM89FTZswL_nOAAWRTNkOYpde39GCf2WqDbNa3d_yNRl0UlCd3kdsVkplJzqnqD7Q0s2q5ddkAN6do1_I-l6UfmGdGvgT3jPPY9zMn6hB0Ed",
    profileImage: "",
    licensePlate: "1pn450",
    carColor: "Camry rouge",
    rating: "4.855468742724042",
    createdAt: "2023-12-19T10:16:52.092Z",
    updatedAt: "2024-02-08T11:38:25.888Z"
  },
  {
    id: "cf949dbc-3637-458a-99ce-f2f211eea34f",
    name: "TIENDREBEOGO ",
    sub: "ba878f85-1d6b-4ade-a27d-19894d648d30",
    phone: 66790027,
    carType: "Confort",
    lat: 12.3401617,
    lng: -1.5664383,
    heading: "0",
    deviceToken: "d8anBFxoSGqSGOiUG1rt8I:APA91bHK9rkSmfgB-_84vh4qRYnT5_AVg7M_rtvlyBvbnpQFtapvT26vXyUz6Hnt1pgE7Q-03L36Z8MsI-JPkNiBsPE5ADri6YZHl7yLTA1R8-nn2EfL07SvYtJIHneMl9J72CG-t6tf",
    profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.12669378467641101.jpg",
    licensePlate: "2207 F7 03",
    carColor: "TOYOTA Berline Noire ",
    rating: "0",
    createdAt: "2023-12-26T11:44:51.395Z",
    updatedAt: "2024-02-07T12:26:50.271Z"
  },
  {
    id: "2e0d899d-c78b-4922-82d3-4cf77c7d50f2",
    name: "KANTE MOHAMED ",
    sub: "ac260b96-6ade-4217-8255-6d05e4b92bad",
    phone: 71615482,
    carType: "Confort",
    lat: 12.3751667,
    lng: -1.40739,
    heading: "0",
    deviceToken: "fsdYdhG0QaKidkYrMxLgfT:APA91bGuz9yT0UdDZm4RyWUrqQL0xpy93XwgHkItSPvVukjLZsH4jNYS9Copy6OdDwoLBhUHWPyPt4yBOnb90jJq_HF--BNAQTMknL7TQCv7u_Kg2nGzu59g_oHF_pvKLqWy0hzYtf8n",
    profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.10166792421066263.jpg",
    licensePlate: "A5525D603",
    carColor: "Toyota Corolla bleu",
    rating: "4.6875",
    createdAt: "2024-02-01T11:52:22.823Z",
    updatedAt: "2024-02-05T22:03:23.373Z"
  },
  {
    id: "d975ac95-2795-4643-9445-f2162132b886",
    name: "CONGO Antoine ",
    sub: "b8cb800c-16dc-406a-ad47-feb8efe86771",
    phone: 76890825,
    carType: "Economique",
    lat: 12.3590114,
    lng: -1.4980528,
    heading: "66.56288146972656",
    deviceToken: "f7lXlN97R5GxuAqn6OPQpz:APA91bHkiqkPvt0o2Zspy4_tV7A3mtA5vDZcCIfRl2HeXrN8o6dFn42jqx1MBtH7CQKTGiSFvQrXEE2b1EyErb-jBH27s_3F_8ROaUg3_g1qbvrB8GJLtc6cFz6y8h7wBj_32UkQxVh6",
    profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.21057324281169687.jpg",
    licensePlate: "3763D203",
    carColor: "Toyota Yaris jaune ",
    rating: "2.5",
    createdAt: "2024-02-05T12:44:55.563Z",
    updatedAt: "2024-02-05T13:35:40.505Z"
  },
  {
    id: "4bc1a903-6f36-4589-888b-d084519bb537",
    name: "DABIRE KOG",
    sub: "8ab4c415-c407-439c-a899-cd82bebc7678",
    phone: 65086317,
    carType: "Confort",
    lat: 12.372572,
    lng: -1.4969664,
    heading: "279.9189758300781",
    deviceToken: "esU-SYPmRiOiP_STh1ECPa:APA91bG6ka7uRyVYc0SFr43OrUBjUKgUhp3JMN8BxrW8VJ9QkhUE4UF-DqBm7ig7NfQOdLrQKWLV1cgfM9Mhccw2JWe_drlZ4golrWzIEBqMDMfK_OvLpikJHT3CrntvKZTPvDMNzvnQ",
    profileImage: "https://djonan-storage101242-staging.s3.us-east-1.amazonaws.com/public/my-image-filename0.3703172531401346.jpg",
    licensePlate: "A5657G103",
    carColor: "Toyota Yaris grise",
    rating: "3.75",
    createdAt: "2024-02-08T11:07:12.090Z",
    updatedAt: "2024-02-08T11:35:09.876Z"
  }
];

export const mockDataInvoices = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    cost: "21.24",
    phone: "(665)121-5454",
    date: "03/12/2022",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    cost: "1.24",
    phone: "(421)314-2288",
    date: "06/15/2021",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    cost: "11.24",
    phone: "(422)982-6739",
    date: "05/02/2022",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    cost: "80.55",
    phone: "(921)425-6742",
    date: "03/21/2022",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    cost: "1.24",
    phone: "(421)445-1189",
    date: "01/12/2021",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    cost: "63.12",
    phone: "(232)545-6483",
    date: "11/02/2022",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    cost: "52.42",
    phone: "(543)124-0123",
    date: "02/11/2022",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    cost: "21.24",
    phone: "(222)444-5555",
    date: "05/02/2021",
  },
];

export const mockTransactions = [
  {
    txId: "01e4dsa",
    user: "johndoe",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2022-04-01",
    cost: "133.45",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "51034szv",
    user: "goodmanave",
    date: "2022-11-05",
    cost: "200.95",
  },
  {
    txId: "0a123sb",
    user: "stevebower",
    date: "2022-11-02",
    cost: "13.55",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "120s51a",
    user: "wootzifer",
    date: "2019-04-15",
    cost: "24.20",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2022-04-01",
    cost: "133.45",
  },
];

export const mockBarData = [
  {
    country: "AD",
    "hot dog": 137,
    "hot dogColor": "hsl(229, 70%, 50%)",
    burger: 96,
    burgerColor: "hsl(296, 70%, 50%)",
    kebab: 72,
    kebabColor: "hsl(97, 70%, 50%)",
    donut: 140,
    donutColor: "hsl(340, 70%, 50%)",
  },
  {
    country: "AE",
    "hot dog": 55,
    "hot dogColor": "hsl(307, 70%, 50%)",
    burger: 28,
    burgerColor: "hsl(111, 70%, 50%)",
    kebab: 58,
    kebabColor: "hsl(273, 70%, 50%)",
    donut: 29,
    donutColor: "hsl(275, 70%, 50%)",
  },
  {
    country: "AF",
    "hot dog": 109,
    "hot dogColor": "hsl(72, 70%, 50%)",
    burger: 23,
    burgerColor: "hsl(96, 70%, 50%)",
    kebab: 34,
    kebabColor: "hsl(106, 70%, 50%)",
    donut: 152,
    donutColor: "hsl(256, 70%, 50%)",
  },
  {
    country: "AG",
    "hot dog": 133,
    "hot dogColor": "hsl(257, 70%, 50%)",
    burger: 52,
    burgerColor: "hsl(326, 70%, 50%)",
    kebab: 43,
    kebabColor: "hsl(110, 70%, 50%)",
    donut: 83,
    donutColor: "hsl(9, 70%, 50%)",
  },
  {
    country: "AI",
    "hot dog": 81,
    "hot dogColor": "hsl(190, 70%, 50%)",
    burger: 80,
    burgerColor: "hsl(325, 70%, 50%)",
    kebab: 112,
    kebabColor: "hsl(54, 70%, 50%)",
    donut: 35,
    donutColor: "hsl(285, 70%, 50%)",
  },
  {
    country: "AL",
    "hot dog": 66,
    "hot dogColor": "hsl(208, 70%, 50%)",
    burger: 111,
    burgerColor: "hsl(334, 70%, 50%)",
    kebab: 167,
    kebabColor: "hsl(182, 70%, 50%)",
    donut: 18,
    donutColor: "hsl(76, 70%, 50%)",
  },
  {
    country: "AM",
    "hot dog": 80,
    "hot dogColor": "hsl(87, 70%, 50%)",
    burger: 47,
    burgerColor: "hsl(141, 70%, 50%)",
    kebab: 158,
    kebabColor: "hsl(224, 70%, 50%)",
    donut: 49,
    donutColor: "hsl(274, 70%, 50%)",
  },
];

export const mockPieData = [
  {
    id: "hack",
    label: "hack",
    value: 239,
    color: "hsl(104, 70%, 50%)",
  },
  {
    id: "make",
    label: "make",
    value: 170,
    color: "hsl(162, 70%, 50%)",
  },
  {
    id: "go",
    label: "go",
    value: 322,
    color: "hsl(291, 70%, 50%)",
  },
  {
    id: "lisp",
    label: "lisp",
    value: 503,
    color: "hsl(229, 70%, 50%)",
  },
  {
    id: "scala",
    label: "scala",
    value: 584,
    color: "hsl(344, 70%, 50%)",
  },
];

export const mockLineData = [
  {
    id: "japan",
    data: [
      {
        x: "plane",
        y: 101,
      },
      {
        x: "helicopter",
        y: 75,
      },
      {
        x: "boat",
        y: 36,
      },
      {
        x: "train",
        y: 216,
      },
      {
        x: "subway",
        y: 35,
      },
      {
        x: "bus",
        y: 236,
      },
      {
        x: "car",
        y: 88,
      },
      {
        x: "moto",
        y: 232,
      },
      {
        x: "bicycle",
        y: 281,
      },
      {
        x: "horse",
        y: 1,
      },
      {
        x: "skateboard",
        y: 35,
      },
      {
        x: "others",
        y: 14,
      },
    ],
  },
  {
    id: "france",
    data: [
      {
        x: "plane",
        y: 212,
      },
      {
        x: "helicopter",
        y: 190,
      },
      {
        x: "boat",
        y: 270,
      },
      {
        x: "train",
        y: 9,
      },
      {
        x: "subway",
        y: 75,
      },
      {
        x: "bus",
        y: 175,
      },
      {
        x: "car",
        y: 33,
      },
      {
        x: "moto",
        y: 189,
      },
      {
        x: "bicycle",
        y: 97,
      },
      {
        x: "horse",
        y: 87,
      },
      {
        x: "skateboard",
        y: 299,
      },
      {
        x: "others",
        y: 251,
      },
    ],
  },
  {
    id: "us",
    data: [
      {
        x: "plane",
        y: 191,
      },
      {
        x: "helicopter",
        y: 136,
      },
      {
        x: "boat",
        y: 91,
      },
      {
        x: "train",
        y: 190,
      },
      {
        x: "subway",
        y: 211,
      },
      {
        x: "bus",
        y: 152,
      },
      {
        x: "car",
        y: 189,
      },
      {
        x: "moto",
        y: 152,
      },
      {
        x: "bicycle",
        y: 8,
      },
      {
        x: "horse",
        y: 197,
      },
      {
        x: "skateboard",
        y: 107,
      },
      {
        x: "others",
        y: 170,
      },
    ],
  },
];

export const mockGeographyData = [
  {
    id: "AFG",
    value: 520600,
  },
  {
    id: "AGO",
    value: 949905,
  },
  {
    id: "ALB",
    value: 329910,
  },
  {
    id: "ARE",
    value: 675484,
  },
  {
    id: "ARG",
    value: 432239,
  },
  {
    id: "ARM",
    value: 288305,
  },
  {
    id: "ATA",
    value: 415648,
  },
  {
    id: "ATF",
    value: 665159,
  },
  {
    id: "AUT",
    value: 798526,
  },
  {
    id: "AZE",
    value: 481678,
  },
  {
    id: "BDI",
    value: 496457,
  },
  {
    id: "BEL",
    value: 252276,
  },
  {
    id: "BEN",
    value: 440315,
  },
  {
    id: "BFA",
    value: 343752,
  },
  {
    id: "BGD",
    value: 920203,
  },
  {
    id: "BGR",
    value: 261196,
  },
  {
    id: "BHS",
    value: 421551,
  },
  {
    id: "BIH",
    value: 974745,
  },
  {
    id: "BLR",
    value: 349288,
  },
  {
    id: "BLZ",
    value: 305983,
  },
  {
    id: "BOL",
    value: 430840,
  },
  {
    id: "BRN",
    value: 345666,
  },
  {
    id: "BTN",
    value: 649678,
  },
  {
    id: "BWA",
    value: 319392,
  },
  {
    id: "CAF",
    value: 722549,
  },
  {
    id: "CAN",
    value: 332843,
  },
  {
    id: "CHE",
    value: 122159,
  },
  {
    id: "CHL",
    value: 811736,
  },
  {
    id: "CHN",
    value: 593604,
  },
  {
    id: "CIV",
    value: 143219,
  },
  {
    id: "CMR",
    value: 630627,
  },
  {
    id: "COG",
    value: 498556,
  },
  {
    id: "COL",
    value: 660527,
  },
  {
    id: "CRI",
    value: 60262,
  },
  {
    id: "CUB",
    value: 177870,
  },
  {
    id: "-99",
    value: 463208,
  },
  {
    id: "CYP",
    value: 945909,
  },
  {
    id: "CZE",
    value: 500109,
  },
  {
    id: "DEU",
    value: 63345,
  },
  {
    id: "DJI",
    value: 634523,
  },
  {
    id: "DNK",
    value: 731068,
  },
  {
    id: "DOM",
    value: 262538,
  },
  {
    id: "DZA",
    value: 760695,
  },
  {
    id: "ECU",
    value: 301263,
  },
  {
    id: "EGY",
    value: 148475,
  },
  {
    id: "ERI",
    value: 939504,
  },
  {
    id: "ESP",
    value: 706050,
  },
  {
    id: "EST",
    value: 977015,
  },
  {
    id: "ETH",
    value: 461734,
  },
  {
    id: "FIN",
    value: 22800,
  },
  {
    id: "FJI",
    value: 18985,
  },
  {
    id: "FLK",
    value: 64986,
  },
  {
    id: "FRA",
    value: 447457,
  },
  {
    id: "GAB",
    value: 669675,
  },
  {
    id: "GBR",
    value: 757120,
  },
  {
    id: "GEO",
    value: 158702,
  },
  {
    id: "GHA",
    value: 893180,
  },
  {
    id: "GIN",
    value: 877288,
  },
  {
    id: "GMB",
    value: 724530,
  },
  {
    id: "GNB",
    value: 387753,
  },
  {
    id: "GNQ",
    value: 706118,
  },
  {
    id: "GRC",
    value: 377796,
  },
  {
    id: "GTM",
    value: 66890,
  },
  {
    id: "GUY",
    value: 719300,
  },
  {
    id: "HND",
    value: 739590,
  },
  {
    id: "HRV",
    value: 929467,
  },
  {
    id: "HTI",
    value: 538961,
  },
  {
    id: "HUN",
    value: 146095,
  },
  {
    id: "IDN",
    value: 490681,
  },
  {
    id: "IND",
    value: 549818,
  },
  {
    id: "IRL",
    value: 630163,
  },
  {
    id: "IRN",
    value: 596921,
  },
  {
    id: "IRQ",
    value: 767023,
  },
  {
    id: "ISL",
    value: 478682,
  },
  {
    id: "ISR",
    value: 963688,
  },
  {
    id: "ITA",
    value: 393089,
  },
  {
    id: "JAM",
    value: 83173,
  },
  {
    id: "JOR",
    value: 52005,
  },
  {
    id: "JPN",
    value: 199174,
  },
  {
    id: "KAZ",
    value: 181424,
  },
  {
    id: "KEN",
    value: 60946,
  },
  {
    id: "KGZ",
    value: 432478,
  },
  {
    id: "KHM",
    value: 254461,
  },
  {
    id: "OSA",
    value: 942447,
  },
  {
    id: "KWT",
    value: 414413,
  },
  {
    id: "LAO",
    value: 448339,
  },
  {
    id: "LBN",
    value: 620090,
  },
  {
    id: "LBR",
    value: 435950,
  },
  {
    id: "LBY",
    value: 75091,
  },
  {
    id: "LKA",
    value: 595124,
  },
  {
    id: "LSO",
    value: 483524,
  },
  {
    id: "LTU",
    value: 867357,
  },
  {
    id: "LUX",
    value: 689172,
  },
  {
    id: "LVA",
    value: 742980,
  },
  {
    id: "MAR",
    value: 236538,
  },
  {
    id: "MDA",
    value: 926836,
  },
  {
    id: "MDG",
    value: 840840,
  },
  {
    id: "MEX",
    value: 353910,
  },
  {
    id: "MKD",
    value: 505842,
  },
  {
    id: "MLI",
    value: 286082,
  },
  {
    id: "MMR",
    value: 915544,
  },
  {
    id: "MNE",
    value: 609500,
  },
  {
    id: "MNG",
    value: 410428,
  },
  {
    id: "MOZ",
    value: 32868,
  },
  {
    id: "MRT",
    value: 375671,
  },
  {
    id: "MWI",
    value: 591935,
  },
  {
    id: "MYS",
    value: 991644,
  },
  {
    id: "NAM",
    value: 701897,
  },
  {
    id: "NCL",
    value: 144098,
  },
  {
    id: "NER",
    value: 312944,
  },
  {
    id: "NGA",
    value: 862877,
  },
  {
    id: "NIC",
    value: 90831,
  },
  {
    id: "NLD",
    value: 281879,
  },
  {
    id: "NOR",
    value: 224537,
  },
  {
    id: "NPL",
    value: 322331,
  },
  {
    id: "NZL",
    value: 86615,
  },
  {
    id: "OMN",
    value: 707881,
  },
  {
    id: "PAK",
    value: 158577,
  },
  {
    id: "PAN",
    value: 738579,
  },
  {
    id: "PER",
    value: 248751,
  },
  {
    id: "PHL",
    value: 557292,
  },
  {
    id: "PNG",
    value: 516874,
  },
  {
    id: "POL",
    value: 682137,
  },
  {
    id: "PRI",
    value: 957399,
  },
  {
    id: "PRT",
    value: 846430,
  },
  {
    id: "PRY",
    value: 720555,
  },
  {
    id: "QAT",
    value: 478726,
  },
  {
    id: "ROU",
    value: 259318,
  },
  {
    id: "RUS",
    value: 268735,
  },
  {
    id: "RWA",
    value: 136781,
  },
  {
    id: "ESH",
    value: 151957,
  },
  {
    id: "SAU",
    value: 111821,
  },
  {
    id: "SDN",
    value: 927112,
  },
  {
    id: "SDS",
    value: 966473,
  },
  {
    id: "SEN",
    value: 158085,
  },
  {
    id: "SLB",
    value: 178389,
  },
  {
    id: "SLE",
    value: 528433,
  },
  {
    id: "SLV",
    value: 353467,
  },
  {
    id: "ABV",
    value: 251,
  },
  {
    id: "SOM",
    value: 445243,
  },
  {
    id: "SRB",
    value: 202402,
  },
  {
    id: "SUR",
    value: 972121,
  },
  {
    id: "SVK",
    value: 319923,
  },
  {
    id: "SVN",
    value: 728766,
  },
  {
    id: "SWZ",
    value: 379669,
  },
  {
    id: "SYR",
    value: 16221,
  },
  {
    id: "TCD",
    value: 101273,
  },
  {
    id: "TGO",
    value: 498411,
  },
  {
    id: "THA",
    value: 506906,
  },
  {
    id: "TJK",
    value: 613093,
  },
  {
    id: "TKM",
    value: 327016,
  },
  {
    id: "TLS",
    value: 607972,
  },
  {
    id: "TTO",
    value: 936365,
  },
  {
    id: "TUN",
    value: 898416,
  },
  {
    id: "TUR",
    value: 237783,
  },
  {
    id: "TWN",
    value: 878213,
  },
  {
    id: "TZA",
    value: 442174,
  },
  {
    id: "UGA",
    value: 720710,
  },
  {
    id: "UKR",
    value: 74172,
  },
  {
    id: "URY",
    value: 753177,
  },
  {
    id: "USA",
    value: 658725,
  },
  {
    id: "UZB",
    value: 550313,
  },
  {
    id: "VEN",
    value: 707492,
  },
  {
    id: "VNM",
    value: 538907,
  },
  {
    id: "VUT",
    value: 650646,
  },
  {
    id: "PSE",
    value: 476078,
  },
  {
    id: "YEM",
    value: 957751,
  },
  {
    id: "ZAF",
    value: 836949,
  },
  {
    id: "ZMB",
    value: 714503,
  },
  {
    id: "ZWE",
    value: 405217,
  },
  {
    id: "KOR",
    value: 171135,
  },
];
