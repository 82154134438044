import { Dialog, DialogContent, DialogTitle, TextField, Button } from "@mui/material";
import { useState } from "react";

const EditDistanceDialog = ({ open, onClose, initialDistance, onSave }) => {
  const [distance, setDistance] = useState(initialDistance);
  console.log("initial distance :",initialDistance)

  const handleChangeDistance = (event) => {
    setDistance(event.target.value);
  };

  const handleSave = () => {
    onSave(distance);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Distance</DialogTitle>
      <DialogContent>
        <TextField
          label="Distance"
          value={distance}
          onChange={handleChangeDistance}
          fullWidth
          type="number"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        />
        <Button variant="contained" onClick={handleSave}>Save</Button>
      </DialogContent>
    </Dialog>
  );
};

export default EditDistanceDialog;
