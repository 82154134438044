import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";


const firebaseConfig = {
    apiKey: "AIzaSyCWAchsu1OpWhGhzDp1HCSCnSwFlTZDCY0",
    authDomain: "djonanfcm-240cb.firebaseapp.com",
    projectId: "djonanfcm-240cb",
    storageBucket: "djonanfcm-240cb.appspot.com",
    messagingSenderId: "980510893250",
    appId: "1:980510893250:web:04f7a53c8e141199ae075b",
    measurementId: "G-ZJBHMTYQFZ"
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
    const permission = await Notification.requestPermission();
    console.log(permission);
    if (permission === 'granted') {
        const token= await getToken(messaging, {
            vapidKey: 
                "BFfSjSyJroTdNPoqThsWwIptq1112uLCzSrhcZ87SnwElMtjwFssyVgmqeGj1_BXXJxgL2Oe-lOSXkRr9dwSLL8"
        });
        console.log('token :', token)
    }   
}