import { Box } from "@mui/material";
import Header from "../../components/Header";
//import Cards from "../../components/cards";
import ContentMain from "./ContentMain";


const Dashboard = () => {
    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Tableau de Bord" subtitle="Bienvenue sur le Tableau de Bord" />
            </Box>
            <Box>
                <ContentMain />
            </Box>   
        </Box>
    )
}
export default Dashboard;