//import { iconsImgs } from "../../utils/images";
import "./Cards.css";
import React, { useState, useEffect } from "react";
import { DataStore } from 'aws-amplify/datastore';
import { DeliveryOrder, CarOrder, OrderLocation, Order } from "../../models";

const Cards = () => {
    const [totalAmount, setTotalAmount] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const deliveryOrders = await DataStore.query(DeliveryOrder, c => c.status.eq("COMPLETED"));
            const carOrders = await DataStore.query(CarOrder, c => c.status.eq("COMPLETED"));
            const orderLocations = await DataStore.query(OrderLocation, c => c.status.eq("COMPLETED"));
            const orders = await DataStore.query(Order, c => c.status.eq("COMPLETED"));
            

            const amount = orderLocations.reduce((acc, orderLocation) => acc + orderLocation.amount, 0) +
                          deliveryOrders.reduce((acc, deliveryOrder) => acc + deliveryOrder.deliveryAmount, 0) +
                          carOrders.reduce((acc, carOrder) => acc + carOrder.FeesDebited, 0) +
                          orders.reduce((acc, order) => acc + order.deliveryFee, 0);

            setTotalAmount(amount);
        };

        fetchData();

        const subscription = DataStore.observe(DeliveryOrder).subscribe(() => fetchData());
        const subscription2 = DataStore.observe(CarOrder).subscribe(() => fetchData());
        const subscription3 = DataStore.observe(OrderLocation).subscribe(() => fetchData());
        const subscription4 = DataStore.observe(Order).subscribe(() => fetchData());

        return () => {
            subscription.unsubscribe();
            subscription2.unsubscribe();
            subscription3.unsubscribe();
            subscription4.unsubscribe();
        };
    }, []);

    return (
        <div className="grid-one-item grid-common grid-c1">
            <div className="grid-c-title">
                <h3 className="grid-c-title-text">BILLAN</h3>
            </div>
            <div className="grid-c1-content">
                <p>Solde</p>
                <div className="lg-value">FCFA {totalAmount}</div>
                <div className="card-wrapper">
                    <span className="card-pin-hidden">**** **** **** </span>
                    <span>1234</span>
                </div>
                <div className="card-logo-wrapper">
                    <div>
                        <p className="text text-silver-v1 expiry-text">Expire</p>
                        <p className="text text-sm text-white">03/24</p>
                    </div>
                    <div className="card-logo">
                        <div className="logo-shape1"></div>
                        <div className="logo-shape2"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cards;
