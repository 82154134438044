import {createContext, useState, useEffect, useContext} from "react"
import { fetchUserAttributes } from 'aws-amplify/auth';
import { DataStore } from 'aws-amplify/datastore';
import { Team } from '../models';


const UserContext = createContext({});

const UserContextProvider = ({ children }) => {
    const [admin, setAdmin] = useState();
    const [user, setUser] = useState();
    const userSub = user?.sub

    

    useEffect(()=> {
        fetchUserAttributes({bypassCache: true}).then(setUser)
    },[])

    useEffect(()=> {
        if(!userSub) {
            return;
        }
        DataStore.query(Team, (t) => t.sub.eq(userSub)).then((team) => setAdmin(team[0]))
    },[userSub])





    return <UserContext.Provider value={{admin}}>
                {children}
            </UserContext.Provider>

};

export default UserContextProvider;

export const useAdminContext = () => useContext(UserContext)