import React, { useRef, useState, useEffect } from 'react';
import {
  Flex,
  Input,
  Box,
} from '@chakra-ui/react';
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from '@react-google-maps/api';
import CircularProgress from '@mui/material/CircularProgress';
import { tokens } from '../../theme';
import { Typography, useTheme, Button, IconButton } from "@mui/material";
import NearMeIcon from '@mui/icons-material/NearMe';
import CloseIcon from '@mui/icons-material/Close';
import { DataStore } from 'aws-amplify/datastore';
import { RideOption } from "../../models";


const center = { lat: 12.37257, lng: -1.49696 };

const CreateCarOrder = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  const [map, setMap] = useState(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState('');
  const [duration, setDuration] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [rideOptions, setRideOptions] = useState([]); 

  const originRef = useRef();
  const destiantionRef = useRef();

  
  

  useEffect(() => {
    const fetchRideOptions = async () => {
      try {
        const rideOptions = await DataStore.query(RideOption);
        console.log("Ride Options:", rideOptions);
        // Mettez à jour votre état local avec les données récupérées
        setRideOptions(rideOptions);
      } catch (error) {
        console.error("Error fetching ride options:", error);
      }
    };
    
    fetchRideOptions();
  }, []);


  const calculateDriveAmount = (distance, multiplier) => {
    const amount = Math.round((distance * multiplier) / 1000);
    return Math.max(amount, 1000); // Assurez-vous que le montant est au moins 1000 Fcfa
  };
 
  

  async function calculateRoute() {
    if (originRef.current.value === '' || destiantionRef.current.value === '') {
      return;
    }
    const directionsService = new window.google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: originRef.current.value,
      destination: destiantionRef.current.value,
      travelMode: window.google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);
    setShowOptions(true);
  }

  function clearRoute() {
    setDirectionsResponse(null);
    setDistance('');
    setDuration('');
    originRef.current.value = '';
    destiantionRef.current.value = '';
  }

  if (!isLoaded) {
    return (
      <Box display='flex' alignItems='center' color={colors.primary[400]}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Flex
      position='relative'
      flexDirection='column'
      alignItems='center'
      h='100vh'
      w='100vw'
    >
      <Box position='absolute' left={0} top={0} h='100%' w='100%'>
        {/* Google Map Box */}
        <GoogleMap
          center={center}
          zoom={15}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          onLoad={(map) => setMap(map)}
        >
          <Marker position={center} />
          {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} />
          )}
        </GoogleMap>
      </Box>
      <Box
        p={4}
        borderRadius='lg'
        m={4}
        bgColor='white'
        shadow='base'
        minW='container.md'
        zIndex='1'
        width='50%'
        alignItems='center'
      >
        <Flex justifyContent='space-between' alignItems='center' width='100%'>
          <Autocomplete>
            <Input  fullWidth type='text' placeholder='Origin' ref={originRef} />
          </Autocomplete>
          <Autocomplete>
            <Input
              fullWidth
              type='text'
              placeholder='Destination'
              ref={destiantionRef}
            />
          </Autocomplete>
          <Flex>
            <Box 
              backgroundColor= {colors.greenAccent[600]}
              borderRadius="4px">
              <Button
                color={colors.greenAccent[200]}
                variant='solid'
                onClick={calculateRoute}
              >
                Calculate Route
              </Button>
            </Box >
            <Box ml={2}
              backgroundColor= {colors.redAccent[400]}
              borderRadius="4px">
              <IconButton
                color={colors.redAccent[400]}
                onClick={clearRoute}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Flex>
        </Flex>
        <Flex justifyContent='space-between' alignItems='center' mt={4}>
          <Typography color={colors.primary[900]}>Distance: {distance} </Typography>
          <Typography color={colors.primary[900]}>Duration: {duration} </Typography>
          <Box 
              backgroundColor= {colors.redAccent[400]}
              borderRadius="4px">
          <IconButton
            backgroundColor= {colors.redAccent[400]}
            onClick={() => {
              map.panTo(center);
              map.setZoom(15);
            }}
          >
            <NearMeIcon />
          </IconButton>
              </Box>
         
        </Flex>
        {showOptions && (
          <Box>
           {rideOptions.map(option => (
          <Button key={option.id}>
            {option.title} - {calculateDriveAmount(distance, option.multiplier)} Fcfa
          </Button>
        ))}
          </Box> // Ajoutez ici le code pour afficher les options de trajet avec les coûts
        )}
      </Box>
    </Flex>
  );
};

export default CreateCarOrder;
