import { useState, useEffect } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import EditIcon from "@mui/icons-material/Edit";

import { DataStore } from 'aws-amplify/datastore';
import { MaxDistance, RideOption, LocationOption } from '../../models';
import EditRideDistance from "./EditRideDistance";
import EditRideOptDialog from "./EditRideOptDialog";
import EditLocationOptDialog from "./EditLocationOptDialog";




const ConfigDrive = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [mockDataMaxDistance, setMockDataMaxDistance] = useState([]);
    const [mockDataLocationRideOption, setMockDataLocationRideOption] = useState([]);
    const [mockDataLocationOption, setMockDataLocationOption] = useState([]);

    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(null); // ID de la ligne sélectionnée pour l'édition
    const [selectedRowDistance, setSelectedRowDistance] = useState("");
    const [openRideDialog, setOpenRideDialog] = useState(false);
    const [selectedRideData, setSelectedRideData] = useState(null);
    const [openLocationDialog, setOpenLocationDialog] = useState(false);
    const [selectedLocationData, setSelectedLocationData] = useState(null);

    useEffect(()=> {
        DataStore.query(MaxDistance).then(setMockDataMaxDistance)
    },[]);

    useEffect(()=> {
        DataStore.query(RideOption).then(setMockDataLocationRideOption)
    },[]);

    useEffect(()=> {
        DataStore.query(LocationOption).then(setMockDataLocationOption)
    },[]);

    const handleOpenEditDialog = (id, distance) => {
        setSelectedRowId(id);
        setSelectedRowDistance(distance);
        setOpenEditDialog(true);
    };

    const handleOpenRideDialog = (rowData) => {
        if (rowData) { // Vérifiez si rowData est défini
            setSelectedRideData(rowData);
            setOpenRideDialog(true);
        } else {
            console.error("Row data is undefined.");
        }
    };

    const handleOpenLocationDialog = (rowData) => {
        if (rowData) { // Vérifiez si rowData est défini
            setSelectedLocationData(rowData);
            setOpenLocationDialog(true);
        } else {
            console.error("Row data is undefined.");
        }
    };

    const updateDataInDatabase = async (id, newData) => {
        try {
            // Récupérer l'élément à mettre à jour dans la base de données
            const originalItem = await DataStore.query(RideOption, id);
    
            // Assurez-vous que multiplier est un nombre
            newData.multiplier = parseFloat(newData.multiplier);
    
            // Vérifiez si la conversion est réussie
            if (!isNaN(newData.multiplier)) {
                // Créer une copie de l'élément avec les nouvelles données
                const updatedItem = RideOption.copyOf(originalItem, updated => {
                    // Mettre à jour chaque champ avec les nouvelles données
                    Object.keys(newData).forEach(key => {
                        updated[key] = newData[key];
                    });
                });
    
                // Enregistrer l'élément mis à jour dans la base de données
                await DataStore.save(updatedItem);
    
                console.log("Data updated successfully:", id, newData);
            } else {
                throw new Error("Multiplier is not a valid number.");
            }
        } catch (error) {
            throw new Error("Error updating data in database: " + error.message);
        }
    };

    const updateDataLocationInDatabase = async (id, newData) => {
        try {
            // Récupérer l'élément à mettre à jour dans la base de données
            const originalItem = await DataStore.query(LocationOption, id);
    
            // Assurez-vous que multiplier est un nombre
            newData.price = parseFloat(newData.price);
    
            // Vérifiez si la conversion est réussie
            if (!isNaN(newData.price)) {
                // Créer une copie de l'élément avec les nouvelles données
                const updatedItem = LocationOption.copyOf(originalItem, updated => {
                    // Mettre à jour chaque champ avec les nouvelles données
                    Object.keys(newData).forEach(key => {
                        updated[key] = newData[key];
                    });
                });
    
                // Enregistrer l'élément mis à jour dans la base de données
                await DataStore.save(updatedItem);
    
                console.log("Data updated successfully:", id, newData);
            } else {
                throw new Error("Multiplier is not a valid number.");
            }
        } catch (error) {
            throw new Error("Error updating data in database: " + error.message);
        }
    };


    const updateDistanceInDatabase = async (id, newDistance) => {
        try {
            // Convertir la nouvelle distance en nombre
            const distanceNumber = parseFloat(newDistance);
            
            // Vérifier si la conversion est réussie
            if (!isNaN(distanceNumber)) {
                const originalItem = await DataStore.query(MaxDistance, id);
                const updatedItem = MaxDistance.copyOf(originalItem, updated => {
                    updated.distance = distanceNumber;
                });
                await DataStore.save(updatedItem);
                console.log("Distance updated successfully:", id, distanceNumber);
            } else {
                throw new Error("New distance is not a valid number.");
            }
        } catch (error) {
            throw new Error("Error updating distance in database: " + error.message);
        }
    };


    const handleSaveDistance = async (id, distance) => {
        try {
            await updateDistanceInDatabase(id, distance);
            console.log("Distance updated successfully:", id, distance);
            const updatedData = await DataStore.query(MaxDistance);
            setMockDataMaxDistance(updatedData);
        } catch (error) {
            console.error("Error updating distance:", error);
        } finally {
            setOpenEditDialog(false);
        }
    };

    const handleSaveRideData = async (newData) => {
        try {
            if (newData) {
                await updateDataInDatabase(newData.id, newData);
                console.log("Ride data updated successfully:", newData.id, newData);
    
                // Rafraîchissez les données à partir de la source pour refléter les modifications
                const updatedData = await DataStore.query(RideOption);
                setMockDataLocationRideOption(updatedData);
            } else {
                throw new Error("New data is undefined.");
            }
        } catch (error) {
            console.error("Error updating ride data:", error);
        } finally {
            setOpenRideDialog(false);
        }
    };

    const handleSaveLocationData = async (newData) => {
        try {
            if (newData) {
                await updateDataLocationInDatabase(newData.id, newData);
                console.log("Ride data updated successfully:", newData.id, newData);
    
                // Rafraîchissez les données à partir de la source pour refléter les modifications
                const updatedData = await DataStore.query(LocationOption);
                setMockDataLocationOption(updatedData);
            } else {
                throw new Error("New data is undefined.");
            }
        } catch (error) {
            console.error("Error updating ride data:", error);
        } finally {
            setOpenRideDialog(false);
        }
    };

    const columns = [
        { field: "id", 
        headerName: "ID",
        flex: 1,
        renderCell:(params) => (
            <Box component="h6" style={{ fontSize: "1rem" }}>
                {params.value}
            </Box>
        )
        },
        {
            field: "distance",
            headerName: "distance",
            flex: 1,
            cellClassName: "name-column--cell",
            renderCell: (params) => (
                <Box component="h6" style={{ fontSize: "1rem" }}>
                    {params.value}
                </Box>
            ),
        },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            width: 180,
            renderCell: (params) => (
                <Box display="flex" justifyContent="space-around">
                    <Button 
                        startIcon={<EditIcon />} 
                        variant="outlined" 
                        color="secondary"
                        onClick={() => handleOpenEditDialog(params.row.id, params.row.distance)} // Ajoutez params.row.id ici
                    >
                        Edit
                    </Button>
              </Box>
            ),
        },
    ];

    const columnsRide = [
        { field: "id", 
        headerName: "ID",
        flex: 1,
        renderCell:(params) => (
            <Box component="h6" style={{ fontSize: "1rem" }}>
                {params.value}
            </Box>
        )
        },
        {
            field: "title",
            headerName: "Type",
            flex: 1,
            cellClassName: "name-column--cell",
            renderCell: (params) => (
                <Box component="h6" style={{ fontSize: "1rem" }}>
                    {params.value}
                </Box>
            ),
        },
        {
            field: "image",
            headerName: "image",
            flex: 1,
            cellClassName: "name-column--cell",
            renderCell: (params) => (
                <Box component="h6" style={{ fontSize: "1rem" }}>
                    {params.value}
                </Box>
            ),
        },
        {
            field: "multiplier",
            headerName: "Multiplicateur",
            flex: 1,
            cellClassName: "name-column--cell",
            renderCell: (params) => (
                <Box component="h6" style={{ fontSize: "1rem" }}>
                    {params.value}
                </Box>
            ),
        },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            width: 180,
            renderCell: (params) => (
                <Box display="flex" justifyContent="space-around">
                    <Button 
                        startIcon={<EditIcon />} 
                        variant="outlined" 
                        color="secondary"
                        onClick={() => handleOpenRideDialog(params.row)} // Ouvre le dialogue d'édition avec les données de la ligne
                    >
                        Edit
                    </Button>
                </Box>
            ),
        },
    ];

    const columnsLocation = [
        { field: "id", 
        headerName: "ID",
        flex: 1,
        renderCell:(params) => (
            <Box component="h6" style={{ fontSize: "1rem" }}>
                {params.value}
            </Box>
        )
        },
        {
            field: "title",
            headerName: "Type",
            flex: 1,
            cellClassName: "name-column--cell",
            renderCell: (params) => (
                <Box component="h6" style={{ fontSize: "1rem" }}>
                    {params.value}
                </Box>
            ),
        },
        {
            field: "subtitle",
            headerName: "Temps",
            flex: 1,
            cellClassName: "name-column--cell",
            renderCell: (params) => (
                <Box component="h6" style={{ fontSize: "1rem" }}>
                    {params.value}
                </Box>
            ),
        },
        {
            field: "image",
            headerName: "image",
            flex: 1,
            cellClassName: "name-column--cell",
            renderCell: (params) => (
                <Box component="h6" style={{ fontSize: "1rem" }}>
                    {params.value}
                </Box>
            ),
        },
        {
            field: "price",
            headerName: "Coût",
            flex: 1,
            cellClassName: "name-column--cell",
            renderCell: (params) => (
                <Box component="h6" style={{ fontSize: "1rem" }}>
                    {params.value}
                </Box>
            ),
        },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            width: 180,
            renderCell: (params) => (
                <Box display="flex" justifyContent="space-around">
                    <Button 
                        startIcon={<EditIcon />} 
                        variant="outlined" 
                        color="secondary"
                        onClick={() => handleOpenLocationDialog(params.row)} // Ouvre le dialogue d'édition avec les données de la ligne
                    >
                        Edit
                    </Button>
                </Box>
            ),
        },
    ];
    

    

    return(
        <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
           <Header title="Configuration Drive & Location" subtitle="Bienvenue sur la Configuration des Drive & Location" />
        </Box>
        <Box
           m="40px 0 0 0"
           height="20vh"
           sx={{
               "& .MuiDataGrid-root": {
                   border: "none",
                   margin: "0 20px", // Ajout de la marge horizontale
               },
               "& .MuiDataGrid-cell": {
                   borderBottom: "none",
               },
               "& .name-column--cell": {
                   color: colors.greenAccent[300],
               },
               "& .MuiDataGrid-columnHeaders": {
                   backgroundColor: colors.blueAccent[700],
                   borderBottom: "none",
               },
               "& .MuiDataGrid-virtualScroller": {
                   backgroundColor: colors.primary[400],
               },
               "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                   backgroundColor: colors.blueAccent[700],
               },
               "& .MuiCheckbox-root": {
                   color: `${colors.greenAccent[200]} !important`,
               },
           }}
       >
           <Typography variant="h5" marginBottom="20px">Configuration distance de notification </Typography> {/* Ajout de la marge inférieure */}
           <DataGrid  rows={mockDataMaxDistance} columns={columns} hideFooter/>
       </Box>

       <Box
       
           m="40px 0 0 0"
           height="25vh"
           marginTop="75px"
           sx={{
               "& .MuiDataGrid-root": {
                   border: "none",
                   margin: "0 20px", // Ajout de la marge horizontale
               },
               "& .MuiDataGrid-cell": {
                   borderBottom: "none",
               },
               "& .name-column--cell": {
                   color: colors.greenAccent[300],
               },
               "& .MuiDataGrid-columnHeaders": {
                   backgroundColor: colors.blueAccent[700],
                   borderBottom: "none",
               },
               "& .MuiDataGrid-virtualScroller": {
                   backgroundColor: colors.primary[400],
               },
               "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                   backgroundColor: colors.blueAccent[700],
               },
               "& .MuiCheckbox-root": {
                   color: `${colors.greenAccent[200]} !important`,
               },
           }}
       >
           <Typography variant="h5" marginBottom="20px" marginTop="20px">Configuration Type de drive</Typography> {/* Ajout de la marge inférieure */}
           <DataGrid  rows={mockDataLocationRideOption} columns={columnsRide} hideFooter/>
       </Box>

       <Box
       
       m="40px 0 0 0"
       height="25vh"
       marginTop="75px"
       sx={{
           "& .MuiDataGrid-root": {
               border: "none",
               margin: "0 20px", // Ajout de la marge horizontale
           },
           "& .MuiDataGrid-cell": {
               borderBottom: "none",
           },
           "& .name-column--cell": {
               color: colors.greenAccent[300],
           },
           "& .MuiDataGrid-columnHeaders": {
               backgroundColor: colors.blueAccent[700],
               borderBottom: "none",
           },
           "& .MuiDataGrid-virtualScroller": {
               backgroundColor: colors.primary[400],
           },
           "& .MuiDataGrid-footerContainer": {
               borderTop: "none",
               backgroundColor: colors.blueAccent[700],
           },
           "& .MuiCheckbox-root": {
               color: `${colors.greenAccent[200]} !important`,
           },
       }}
   >
       <Typography variant="h5" marginBottom="20px" marginTop="20px">Configuration Type de location </Typography> {/* Ajout de la marge inférieure */}
       <DataGrid  rows={mockDataLocationOption} columns={columnsLocation} hideFooter/>
   </Box>
        <EditRideDistance 
            open={openEditDialog}
            onClose={() => setOpenEditDialog(false)}
            onSave={(distance) => handleSaveDistance(selectedRowId, distance)} // Ajoutez selectedRowId ici
            initialDistance={selectedRowDistance}
        />

        <EditRideOptDialog 
            open={openRideDialog}
            onClose={() => setOpenRideDialog(false)}
            initialData={selectedRideData}
            onSave={handleSaveRideData} // Remplacez par votre fonction de sauvegarde des données
        />

        <EditLocationOptDialog 
            open={openLocationDialog}
            onClose={() => setOpenLocationDialog(false)}
            initialData={selectedLocationData}
            onSave={handleSaveLocationData} // Remplacez par votre fonction de sauvegarde des données
        />         
       
    </Box>
    )

}

export default ConfigDrive