// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const TeamAccess = {
  "ADMIN": "ADMIN",
  "MANAGER": "MANAGER",
  "USERACCESS": "USERACCESS"
};

const DeliveryStatus = {
  "NEW": "NEW",
  "NEWVALUE": "NEWVALUE",
  "ACCEPTED": "ACCEPTED",
  "WAITTING_FOR_PICKUP": "WAITTING_FOR_PICKUP",
  "PICK_UP": "PICK_UP",
  "COMPLETED": "COMPLETED",
  "CANCELED": "CANCELED"
};

const TransportationModes = {
  "DRIVING": "DRIVING",
  "BYCICLING": "BYCICLING"
};

const { Team, DelivMaxDistance, MaxDistance, DeliveryOption, OrderLocation, LocationOption, Coupons, RideOption, Favorites, Wallet, DeliveryOrder, CarOrder, Driver, Courier, OrderItem, Order, Category, Dish, Featured, Restaurant, User } = initSchema(schema);

export {
  Team,
  DelivMaxDistance,
  MaxDistance,
  DeliveryOption,
  OrderLocation,
  LocationOption,
  Coupons,
  RideOption,
  Favorites,
  Wallet,
  DeliveryOrder,
  CarOrder,
  Driver,
  Courier,
  OrderItem,
  Order,
  Category,
  Dish,
  Featured,
  Restaurant,
  User,
  TeamAccess,
  DeliveryStatus,
  TransportationModes
};