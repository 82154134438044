import React, { useState } from 'react';
import { Box, Button, TextField, Typography} from '@mui/material';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header';

const ConfirmationScreen = () => {
  const { username } = useParams();
  const [confirmationCode, setConfirmationCode] = useState('');
  console.log(username)

  const handleChange = (e) => {
    setConfirmationCode(e.target.value);
  };

  const handleConfirmation = () => {
    // Ajoutez ici la logique pour confirmer le code de confirmation
    console.log(username, confirmationCode);
  };

  return (
    <Box m="20px">
         <Header title="Creer Membre" subtitle="Confirmation Compte Membre" />
      <Typography variant="h5" gutterBottom>
        Confirmation pour {username}
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        label="Code de confirmation"
        value={confirmationCode}
        onChange={handleChange}
        margin="normal"
      />
      <Typography variant="h6" style={{ marginTop: '10px' }}>
        Renvoyer Code de confirmation?
      </Typography>
      <Button
        onClick={handleConfirmation}
        variant="contained"
        color="primary"
        style={{ marginTop: '20px' }}
      >
        Confirmer
      </Button>
    </Box>
  );
};

export default ConfirmationScreen;
