import { useState, useEffect, } from "react";
import { Box, Typography, useTheme, IconButton } from "@mui/material";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";

import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import AddIcon from "@mui/icons-material/Add";
import { Link } from 'react-router-dom';
import { DataStore } from 'aws-amplify/datastore';
import { Team } from '../../models';



const Teams = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [mockDataTeam, setMockDataTeam] = useState([]);

    useEffect(()=> {
      DataStore.query(Team).then(setMockDataTeam)
    },[]);

    


    const columns = [
      { field: "id", headerName: "ID" },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        cellClassName: "name-column--cell",
      },
      {
        field: "address",
        headerName: "Adresse",
        type: "number",
        headerAlign: "left",
        align: "left",
      },
      {
        field: "poste",
        headerName: "Poste",
        flex: 1,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
      },
      {
        field: "accessLevel",
        headerName: "Access Level",
        flex: 1,
        renderCell: ({ row: { accessLevel } }) => {
          return (
            <Box
              width="60%"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              backgroundColor={
                accessLevel === "ADMIN"
                  ? colors.greenAccent[600]
                  : accessLevel === "MANAGER"
                  ? colors.greenAccent[700]
                  : colors.greenAccent[700]
              }
              borderRadius="4px"
            >
              {accessLevel === "ADMIN" && <AdminPanelSettingsOutlinedIcon />}
              {accessLevel === "MANAGER" && <SecurityOutlinedIcon />}
              {accessLevel === "USERACCESS" && <LockOpenOutlinedIcon />}
              <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                {accessLevel}
              </Typography>
            </Box>
          );
        },
      },
    ];

    return (
        <Box  m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Personnel" subtitle="Bienvenue sur la gestion du personnel "  />
                <Link to="/SignUp" style={{ textDecoration: "none" }}>
                <Box 
                backgroundColor= {colors.greenAccent[600]}  
                p="5px"
                display="flex"
                borderRadius="4px">
                  <IconButton >
                    <AddIcon />
                    <Typography color={colors.grey[100]}>Ajouter Membre</Typography>
                  </IconButton>
                </Box>
                </Link>
            </Box>
           
            <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
              }}
            >
              <DataGrid checkboxSelection rows={mockDataTeam} columns={columns} />
            </Box>
        </Box>
    )
}
export default Teams;