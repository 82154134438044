import React, { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import Header from "../../components/Header";
import { Formik } from 'formik';
import * as yup from "yup";


const SignUp = () => {
    
 

  
    const handleFormSubmit = (values) => {
        // Naviguer vers ConfirmationScreen en passant les informations de l'utilisateur
        // Utiliser le composant Link pour naviguer avec les données nécessaires
        console.log(values);
        // Construct the link with parameters
        const link = `/confirmation/${values.username}`;
        // Navigate to ConfirmationScreen with user data
        window.location.href = link;
    };

  

  return (
    <Box m="20px">
        <Header title="Creer Membre" subtitle="Creer Compte Membre" />
        <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >{({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
      <form onSubmit={handleSubmit}>
        
        <TextField
          fullWidth
          variant="outlined"
          label="Nom d'utilisateur"
          name="username"
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!touched.username && !!errors.username}
          helperText={touched.username && errors.username}
          margin="normal"
        />
        <TextField
          fullWidth
          variant="outlined"
          label="Mot de passe"
          type="password"
          name="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!touched.password && !!errors.password}
          helperText={touched.password && errors.password}
          margin="normal"
        />
        <TextField
          fullWidth
          variant="outlined"
          label="Confirmer le mot de passe"
          type="password"
          name="confirmPassword"
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!touched.confirmPassword && !!errors.confirmPassword}
          helperText={touched.confirmPassword && errors.confirmPassword}
          margin="normal"
        />
        
            <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
            S'inscrire
            </Button>
        
      </form>
      )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
    username: yup.string().required("required"),
    password: yup.string().required("required"),
    confirmPassword: yup.string().required("required"),

   
  
  });
  const initialValues = {
    username: "",
    password: "",
    confirmPassword: "",

  };

export default SignUp;
