import { Box } from "@mui/material";

import MapBox from "../../components/MapBox";


const Map = () => {

    return (
        <Box >
            <MapBox />
        </Box>
    );
};

export default Map;