import React, { useState, useEffect } from 'react';
import { useJsApiLoader, GoogleMap, Marker, DirectionsRenderer } from '@react-google-maps/api';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

const DriveMapScreen = ({ deliveryOrder, courier }) => {
    const originDelivery = { lat: deliveryOrder.originlatitude, lng: deliveryOrder.originlongitude }; // Position d'origine
    const destinationDelivery = { lat: deliveryOrder.destinationlatitude, lng: deliveryOrder.destinationlongitude }; // Destination
    const courierPosition = courier ? { lat: courier.lat, lng: courier.lng } : null; // Position du livreur s'il est disponible
    const [directionsResponse, setDirectionsResponse] = useState(null);

    

    useEffect(() => {
        const calculateRoute = async () => {
            const directionsService = new window.google.maps.DirectionsService();
            const results = await directionsService.route({
                origin: originDelivery,
                destination: destinationDelivery,
                travelMode: window.google.maps.TravelMode.DRIVING,
            });
            setDirectionsResponse(results);
        };
        calculateRoute();
    }); // Exécute une seule fois lors du montage seule fois lors du montage
   
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    if (!isLoaded) {
        return (
            <Box display='flex' alignItems='center' color='white'>
                <CircularProgress />
            </Box>
        );
    }

    // Options pour la carte Google
    const mapOptions = {
        zoom: 25,
        center: origin // Centrer la carte sur l'origine de la livraison
    };

    return (
        <div style={{ height: '400px', width: '100%' }}>
            <GoogleMap
                mapContainerStyle={{ height: '100%', width: '100%' }}
                zoom={mapOptions.zoom}
                center={mapOptions.center}
            >
                <Marker position={originDelivery} title="Origine de la livraison" />
                <Marker position={destinationDelivery} title="Destination de la livraison" />
                {courierPosition && <Marker position={courierPosition} title="Position du livreur" />}
               {/* Afficher la direction entre l'origine et la destination */}
               {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
            </GoogleMap>
        </div>
    );
};

export default DriveMapScreen;
