import React, { useState, useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import { tokens } from '../theme';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import CircularProgress from '@mui/material/CircularProgress';
import { DataStore } from 'aws-amplify/datastore';
import { Driver, Courier } from "../models";

const center = { lat: 12.37257, lng: -1.49696 };

const MapBox = () => {
    const [drivers, setDrivers] = useState([]);
    const [couriers, setCouriers] = useState([]);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        const fetchDrivers = async () => {
            try {
                const driverData = await DataStore.query(Driver);
                setDrivers(driverData);
            } catch (error) {
                console.error('Error fetching drivers', error);
            }
        };

        const fetchCouriers = async () => {
            try {
                const courierData = await DataStore.query(Courier);
                setCouriers(courierData);
            } catch (error) {
                console.error('Error fetching couriers', error);
            }
        };

        fetchDrivers();
        fetchCouriers();

        const subscriptionDriver = DataStore.observe(Driver).subscribe(() => fetchDrivers());
        const subscriptionCourier = DataStore.observe(Courier).subscribe(() => fetchCouriers());

        return () => {
            subscriptionDriver.unsubscribe();
            subscriptionCourier.unsubscribe();
        };
    }, []);
 
 
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    if (!isLoaded) {
        return (
            <Box display='flex' alignItems='center' color={colors.primary[400]}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box position='absolute' top={0} left={0} height='100vh' width='100vw'>
            <GoogleMap
                center={center}
                zoom={16}
                mapContainerStyle={{ width: '100%', height: '100%' }}
                options={{
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                }}
            >

                <Marker position={center}/>
                {/* Marqueurs pour les livreurs */}
                {couriers.map((courier) => (
                    <Marker
                        key={courier.id}
                        position={{ lat: courier.lat, lng: courier.lng }}
                        icon={{
                            url: "https://djonan-storage101242-staging.s3.amazonaws.com/public/livreur.png",
                            scaledSize: new window.google.maps.Size(40, 60), // Taille de l'icône
                            rotation: courier.heading,
                        }}
                        title={courier.name}
                    />
                ))}

                {/* Marqueurs pour les chauffeurs */}
                {drivers.map((driver) => (
                    <Marker
                        key={driver.id}
                        position={{ lat: driver.lat, lng: driver.lng }}
                        // Utiliser une image différente en fonction du type de voiture
                        icon={{
                            url: driver.carType === "Confort" ? "https://djonan-storage101242-staging.s3.amazonaws.com/public/confort.png" : "https://djonan-storage101242-staging.s3.amazonaws.com/public/bestEco.png",
                            scaledSize: new window.google.maps.Size(40, 60), // Taille de l'icône
                            rotation: driver.heading,
                        }}
                        title={driver.name}
                    />
                ))}

                {/* Afficher l'infobulle avec le nom du livreur ou du chauffeur */}
                
            </GoogleMap>
        </Box>
    );
};

export default MapBox;
