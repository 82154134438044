import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, Card, CardContent, CardMedia, Box, MenuItem, Menu } from "@mui/material";
//import { DataGrid } from "@mui/x-data-grid";
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import TodayIcon from '@mui/icons-material/Today';
//import TokenIcon from '@mui/icons-material/Token';
//import HowToRegIcon from '@mui/icons-material/HowToReg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState } from 'react';

const Dialogue = ({ open, handleClose, user, carOrders }) => {
    const userCarOrders = user ? carOrders.filter(order => order.userID === user.sub) : [];
    const [activityAnchorEl, setActivityAnchorEl] = useState(null);
    const [selectedActivity, setSelectedActivity] = useState(null);

    const handleActivityClick = (event) => {
        setActivityAnchorEl(event.currentTarget);
    };

    const handleActivityClose = () => {
        setActivityAnchorEl(null);
    };

    const handleActivitySelect = (activity) => {
        setSelectedActivity(activity);
        handleActivityClose();
        // Handle activity selection logic here
    };

    //const getOrderCountByType = () => {
        //const orderCountByType = userCarOrders.reduce((acc, order) => {
            //acc[order.type] = (acc[order.type] || 0) + 1;
            //return acc;
        //}, {});
        //return Object.entries(orderCountByType).map(([type, count], index) => ({ id: index, type, count }));
    //};

    //const columns = [
        //{ field: 'type', headerName: 'Type', flex: 1 },
        //{ field: 'count', headerName: 'Count', flex: 1 },
    //];

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>Informations de l'utilisateur</DialogTitle>
            <DialogContent dividers>
                {user && (
                    <Card>
                        <CardMedia
                            component="img"
                            image={user.profileImage || "../../assets/pngwing.com.png"}
                            title={user.name}
                            sx={{ width: 100, height: 100, margin: 'auto', borderRadius: 20 }}
                        />
                        <CardContent>
                            <Typography variant="h3" align="center" gutterBottom>
                                {user.name}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <PlaceIcon sx={{ marginRight: 1 }} />
                                <Typography variant="h5">{user.adress}</Typography>
                            </Box>
                            {/*<Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <HowToRegIcon sx={{ marginRight: 1 }} />
                                <Typography variant="h5">{user.sub}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <TokenIcon sx={{ marginRight: 1 }} />
                                <Typography variant="h5">{user.deviceToken}</Typography>
                            </Box>*/}
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <PhoneIcon sx={{ marginRight: 1 }} />
                                <Typography variant="h5">{user.phone}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <TodayIcon sx={{ marginRight: 1 }} />
                                <Typography variant="h5">{new Date(user.createdAt).toLocaleDateString()}</Typography>
                            </Box>
                        </CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 16px' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<MoreVertIcon />}
                                endIcon={<ArrowDropDownIcon />}
                                onClick={handleActivityClick}
                                size="large" // Bouton de taille plus grande
                            >
                                Voir activité
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<PhoneIcon />}
                                size="large" // Bouton de taille plus grande
                            >
                                Envoyer une notification
                            </Button>
                        </Box>
                    </Card>
                )}
                {/*<Typography variant="h6" sx={{ mt: 2 }}>Commandes:</Typography>
                <Box sx={{ height: 300, width: '100%', mt: 2 }}>
                    <DataGrid rows={getOrderCountByType()} columns={columns} pageSize={5} />
                </Box>*/}
                <Menu
                    anchorEl={activityAnchorEl}
                    open={Boolean(activityAnchorEl)}
                    onClose={handleActivityClose}
                >
                    <MenuItem onClick={() => handleActivitySelect('Commande de repas')}>Commande de repas</MenuItem>
                    <MenuItem onClick={() => handleActivitySelect('Commande de drive')}>Commande de drive</MenuItem>
                    <MenuItem onClick={() => handleActivitySelect('Commande de livraison')}>Commande de livraison</MenuItem>
                </Menu>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Fermer</Button>
            </DialogActions>
        </Dialog>
    );
};

export default Dialogue;
