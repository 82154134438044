import { Box, Button } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { DataStore } from 'aws-amplify/datastore';
import { Coupons } from "../../models";

const CreateCouponForm = ({ onCreate }) => {
  const { control, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    // Call onCreate prop with the form data
    onCreate(data);
  };

  const checkCouponExists = async (code) => {
    try {
      const existingCoupon = await DataStore.query(Coupons, c => c.code.eq(code));
      return existingCoupon.length > 0;
    } catch (error) {
      console.error("Error checking coupon existence:", error);
      return false;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ marginBottom: 2 }}>
        <Controller
          name="code"
          control={control}
          defaultValue=""
          rules={{
            required: "Code is required",
            pattern: {
                value: /^[A-Z0-9]+$/,
                message: "Code must be uppercase letters only"
            },
            validate: async (value) => {
              // Check if the code already exists
              const couponExists = await checkCouponExists(value);
              return !couponExists || "Code already exists";
            }
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Code"
              variant="outlined"
              error={!!errors.code}
              helperText={errors.code?.message}
            />
          )}
        />
      </Box>
      <Box sx={{ marginBottom: 2 }}>
        <Controller
          name="reduction"
          control={control}
          defaultValue=""
          rules={{
            required: "Reduction is required",
            pattern: {
              value: /^\d+(\.\d+)?$/,
              message: "Reduction must be a float number"
            }
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Reduction"
              variant="outlined"
              error={!!errors.reduction}
              helperText={errors.reduction?.message}
            />
          )}
        />
      </Box>
      <Button type="submit" variant="contained" color="primary">Créer</Button>
    </form>
  );
};

export default CreateCouponForm;
