import { Dialog, DialogContent, DialogTitle, TextField, Button, IconButton, InputAdornment } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { useState, useEffect } from "react";

const EditLocationOptDialog = ({ open, onClose, initialData, onSave }) => {
    const [data, setData] = useState(initialData);
    const [editMode, setEditMode] = useState(false);

    // Vérifiez si initialData a changé, puis mettez à jour les données si nécessaire
    useEffect(() => {
        if (initialData) {
            setData(initialData);
        }
    }, [initialData]);

    const handleChange = (field, value) => {
        setData({ ...data, [field]: value });
    };

    const handleEdit = () => {
        setEditMode(true);
    };

    const handleSave = () => {
        onSave(data); // Assurez-vous que 'data' contient les nouvelles données
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Edit Ride</DialogTitle>
            <DialogContent>
                {data && ( // Vérifiez que data est défini avant d'essayer d'accéder à ses propriétés
                    <>
                        <TextField
                            label="Title"
                            value={data.title}
                            onChange={(e) => handleChange('title', e.target.value)}
                            fullWidth
                            disabled={!editMode}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleEdit} edge="end">
                                            <EditIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <TextField
                            label="Durée"
                            value={data.subtitle}
                            onChange={(e) => handleChange('subtitle', e.target.value)}
                            fullWidth
                            disabled={!editMode}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleEdit} edge="end">
                                            <EditIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <TextField
                            label="Image"
                            value={data.image}
                            onChange={(e) => handleChange('image', e.target.value)}
                            fullWidth
                            disabled={!editMode}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleEdit} edge="end">
                                            <EditIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <TextField
                            label="Prix"
                            value={data.price}
                            onChange={(e) => handleChange('price', e.target.value)}
                            fullWidth
                            disabled={!editMode}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleEdit} edge="end">
                                            <EditIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </>
                )}
                {editMode && (
                    <Button variant="contained" onClick={handleSave}>Save</Button>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default EditLocationOptDialog;
