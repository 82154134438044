import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, Card, CardContent, CardMedia, Box, Menu, MenuItem } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PhoneIcon from '@mui/icons-material/Phone';
import TodayIcon from '@mui/icons-material/Today';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState } from 'react'
// Importez les données du portefeuille du conducteur
 // Assurez-vous de spécifier le chemin correct

const DialogueDriver = ({ open, handleClose, driver, carOrders, wallet }) => {
    const driverCarOrders = driver ? carOrders.filter(order => order.driverID === driver.id) : [];



    const [activityAnchorEl, setActivityAnchorEl] = useState(null);
    const [selectedActivity, setSelectedActivity] = useState(null);

    const handleActivityClick = (event) => {
        setActivityAnchorEl(event.currentTarget);
    };

    const handleActivityClose = () => {
        setActivityAnchorEl(null);
    };

    const handleActivitySelect = (activity) => {
        setSelectedActivity(activity);
        handleActivityClose();
        // Handle activity selection logic here
    };

    const getOrderCountByType = () => {
        const orderCountByType = driverCarOrders.reduce((acc, order) => {
            acc[order.type] = (acc[order.type] || 0) + 1;
            return acc;
        }, {});
        return Object.entries(orderCountByType).map(([type, count], index) => ({ id: index, type, count }));
    };

    const columns = [
        { field: 'type', headerName: 'Type', flex: 1 },
        { field: 'count', headerName: 'Count', flex: 1 },
    ];

    // Trouvez le portefeuille correspondant au conducteur actuel
    const driverWallet = driver && wallet ? wallet.find(wallet => wallet.walletDriverId === driver.id) : null;


    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>Informations du chauffeur</DialogTitle>
            <DialogContent dividers>
              {/* Afficher les informations du conducteur */}
                {driver && (
                    <Card sx={{ marginTop: 2 }}>
                        <CardMedia
                            component="img"
                            image={driver.profileImage || "../../assets/pngwing.com.png"}
                            title={driver.name}
                            sx={{ width: 100, height: 100, margin: 'auto', borderRadius: 20 }}
                        />
                        <CardContent>
                            <Typography variant="h3" align="center" gutterBottom>
                                {driver.name}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <PhoneIcon sx={{ marginRight: 1 }} />
                                <Typography variant="h5">{driver.phone}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <DirectionsCarIcon sx={{ marginRight: 1 }} />
                                <Typography variant="h5">{driver.carType}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <ContactEmergencyIcon sx={{ marginRight: 1 }} />
                                <Typography variant="h5">{driver.licensePlate}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <TodayIcon sx={{ marginRight: 1 }} />
                                <Typography variant="h5">{new Date(driver.createdAt).toLocaleDateString()}</Typography>
                            </Box>
                        </CardContent>

                        {/* Boutons */}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 16px', marginTop: 2 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<MoreVertIcon />}
                                endIcon={<ArrowDropDownIcon />}
                                onClick={handleActivityClick}
                                size="large"
                            >
                                Voir activité
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<PhoneIcon />}
                                size="large"
                            >
                                Envoyer une notification
                            </Button>
                        </Box>
                        
                    </Card>
                )}


                {/* Afficher les informations du portefeuille du conducteur */}
                     
                     {driver && wallet && (
                    <Card sx={{ marginTop: 2 }}>
                        <CardContent>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="h5" gutterBottom>
                                    Portefeuille du conducteur
                                </Typography>
                                <Button variant="contained" color="primary" >
                                    Recharger Wallet
                                </Button>
                            </Box>
                            <Typography variant="body1" gutterBottom>
                                Balance: {driverWallet.balance} $
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Dernière mise à jour: {new Date(driverWallet.updatedAt).toLocaleString()}
                            </Typography>
                        </CardContent>
                    </Card>
                )}
                
                <Typography variant="h5" sx={{ mt: 2 }}>Drive:</Typography>
                <Box sx={{ height: 300, width: '100%', mt: 2 }}>
                    <DataGrid rows={getOrderCountByType()} columns={columns} pageSize={5} />
                </Box>
               <Menu
                    anchorEl={activityAnchorEl}
                    open={Boolean(activityAnchorEl)}
                    onClose={handleActivityClose}
                >
                    <MenuItem onClick={() => handleActivitySelect('Commande de repas')}>Commande de drive</MenuItem>
                    <MenuItem onClick={() => handleActivitySelect('Commande de drive')}>Transaction wallet</MenuItem>
                </Menu>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Fermer</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogueDriver;
