import { Box, Button, TextField, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from "yup";

const UserSetting = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleFormSubmit = (values) => {
        console.log(values);
    };

    return(
        <Box  m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Paramettre " subtitle="Bienvenue sur l'espace de réglage de votre compte"  />
            </Box>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>

                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Nom complet"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!touched.name && !!errors.name}
                            helperText={touched.name && errors.name}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="contact"
                            type="text"
                            name="contact"
                            value={values.contact}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!touched.contact && !!errors.contact}
                            helperText={touched.contact && errors.contact}
                            margin="normal"
                        />
                         <TextField
                            fullWidth
                            variant="outlined"
                            label="adresse"
                            name="adresse"
                            value={values.adresse}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!touched.adresse && !!errors.adresse}
                            helperText={touched.adresse && errors.adresse}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                            margin="normal"
                        />

                        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
                            Enregistrer
                        </Button>

                    </form>
                )}
            </Formik>
        </Box>
    )
}

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
    name: yup.string().required("required"),
    adresse: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
    contact: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
    
});

const initialValues = {
    name: "",
    contact: "",
    adresse: "",
    email:"",
};
export default UserSetting;
