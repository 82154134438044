import { useState, useEffect } from "react";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import DialoguePromoCode from "./Dialogue";
import { DataStore } from 'aws-amplify/datastore';
import { Coupons, User } from "../../models";
import CreateCouponDialog from "./CreateCouponDialog";

const ConfigPromo = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [mockDataCoupons, setMockDataCoupons] = useState([]);
    const [mockDataContacts, setMockDataContacts] = useState([]);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);

    useEffect(()=> {
        DataStore.query(Coupons).then(setMockDataCoupons)
    },[])

    useEffect(()=> {
        DataStore.query(User).then(setMockDataContacts)
    },[])

    const handleOpenViewModal = (coupon) => {
        setSelectedCoupon(coupon);
        setOpenViewModal(true);
    };

    const handleCloseViewModal = () => {
        setOpenViewModal(false);
    };

    const onCreateCoupon = async (data) => {
        // Create the new coupon object
        const newCoupon = await DataStore.save(new Coupons({
            code: data.code,
            reduction: parseFloat(data.reduction),
            status: "NEW"
        }));

        // Update the mock data
        setMockDataCoupons([...mockDataCoupons, newCoupon]);

        // Close the dialog
        setOpenCreateDialog(false);
    };

    const handleDeleteCoupon = async (couponId) => {
        try {
          // Supprimer le coupon de la base de données
          await DataStore.delete(Coupons, couponId);
      
          // Mettre à jour les données des coupons affichés dans la grille
          setMockDataCoupons(prevData => prevData.filter(coupon => coupon.id !== couponId));
          
          console.log(`Coupon with ID ${couponId} has been successfully deleted.`);
        } catch (error) {
          console.error("Error deleting coupon:", error);
        }
      };
      

    const columns = [
        { field: "id", headerName: "ID" },
        { field: "code", headerName: "Code" },
        { field: "reduction", headerName: "Reduction" },
        { field: "status", headerName: "status" },
        { field: "createdAt", headerName: "Date" },
        {
            field: "userID",
            headerName: "User",
            flex: 1,
            valueGetter: (params) => {
                const user = mockDataContacts.find(user => user.sub === params.value);
                return user ? user.name : '';
            }
        },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            flex: 1,
            renderCell: (params) => (
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box paddingRight={2} >
                        <Button startIcon={<VisibilityIcon />} variant="outlined" color="primary" onClick={() => handleOpenViewModal(params.row)}>
                            View
                        </Button>
                    </Box>
                    <Box>
                        <Button startIcon={<DeleteIcon />} variant="outlined" color="error" onClick={() => handleDeleteCoupon(params.row.id)}>
                        Supprimer
                        </Button>
                    </Box>
                </Box>
            ),
        },
    ];

    return(
        <Box m="20px">
             <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Configuration Code Promo" subtitle="Bienvenue sur la Configuration des Codes Promo" />
                <IconButton onClick={() => setOpenCreateDialog(true)}>
                  <AddIcon />
                  <Typography color={colors.grey[100]}>Créer Coupons</Typography>
                </IconButton>
             </Box>
             <Box
                m="15px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                <DataGrid  rows={mockDataCoupons} columns={columns} />
            </Box>
            <CreateCouponDialog
              open={openCreateDialog}
              onClose={() => setOpenCreateDialog(false)}
              onCreate={onCreateCoupon}
            />
            <DialoguePromoCode 
                open={openViewModal} 
                handleClose={handleCloseViewModal}
                codePromo={selectedCoupon}  
                mockDataContacts={mockDataContacts} 
            />
        </Box>
    )

}

export default ConfigPromo;
