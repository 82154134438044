import React from 'react';
import { Box, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import CircularProgress from '@mui/material/CircularProgress';

 
const center = { lat: 12.37257, lng: -1.49696 };
  
  const CreateDeliveryOrder = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
 
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    if (!isLoaded) {
        return (
            <Box display='flex' alignItems='center' color={colors.primary[400]}>
                <CircularProgress />
            </Box>
        );
    }

    return (
     <Box position='absolute' top={0} left={0} height='100vh' width='100vw'>
        <GoogleMap
                center={center}
                zoom={16}
                mapContainerStyle={{ width: '100%', height: '100%' }}
                options={{
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                }}
            >
                <Marker position={center}/>
            </GoogleMap>
     </Box>
                
    )
  }
  
  export default CreateDeliveryOrder