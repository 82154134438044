import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Grid, Card, CardContent, CardMedia } from "@mui/material";
import TodayIcon from '@mui/icons-material/Today';
import PhoneIcon from '@mui/icons-material/Phone';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import MapScreen from "./MapScreen";


const DialogueDeliveryOrder = ({ open, handleClose, deliveryOrder, courier, mockDataContacts }) => {
    const [viewDelivery, setViewDelivery] = useState(false);
    if (!deliveryOrder) return null;

    const user = mockDataContacts.find(contact => contact.sub === deliveryOrder.userID);
    const courierInfo = deliveryOrder.courierID ? courier.find(c => c.id === deliveryOrder.courierID) : null;

    const handleViewDelivery = () => {
        setViewDelivery(true);
    };

    const handleDialogClose = () => {
        setViewDelivery(false); // Définir viewDelivery sur false lors de la fermeture du dialogue
        handleClose(); // Appeler la fonction handleClose fournie par les props
    };

    const getStatusStyles = (status) => {
        let statusBackgroundColor = "transparent";
        let statusIconColor = "black";
        let statusTitle = "";

        switch (status) {
            case "NEW":
                statusBackgroundColor = "blue";
                statusIconColor = "white";
                statusTitle = "Nouvelle Commande";
                break;
            case "ACCEPTED":
                statusBackgroundColor = "green";
                statusIconColor = "white";
                statusTitle = "Commande Acceptée";
                break;
            case "DECLINED":
            case "CANCELED":
                statusBackgroundColor = "red";
                statusIconColor = "white";
                statusTitle = "Commande Annulée";
                break;
            case "PICK_UP":
                statusBackgroundColor = "orange";
                statusTitle = "Livraison récupérée";
                break;
            case "ACCEPTED_BY_COURIER":
                statusBackgroundColor = "yellow";
                statusTitle = "Commande Acceptée par Livreur";
                break;
            case "PICKED_UP_BY_COURIER":
                statusBackgroundColor = "lightgray";
                statusTitle = "Commande Récupérée";
                break;
            case "COMPLETED":
                statusBackgroundColor = "green";
                statusIconColor = "white";
                statusTitle = "Commande Complétée";
                break;
            default:
                break;
        }

        return { backgroundColor: statusBackgroundColor, color: statusIconColor, title: statusTitle };
    };

    const statusStyles = getStatusStyles(deliveryOrder.status);

    const getAmounts = () => {
        if (deliveryOrder.deliveryAmount ) {
            return (
                <Card sx={{ marginTop: 2, display: 'flex', justifyContent: 'space-between', padding: '8px', borderRadius: '4px' }}>
                    <div>
                        {deliveryOrder.deliveryAmount && <Typography variant="h5">Coût total:</Typography>}
                    </div>
                    <div>
                        {deliveryOrder.deliveryAmount && <Typography variant="h5">{deliveryOrder.deliveryAmount} FCFA</Typography>}
                    </div>
                </Card>
            );
        } else {
            return (
                <Card sx={{ marginTop: 2, display: 'flex', justifyContent: 'space-between', padding: '8px', borderRadius: '4px' }}>
                    <div>
                        <Typography variant="h5">Coût total:</Typography>
                    </div>
                    <div>
                        <Typography variant="h5">{deliveryOrder.deliveryAmount} FCFA</Typography>
                    </div>
                </Card>
            );
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>Détails de la commande de voiture numéro :{deliveryOrder.id} </DialogTitle>
            <DialogContent>
                

                <Grid container spacing={2}>
                   

                    <Grid item xs={6}>
                        <Card sx={{ height: '100%' }}>
                            <CardContent>
                                <Typography gutterBottom variant="h6">Informations sur l'utilisateur:</Typography>
                                <CardMedia
                                        component="img"
                                        image={user.profileImage || "../../assets/pngwing.com.png"}
                                        sx={{ width: 100, height: 100, margin: 'auto', borderRadius: 20 }}
                                 />
                                 <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 , marginTop:1}}>
                                    <AccountCircleIcon sx={{ marginRight: 1 }} />
                                    <Typography variant="h5">{user.name}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                    <PhoneIcon sx={{ marginRight: 1 }} />
                                    <Typography variant="h5">{user.phone}</Typography>
                                </Box>
                                
                                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                    <TodayIcon sx={{ marginRight: 1 }} />
                                    <Typography variant="h5">{new Date(user.createdAt).toLocaleDateString()}</Typography>
                                </Box>
                                {/* Ajoutez d'autres détails de l'utilisateur ici */}
                            </CardContent>
                        </Card>
                    </Grid>
                    {courierInfo && (
                        <Grid item xs={6}>
                            <Card sx={{ height: '100%' }}>
                                <CardContent>
                                   
                                    <Typography gutterBottom variant="h6">Informations sur le chauffeur:</Typography>
                                    <CardMedia
                                        component="img"
                                        image={courierInfo.profileImage || "../../assets/pngwing.com.png"}
                                        sx={{ width: 100, height: 100, margin: 'auto', borderRadius: 20 }}
                                    />
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 , marginTop:1}}>
                                        <AccountCircleIcon sx={{ marginRight: 1 }} />
                                        <Typography variant="h5">{courierInfo.name}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                        <PhoneIcon sx={{ marginRight: 1 }} />
                                        <Typography variant="h5">{courierInfo.phone}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                        <DirectionsCarIcon sx={{ marginRight: 1 }} />
                                        <Typography variant="h5">{courierInfo.bikeColor}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                        <ContactEmergencyIcon sx={{ marginRight: 1 }} />
                                        <Typography variant="h5">{courierInfo.licensePlate}</Typography>
                                    </Box>
                                    {/* Ajoutez d'autres détails du chauffeur ici */}
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                </Grid>

                


                {getAmounts()}

                <Card sx={{ marginTop: 2, display: 'flex', justifyContent: 'space-between', padding: '8px', borderRadius: '4px' }}>
                    <div>
                        <Typography variant="h5">Date de création:</Typography>
                        <Typography variant="h5">Date de mise à jour:</Typography>
                    </div>
                    <div>
                        <Typography variant="h5">{new Date(deliveryOrder.createdAt).toLocaleString()}</Typography>
                        <Typography variant="h5">{new Date(deliveryOrder.updatedAt).toLocaleString()}</Typography>
                    </div>
                </Card>
                <Box sx={{ display: 'flex', justifyContent: 'center', margin: 2 }}>
                    <Typography variant="h3" sx={{ backgroundColor: statusStyles.backgroundColor, padding: '4px 8px', borderRadius: 4, color: statusStyles.color, cursor: 'pointer'}} title={statusStyles.title}>
                         {statusStyles.title}
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleViewDelivery}>Voir Livraison</Button> {/* Bouton pour voir la livraison */}
                <Button onClick={handleDialogClose}>Fermer</Button>
            </DialogActions>
            {viewDelivery && <MapScreen deliveryOrder={deliveryOrder} courier={courier} />}
        </Dialog>
    );
};

export default DialogueDeliveryOrder;
