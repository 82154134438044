import { useState, useEffect } from "react";
import { Box, Button, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";

import VisibilityIcon from "@mui/icons-material/Visibility";
//import EditIcon from "@mui/icons-material/Edit";
//import DeleteIcon from "@mui/icons-material/Delete";
import DialogueRestaurant from "./Dialogue";
import { DataStore } from 'aws-amplify/datastore';
import { Restaurant, Wallet, Order, Dish } from "../../models";



const Restaurants = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const [mockDataRestaurant, setMockDataRestaurant] = useState([]);
    const [mockDataWallet, setMockDataWallet] = useState([]);
    const [mockDataOrderRestaurant, setMockDataOrderRestaurant] = useState([]);
    const [mokDataDishes, setMokDataDishes] = useState([]);

    useEffect(()=> {
        DataStore.query(Restaurant).then(setMockDataRestaurant)
    },[])

    useEffect(()=> {
        DataStore.query(Wallet).then(setMockDataWallet)
    },[])

    useEffect(()=> {
        DataStore.query(Order).then(setMockDataOrderRestaurant)
    },[])

    useEffect(()=> {
        DataStore.query(Dish).then(setMokDataDishes)
    },[])

    const handleOpenViewModal = (restaurant) => {
        setSelectedRestaurant(restaurant);
        setOpenViewModal(true);
    };

    const handleCloseViewModal = () => {
        setOpenViewModal(false);
    };

    const columns = [
        { field: "id", headerName: "ID" },
        {
            field: "name",
            headerName: "Restaurant",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "sub",
            headerName: "Sub",
            type: "string",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "phone",
            headerName: "Contact",
            flex: 1,
        },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            width: 180,
            renderCell: (params) => (
                <Box display="flex" justifyContent="space-around">
                    <Button startIcon={<VisibilityIcon />} variant="outlined" color="primary" onClick={() => handleOpenViewModal(params.row)}>
                        View
                    </Button>
                   {/* <Button startIcon={<EditIcon />} variant="outlined" color="secondary">
                        Edit
                    </Button>
                    <Button startIcon={<DeleteIcon />} variant="outlined" color="error">
                        Delete
                    </Button>*/}
                </Box>
            ),
        },
    ];

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Restaurant" subtitle="Bienvenue sur la gestion des Restaurant" />
            </Box>
           <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                <DataGrid  rows={mockDataRestaurant} columns={columns} />
            </Box>
        
            <DialogueRestaurant 
                open={openViewModal} 
                handleClose={handleCloseViewModal} 
                restaurant={selectedRestaurant} 
                restaurantOrder={mockDataOrderRestaurant} 
                wallet={mockDataWallet} 
                mokDataDishes={mokDataDishes} 
            />
            
        </Box>
    );
};

export default Restaurants;
