import { useState, useEffect } from "react";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import VisibilityIcon from "@mui/icons-material/Visibility";

import DialogueDeliveryOrder from "./Dialogue";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";


import { DataStore } from 'aws-amplify/datastore';
import { DeliveryOrder, User, Courier } from "../../models";



const OrderDelivery = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [selectedDeliveryOrder, setSelectedDeliveryOrder] = useState(null);

    const [mockDataDeliveryOrder, setMockDataDeliveryOrder] = useState([]);
    const [mockDataContacts, setMockDataContacts] = useState([]);
    const [mockDataCourier, setMockDataCourier] = useState([]);

    useEffect(()=> {
        DataStore.observe(DeliveryOrder).subscribe(msg => {
            if (msg.opType === 'INSERT' || msg.opType === 'UPDATE' || msg.opType === 'DELETE') {
                // Mettez à jour les données de carOrder ici
                fetchData();
            }
        });
        fetchData();
    },[]);

    const fetchData = async () => {
        const deliveryOrder = await DataStore.query(DeliveryOrder);
        setMockDataDeliveryOrder(deliveryOrder)
    }

    useEffect(()=> {
        DataStore.query(User).then(setMockDataContacts)
    },[])

    useEffect(()=> {
        DataStore.query(Courier).then(setMockDataCourier)
    },[])

    const handleOpenViewModal = (deliveryOrder) => {
        setSelectedDeliveryOrder(deliveryOrder);
        setOpenViewModal(true);
    };

    const handleCloseViewModal = () => {
        setOpenViewModal(false);
    };

    const columns = [
        { field: "id", headerName: "ID" },
        {
            field: "userID",
            headerName: "Utilisateur",
            flex: 1,
            valueGetter: (params) => {
                const user = mockDataContacts.find(user => user.sub === params.value);
                return user ? user.name : '';
            }
        },
        {
            field: "courierID",
            headerName: "Livreur",
            flex: 1,
            valueGetter: (params) => {
                if (params.value === "") {
                    return params.row.whyCanceled;
                }
                const courier = mockDataCourier.find(courier => courier.id === params.value);
                return courier ? courier.name : '';
            }
        },
        { field: "type", headerName: "Type" , flex: 1},
        { field: "deliveryAmount", headerName: "Coût" },
        { field: "status", headerName: "status" },
        { field: "createdAt", headerName: "Date" },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            width: 150,
            renderCell: (params) => (
                <Button startIcon={<VisibilityIcon />} variant="outlined" color="primary" onClick={() => handleOpenViewModal(params.row)}>
                    View
                </Button>
            ),
        },
    ];
    
  return (
    <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Commande de Livraison" subtitle="Bienvenue sur la gestion des Commande de Livraison" />
                <Link to="/createDeliveryOrder" style={{ textDecoration: "none" }}>
                <Box 
                backgroundColor= {colors.greenAccent[600]}  
                p="5px"
                display="flex"
                borderRadius="4px">
                  <IconButton >
                    <AddIcon />
                    <Typography color={colors.grey[100]}>Créer Livraison</Typography>
                  </IconButton>
                </Box>
                </Link>
            </Box>
            <Box
                m="15px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                <DataGrid  rows={mockDataDeliveryOrder} columns={columns} />
            </Box>
            <DialogueDeliveryOrder 
                open={openViewModal} 
                handleClose={handleCloseViewModal} 
                deliveryOrder={selectedDeliveryOrder} 
                courier={mockDataCourier} 
                mockDataContacts={mockDataContacts} 
            />
        </Box>
  )
}

export default OrderDelivery