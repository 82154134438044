
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar"
import Sidebar from "./scenes/global/Sidebar"
import Dashboard from "./scenes/dashboard"
import Teams from "./scenes/team"
import Drivers from "./scenes/driver"
import Users from "./scenes/users"
import Restaurants from "./scenes/restaurants"
import Couriers from "./scenes/courier"
import OrderRestaurant from "./scenes/orderRestaurant"
import OrderDrive from "./scenes/orderDrive"
import OrderDelivery from "./scenes/orderDelivery"
import Map from "./scenes/map"
import Form from "./scenes/form";
import SignUp from "./scenes/form/SignUp";
import ConfirmationScreen from "./scenes/form/ConfirmationScreen";
import CreateCarOrder from "./scenes/createCarOrder";
import CreateDeliveryOrder from "./scenes/createDeliveryOrder";
import { useEffect } from 'react';
import { generateToken, messaging} from './notification/firebase';
import { onMessage } from "firebase/messaging";
import toast, { Toaster } from 'react-hot-toast';
import ConfigDrive from "./scenes/configDrive";
import ConfigDelivery from "./scenes/configDelivery";
import ConfigPromo from "./scenes/configPromo";
import UserSetting from "./scenes/configSetting";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports"
import { withAuthenticator } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import OrderLocations from "./scenes/orderLocation";
import UserContextProvider, { useAdminContext } from "./contexts/UserContext";
import { DataStore } from 'aws-amplify/datastore';
import { Team } from "./models";

Amplify.configure(awsconfig)





function App() {
  const [theme, colorMode] = useMode();
  const {admin} = useAdminContext();

  const updateAdminProfileTokenInDatabase = async (adminId, newToken) => {
    try {
        const admin = await DataStore.query(Team, adminId);
        if (admin) {
            admin.profileToken = newToken;
            await DataStore.save(admin);
            console.log("Profile token updated successfully:", adminId, newToken);
        } else {
            throw new Error("Admin not found with ID: " + adminId);
        }
    } catch (error) {
        console.error("Error updating profile token:", error);
        throw new Error("Error updating profile token: " + error.message);
    }
};
useEffect(() => {
  const fetchData = async () => {
      try {
          const newToken = generateToken();
          const adminId = admin.id; // Assurez-vous que admin est correctement initialisé avant d'accéder à son ID
          await updateAdminProfileTokenInDatabase(adminId, newToken);
      } catch (error) {
          console.error("Error updating profile token:", error);
      }
  };

  if (admin && admin.id) {
      fetchData();
  }
}, [admin]);


  useEffect(()=> {
    generateToken()
    onMessage(messaging, (payload) => {
      console.log(payload)
      toast(payload.notification.body)
    })
  }, [])

  return (
    <UserContextProvider>
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
      <CssBaseline /> {/*reset ccs to default*/}
    <div className="app">
      <Sidebar/>
      <main className="content">
        <Topbar/>
        <Toaster position="top-right"/>
        {/**/}
        <Routes>
            <Route path="/" element={<Dashboard/>}/>
            <Route path="/team" element={<Teams/>}/>
            <Route path="/users" element={<Users/>}/>
            <Route path="/driver" element={<Drivers/>}/>
            <Route path="/restaurants" element={<Restaurants/>}/>
            <Route path="/courier" element={<Couriers/>}/>
            <Route path="/orderRestaurant" element={<OrderRestaurant/>}/>
            <Route path="/orderDrive" element={<OrderDrive/>}/>
            <Route path="/orderDelivery" element={<OrderDelivery/>}/>
            <Route path="/orderLocation" element={<OrderLocations/>}/>
            <Route path="/form" element={<Form />} />
            <Route path="/SignUp" element={<SignUp />} />
            <Route path="/confirmation/:username" element={<ConfirmationScreen />} />
            <Route path="/createCarOrder" element={<CreateCarOrder />} />
            <Route path="/createDeliveryOrder" element={<CreateDeliveryOrder />} />
            <Route path="/configDrive" element={<ConfigDrive />} />
            <Route path="/configDelivery" element={<ConfigDelivery />} />
            <Route path="/configPromo" element={<ConfigPromo />} />
            <Route path="/map" element={<Map/>}/>
            <Route path="/configSetting" element={<UserSetting/>}/>
        </Routes>
      </main>
    </div>
    </ThemeProvider>
    </ColorModeContext.Provider>
    </UserContextProvider>
  );
}

export default withAuthenticator(App, { hideSignUp: true });
