import { useState, useEffect } from "react";
import { Box, Button, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import VisibilityIcon from "@mui/icons-material/Visibility";
//import EditIcon from "@mui/icons-material/Edit";
//import DeleteIcon from "@mui/icons-material/Delete";

import { DataStore } from 'aws-amplify/datastore';
import { Order, User, Restaurant, OrderItem, Dish } from "../../models";

import DialogueOrder from "./Dialogue";




const OrderRestaurant = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [mockDataOrderRestaurant, setMockDataOrderRestaurant] = useState([]);
    const [mockDataContacts, setMockDataContacts] = useState([]);
    const [mockDataRestaurant, setMockDataRestaurant] = useState([]);
    const [mockDataOrderItem, setMockDataOrderItem] = useState([]);
    const [mokDataDishes, setMokDataDishes] = useState([]);

    useEffect(()=> {
        DataStore.observe(Order).subscribe(msg => {
            if (msg.opType === 'INSERT' || msg.opType === 'UPDATE' || msg.opType === 'DELETE') {
                // Mettez à jour les données de carOrder ici
                fetchData();
            }
        });
        fetchData();
    },[]);

    const fetchData = async () => {
        const order = await DataStore.query(Order);
        setMockDataOrderRestaurant(order)
    }

    useEffect(()=> {
        DataStore.query(User).then(setMockDataContacts)
    },[])

    useEffect(()=> {
        DataStore.query(Restaurant).then(setMockDataRestaurant)
    },[])

    useEffect(()=> {
        DataStore.query(OrderItem).then(setMockDataOrderItem)
    },[])

    useEffect(()=> {
        DataStore.query(Dish).then(setMokDataDishes)
    },[])

    const handleOpenViewModal = (order) => {
        setSelectedOrder(order);
        setOpenViewModal(true);
    };

    const handleCloseViewModal = () => {
        setOpenViewModal(false);
    };

    const columns = [
        { field: "id", headerName: "ID" },
        {
            field: "restaurantID",
            headerName: "Restaurant",
            flex: 1,
            valueGetter: (params) => {
                const restaurant = mockDataRestaurant.find(restaurant => restaurant.id === params.value);
                return restaurant ? restaurant.name : '';
            }
        },
        {
            field: "userID",
            headerName: "Utilisateur",
            flex: 1,
            valueGetter: (params) => {
                const user = mockDataContacts.find(user => user.sub === params.value);
                return user ? user.name : '';
            }
        },
        { field: "totalAmount", headerName: "Coût commande" },
        { field: "deliveryFee", headerName: "Frais Livraison" },
        { field: "createdAt", headerName: "Date" },
        { field: "status", headerName: "Status" },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            width: 150,
            renderCell: (params) => (
                <Button startIcon={<VisibilityIcon />} variant="outlined" color="primary" onClick={() => handleOpenViewModal(params.row)}>
                    View
                </Button>
            ),
        },
    ];
    

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Commande de Repas" subtitle="Bienvenue sur la gestion des Commande de Repas" />
            </Box>
            <Box
                m="15px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                <DataGrid  rows={mockDataOrderRestaurant} columns={columns} />
            </Box>
            <DialogueOrder 
                open={openViewModal} 
                handleClose={handleCloseViewModal} 
                order={selectedOrder} 
                orderItems={mockDataOrderItem} 
                mockDataContacts={mockDataContacts} 
                mockDataRestaurant={mockDataRestaurant}
                mockDataDishes={mokDataDishes}
                />
        </Box>
    );
};

export default OrderRestaurant;
