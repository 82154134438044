import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Card, CardContent, CardMedia, Box, Grid } from "@mui/material";

const DialogueOrder = ({ open, handleClose, order, orderItems, mockDataRestaurant, mockDataContacts, mockDataDishes }) => {
    if (!order) return null; // Si aucune commande sélectionnée, ne pas afficher le dialogue

    // Rechercher les informations du restaurant correspondant à l'ID de la commande
    const restaurant = mockDataRestaurant.find(restaurant => restaurant.id === order.restaurantID);

    // Rechercher les informations de l'utilisateur correspondant à l'ID de la commande
    const user = mockDataContacts.find(user => user.sub === order.userID);

    // Fonction pour récupérer l'adresse de l'utilisateur à partir des coordonnées userLat et userLng
    //const getUserAddress = () => {
        // Votre logique pour récupérer l'adresse à partir de userLat et userLng
        // Je vais simplement simuler une adresse pour l'exemple
        //return "123 Rue de l'Utilisateur, Ville, Pays";
    //};

    // Fonction pour déterminer les styles du statut de la commande
    const getStatusStyles = (status) => {
        let statusBackgroundColor = "transparent";
        let statusIconColor = "black";
        let statusTitle = "";

        switch (status) {
            case "NEW":
                statusBackgroundColor = "blue";
                statusIconColor = "white";
                statusTitle = "Nouvelle Commande";
                break;
            case "ACCEPTED":
                statusBackgroundColor = "green";
                statusIconColor = "white";
                statusTitle = "Commande Acceptée";
                break;
            case "DECLINED":
                statusBackgroundColor = "red";
                statusIconColor = "white";
                statusTitle = "Commande Refusée";
                break;
            case "CANCELLED":
                statusBackgroundColor = "red";
                statusIconColor = "white";
                statusTitle = "Commande Refusée";
                break;
            case "READY_FOR_PICKED_UP":
                statusBackgroundColor = "orange";
                statusIconColor = "white";
                statusTitle = "Commande en Attente";
                break;
            case "ACCEPTED_BY_COURIER":
                statusBackgroundColor = "yellow";
                statusTitle = "Commande Acceptée par Livreur";
                break;
            case "PICKED_UP_BY_COURIER":
                statusBackgroundColor = "lightgray";
                statusTitle = "Commande Récupérée";
                break;
            case "COMPLETED":
                statusBackgroundColor = "green";
                statusIconColor = "white";
                statusTitle = "Commande Complétée";
                break;
            default:
                break;
        }

        return { backgroundColor: statusBackgroundColor, color: statusIconColor, title: statusTitle };
    };

    const statusStyles = getStatusStyles(order.status);

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>Détails de la commande</DialogTitle>
            <DialogContent>
                

                {/* Informations sur la commande */}
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Card sx={{ marginTop: 2 }}>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    {restaurant ? restaurant.name : "Nom du restaurant non disponible"}
                                </Typography>
                                <Typography variant="body1">
                                    Adresse du restaurant: {restaurant ? restaurant.address : "Non disponible"}
                                </Typography>
                                <Typography variant="body1">
                                    Téléphone du restaurant: {restaurant ? restaurant.phone : "Non disponible"}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card sx={{ marginTop: 2 }}>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    {user ? user.name : "Nom de l'utilisateur non disponible"}
                                </Typography>
                                <Typography variant="body1">
                                    Numéro de téléphone de l'utilisateur: {user ? user.phone : "Non disponible"}
                                </Typography>
                                <Typography variant="body1">
                                    Adresse de l'utilisateur: {order.userlat} & {order.userlng}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                {/* Plats commandés */}
                <Typography variant="h6" sx={{ marginTop: 2 }}>Plats commandés:</Typography>
                <Box sx={{ overflowX: 'auto', width: '100%', marginTop: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: 800 }}>
                        {orderItems
                            .filter(item => item.orderID === order.id)
                            .map(item => {
                                const dish = mockDataDishes.find(dish => dish.id === item.dishID);
                                return (
                                    dish && (
                                        <Card key={item.id} sx={{ width: '100%', marginY: 2 }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <CardMedia
                                                    component="img"
                                                    image={dish.image}
                                                    title={dish.name}
                                                    sx={{ width: 150, height: 150 }}
                                                />
                                                <CardContent sx={{ flexGrow: 1 }}>
                                                    <Typography gutterBottom variant="h5" component="div">
                                                        {dish.name}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 1 }}>
                                                        {item.quantity}x - {item.price} FCFA
                                                    </Typography>
                                                </CardContent>
                                            </Box>
                                        </Card>
                                    )
                                );
                            })}
                    </Box>
                </Box>
                <Card sx={{ marginTop: 2 }}>
                    <CardContent>
                        {/* Total de la commande */}
                        <Typography variant="h6" sx={{ marginTop: 2 }}>Total de la commande: {order.totalAmount} FCFA</Typography>
                        <Typography variant="body1">Frais de livraison: {order.deliveryFee} FCFA</Typography>
                    </CardContent>
                </Card>
                {/* Statut de la commande */}
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                    <Typography variant="h6" sx={{ backgroundColor: statusStyles.backgroundColor, padding: '4px 8px', borderRadius: 4, color: statusStyles.color, cursor: 'pointer'}} title={statusStyles.title}>
                        Statut de la commande: {statusStyles.title}
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Fermer</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogueOrder;
