import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import CreateCouponForm from "./CreateCoupon";

const CreateCouponDialog = ({ open, onClose, onCreate }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Créer Coupons</DialogTitle>
      <DialogContent>
        <CreateCouponForm onCreate={onCreate} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCouponDialog;
